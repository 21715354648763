import React, { PropsWithChildren, useContext, useEffect } from 'react';

// Analytic
import * as Analytic from '@/Analytic';

// Spec
import * as Spec from './Spec';

const DEFAULT: Spec.Props = {
  method: 'GET',
};

const Context = React.createContext(DEFAULT);

const FormProvider: React.FunctionComponent<PropsWithChildren<Spec.Props>> = ({
  body,
  children,
  method = DEFAULT.method,
  onSubmit: onSubmitHandler,
  ...rest
}) => {
  const observer = new AbortController();

  useEffect(() => {
    return () => {
      observer.abort();
    };
  });

  const onSubmit: Spec.Props['onSubmit'] = async (event) => {
    event.preventDefault();

    Analytic.onSubmit(event);

    onSubmitHandler?.(event);
  };

  const value = { ...rest, onSubmit };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useFormContext = () => {
  const Contexts = useContext(Context);

  return Contexts;
};

export { useFormContext };
export default FormProvider;
