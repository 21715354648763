import React, { Suspense } from 'react';

// Router
import { Route as Origin } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Constants
import { PROVIDER_PATH } from '@/Views/Authentication/constants';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner position='inline' />}>
      <Contents />
    </Suspense>
  );
};

export { PROVIDER_PATH };
export default <Origin path={PROVIDER_PATH} element={<Lazy />} />;
