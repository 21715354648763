// Polyfill
import './Polyfill';

// Query
import * as Query from './Query';

// Hooks
export * from './Hooks';

const Cache = {
  ...Query.Cache,
};

export default Cache;
