import { useEffect, useState } from 'react';

import {
  useMutation as useOrigin,
  ApolloCache,
  ApolloError,
  OperationVariables,
  DefaultContext,
} from '@/API/Client';

function useMutation<
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(...props: Parameters<typeof useOrigin<TData, TVariables, TContext, TCache>>) {
  const [error, setError] = useState<ApolloError | void>();

  const [mutation, result] = useOrigin<TData, TVariables, TContext, TCache>(
    props[0],
    {
      ...props[1],
      onError: setError,
    }
  );

  const [previousData, setPreviousData] = useState(result.data);

  useEffect(() => {
    if (!result.data) {
      return;
    }

    setPreviousData(result.data);
  }, [result.data]);

  const mutate = async (...params: Parameters<typeof mutation>) => {
    setError();

    const result = await mutation(...params);

    return result;
  };

  const reset = () => {
    result.reset();
    setError();
  };

  return { ...result, error, mutate, previousData, reset };
}

export default useMutation;
