import { RetryLink } from '@apollo/client/link/retry';

// SignIn.definitions[0].name.value

const NO_RETRY_OPERATIONS = ['SignIn', 'Register'];

const FORBIDDEN = 'FORBIDDEN';

const retryLink = new RetryLink({
  attempts: {
    max: 0,
    retryIf(errors, operation) {
      const isForbidden = errors?.result?.errors?.find(
        (error) => error?.extensions?.code === FORBIDDEN
      );

      return (
        !isForbidden &&
        !NO_RETRY_OPERATIONS.some((operationName) =>
          operation.operationName.includes(operationName)
        )
      );
    },
  },
  delay: {
    initial: 1000,
    max: 10000,
    jitter: true,
  },
});

export default retryLink;
