import React, { Suspense } from 'react';

// Routes
import {
  MatchedRoute,
  Navigate,
  NavigateProps,
  Outlet,
  Route as Origin,
  useLocation,
} from '@/Router';

// Views
import Booking, { PATTERN as BOOKING_PATTERN } from './Booking';

// Components
import { Spinner } from '@/Components';

const PATTERN = [
  `/activities/:ActivityGUID([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`,
  ...BOOKING_PATTERN,
];

const PATH = ':ActivityGUID/*';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--views--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

const ConditionalRedirect: React.FunctionComponent = () => {
  const location = useLocation();

  const to: NavigateProps['to'] = {
    pathname: '..',
    hash: location.hash,
    search: location.search,
  };

  const Fallback = <Navigate to={to} state={location.state} replace />;

  return (
    <MatchedRoute fallback={Fallback} pattern={PATTERN}>
      <Lazy />
      <Outlet />
    </MatchedRoute>
  );
};

export { PATH };
export default (
  <Origin path={PATH} element={<ConditionalRedirect />}>
    {Booking}
  </Origin>
);
