import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// Env
import { Value } from '@/Env/Client';

// LocalStorage
import { getItem } from '@/LocalStorage';

const COOKIE = 'Authorization';

const client = (config: Value) => {
  const Authorization = getItem(COOKIE);

  const connectionParams = {
    authorization: '',
  };

  if (Authorization?.AccessToken) {
    connectionParams.authorization = `bearer ${Authorization.AccessToken}`;
  }

  return createClient({
    url: String(config.env?.LOCI_API_WS_URL),
    connectionParams,
  });
};

const ws = (config: Value) => new GraphQLWsLink(client(config));

export default ws;
