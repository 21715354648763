import { ApolloError, HttpLink } from '@apollo/client';

// Env
import { Value } from '@/Env/Client';

// LocalStorage
import { getItem } from '@/LocalStorage';

// Utilities
import * as Utilities from '@/API/Client/Utilities';

// Constants
import { INFO } from '@/API/Client/Links/constants';

const http = (config: Value) =>
  new HttpLink({
    credentials: 'include',
    async fetch(_, init) {
      try {
        if (!init) {
          throw new Error(Utilities.INVALID_MESSAGES);
        }

        const body = JSON.parse(String(init.body || '{}'));

        if (!body.operationName) {
          throw new ApolloError({
            networkError: {
              message: Utilities.INVALID_MESSAGES,
              name: INFO.MISSING_OPERATION_NAME,
            },
          });
        }

        if (!config.env?.LOCI_API_URL) {
          return Promise.reject(Utilities.NO_READY_MESSAGES);
        }

        if (Utilities.WHITE_LISTED.includes(body.operationName)) {
          return window.fetch(config.env?.LOCI_API_URL, init);
        }

        let Authorization = getItem('Authorization');

        if (!Utilities.WHITE_LISTED.includes(body.operationName)) {
          Authorization = await Utilities.validateAccess(init);
        }

        if (!Authorization) {
          throw new ApolloError({
            networkError: {
              message: Utilities.INVALID_MESSAGES,
              name: INFO.NOT_AUTHORIZE,
            },
          });
        }

        const result = await window.fetch(config.env?.LOCI_API_URL, {
          ...init,
          headers: {
            ...init.headers,
            authorization: `bearer ${Authorization.AccessToken}`,
          },
        });

        return result;
      } catch (error) {
        throw new ApolloError(error as ApolloError);
      }
    },
  });

export default http;
