/* eslint-disable */
import type {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Banking account number is a string of 5 to 17 alphanumeric values for representing an generic account number */
  AccountNumber: { input: unknown; output: unknown };
  /** Asset DataSource Value scalar type */
  AssetDataSourceValue: { input: unknown; output: unknown };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: unknown; output: unknown };
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: { input: unknown; output: unknown };
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard cuid format as specified in https://github.com/ericelliott/cuid#broken-down */
  Cuid: { input: unknown; output: unknown };
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard DID format as specified in did-core: https://www.w3.org/TR/did-core/. */
  DID: { input: unknown; output: unknown };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: unknown; output: unknown };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: unknown; output: unknown };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard DeweyDecimal format as specified by the OCLC https://www.oclc.org/content/dam/oclc/dewey/resources/summaries/deweysummaries.pdf */
  DeweyDecimal: { input: unknown; output: unknown };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: unknown; output: unknown };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: { input: unknown; output: unknown };
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: { input: unknown; output: unknown };
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: { input: unknown; output: unknown };
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: { input: unknown; output: unknown };
  /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
  Hexadecimal: { input: unknown; output: unknown };
  /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
  IBAN: { input: unknown; output: unknown };
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  IP: { input: unknown; output: unknown };
  /** A field whose value is an IPC Class Symbol within the International Patent Classification System: https://www.wipo.int/classifications/ipc/en/ */
  IPCPatent: { input: unknown; output: unknown };
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: { input: unknown; output: unknown };
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: { input: unknown; output: unknown };
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: { input: unknown; output: unknown };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: { input: unknown; output: unknown };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: unknown; output: unknown };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: unknown; output: unknown };
  /** A field whose value conforms to the Library of Congress Subclass Format ttps://www.loc.gov/catdir/cpso/lcco/ */
  LCCSubclass: { input: unknown; output: unknown };
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: unknown; output: unknown };
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: unknown; output: unknown };
  /** A local date-time string (i.e., with no associated timezone) in `YYYY-MM-DDTHH:mm:ss` format, e.g. `2020-01-01T00:00:00`. */
  LocalDateTime: { input: unknown; output: unknown };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day.  This is useful when using the scalar to represent the exclusive upper bound of a time block. */
  LocalEndTime: { input: unknown; output: unknown };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: { input: unknown; output: unknown };
  /** The locale in the format of a BCP 47 (RFC 5646) standard string */
  Locale: { input: unknown; output: unknown };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: { input: unknown; output: unknown };
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: unknown; output: unknown };
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: { input: unknown; output: unknown };
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: { input: unknown; output: unknown };
  /** Floats that will have a value less than 0. */
  NegativeFloat: { input: unknown; output: unknown };
  /** Integers that will have a value less than 0. */
  NegativeInt: { input: unknown; output: unknown };
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: unknown; output: unknown };
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: unknown; output: unknown };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: unknown; output: unknown };
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: { input: unknown; output: unknown };
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: { input: unknown; output: unknown };
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: unknown; output: unknown };
  /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
  Port: { input: unknown; output: unknown };
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: unknown; output: unknown };
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: unknown; output: unknown };
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: { input: unknown; output: unknown };
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: { input: unknown; output: unknown };
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: { input: unknown; output: unknown };
  /** In the US, an ABA routing transit number (`ABA RTN`) is a nine-digit code to identify the financial institution. */
  RoutingNumber: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard personal number (personnummer) formats for Sweden */
  SESSN: { input: unknown; output: unknown };
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: unknown; output: unknown };
  /** A field whose value is a Semantic Version: https://semver.org */
  SemVer: { input: unknown; output: unknown };
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: unknown; output: unknown };
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: { input: unknown; output: unknown };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: unknown; output: unknown };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: unknown; output: unknown };
  /** A currency string, such as $21.25 */
  USCurrency: { input: unknown; output: unknown };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: unknown; output: unknown };
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: { input: unknown; output: unknown };
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: { input: unknown; output: unknown };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: unknown; output: unknown };
  /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  UtcOffset: { input: unknown; output: unknown };
  /** Represents NULL values */
  Void: { input: unknown; output: unknown };
};

export type Activate = {
  AccessToken: Scalars['JWT']['output'];
  CreatedAt: Scalars['DateTime']['output'];
  ExpiresIn: Scalars['Int']['output'];
  LastSignedInAt: Scalars['DateTime']['output'];
  RefetchIn: Scalars['Int']['output'];
  RefreshToken: Scalars['JWT']['output'];
  UserGUID: Scalars['UUID']['output'];
};

export type ActivateInput = {
  RegistrationGUID: Scalars['UUID']['input'];
  Secret: Scalars['String']['input'];
  UserGUID: Scalars['UUID']['input'];
};

export type Activities = {
  ActivityGUID?: Maybe<Scalars['String']['output']>;
  AssetID?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  Assets: Array<Maybe<Assets>>;
  Description?: Maybe<ActivitiesDescription>;
  Details?: Maybe<Scalars['String']['output']>;
  FavoredBy?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  GeoLocation?: Maybe<GeoLocations>;
  GeoLocationID: Scalars['MongoID']['output'];
  GuideID?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  Guides: Array<Maybe<Guides>>;
  ScheduleID?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  Schedules: Array<Maybe<Schedules>>;
  Title?: Maybe<Scalars['String']['output']>;
  _id: Scalars['MongoID']['output'];
};

export type ActivitiesDescription = {
  Conclusion?: Maybe<Scalars['String']['output']>;
  Introduction?: Maybe<Scalars['String']['output']>;
  Main?: Maybe<Scalars['String']['output']>;
  _id?: Maybe<Scalars['MongoID']['output']>;
};

export type ActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

/** List of items with pagination. */
export type ActivitiesPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Activities>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AssetDataSource = {
  Field: Scalars['String']['input'];
  Source: AssetsDataSourceName;
  Value: Scalars['AssetDataSourceValue']['input'];
};

export type Assets = {
  AssetGUID?: Maybe<Scalars['String']['output']>;
  Path: Scalars['String']['output'];
  Size: Scalars['Float']['output'];
  _id: Scalars['MongoID']['output'];
};

export enum AssetsDataSourceName {
  GEO_LOCATIONS = 'GEO_LOCATIONS',
  ROOMS = 'ROOMS',
  USERS = 'USERS',
}

/** List of items with pagination. */
export type AssetsPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Assets>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Bookings = {
  Activity?: Maybe<Activities>;
  ActivityID: Scalars['MongoID']['output'];
  BookingGUID?: Maybe<Scalars['String']['output']>;
  CheckInDate: Scalars['Date']['output'];
  CheckOutDate: Scalars['Date']['output'];
  Guide?: Maybe<Guides>;
  GuideID: Scalars['MongoID']['output'];
  User?: Maybe<Users>;
  UserID: Scalars['MongoID']['output'];
  _id: Scalars['MongoID']['output'];
};

/** List of items with pagination. */
export type BookingsPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Bookings>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ChatCompletionTokenLogprob = {
  bytes?: Maybe<Array<Scalars['Int']['output']>>;
  logprob: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  top_logprobs: Array<TopLogprob>;
};

export type Countries = {
  CountryGUID?: Maybe<Scalars['String']['output']>;
  Data: CountriesData;
  _id: Scalars['MongoID']['output'];
};

export type CountriesData = {
  _id?: Maybe<Scalars['MongoID']['output']>;
  countryCallingCode: Scalars['String']['output'];
  countryCode?: Maybe<Scalars['String']['output']>;
  countryNameEn: Scalars['String']['output'];
  countryNameLocal?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  currencyNameEn?: Maybe<Scalars['String']['output']>;
  flag: Scalars['String']['output'];
  officialLanguageCode: Scalars['String']['output'];
  officialLanguageNameEn?: Maybe<Scalars['String']['output']>;
  officialLanguageNameLocal?: Maybe<Scalars['String']['output']>;
  region: Scalars['String']['output'];
  tinName?: Maybe<Scalars['String']['output']>;
  tinType?: Maybe<Scalars['String']['output']>;
};

export type CreateManyActivitiesInput = {
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<ActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID: Scalars['MongoID']['input'];
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManyActivitiesPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Activities>>;
};

export type CreateManyAssetsInput = {
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  Path: Scalars['String']['input'];
  Size: Scalars['Float']['input'];
};

export type CreateManyAssetsPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Assets>>;
};

export type CreateManyBookingsInput = {
  ActivityID: Scalars['MongoID']['input'];
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate: Scalars['Date']['input'];
  CheckOutDate: Scalars['Date']['input'];
  GuideID: Scalars['MongoID']['input'];
  UserID: Scalars['MongoID']['input'];
};

export type CreateManyBookingsPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Bookings>>;
};

export type CreateManyGeoLocationsInput = {
  Annotations?: InputMaybe<GeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<GeoLocationsBoundsInput>;
  Components?: InputMaybe<GeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<GeoLocationsGeometryInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateManyGeoLocationsPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<GeoLocations>>;
};

export type CreateManyGuidesInput = {
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID: Scalars['MongoID']['input'];
};

export type CreateManyGuidesPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Guides>>;
};

export type CreateManyPhonesInput = {
  CallingCode: Scalars['String']['input'];
  CountryCode: EnumPhonesCountryCode;
  Number: Scalars['String']['input'];
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManyPhonesPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Phones>>;
};

export type CreateManySchedulesInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<SchedulesRangesInput>>>>
  >;
};

export type CreateManySchedulesPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Schedules>>;
};

export type CreateManyUsersInput = {
  Active: Scalars['Boolean']['input'];
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday: Scalars['Date']['input'];
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email: Scalars['String']['input'];
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Password: Scalars['String']['input'];
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName: Scalars['String']['input'];
};

export type CreateManyUsersPayload = {
  /** Number of created documents */
  createdCount: Scalars['Int']['output'];
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']['output']>;
  /** Created documents */
  records?: Maybe<Array<Users>>;
};

export type CreateOneActivitiesInput = {
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<ActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID: Scalars['MongoID']['input'];
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOneActivitiesPayload = {
  /** Created document */
  record?: Maybe<Activities>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneAssetsInput = {
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  Path: Scalars['String']['input'];
  Size: Scalars['Float']['input'];
};

export type CreateOneAssetsPayload = {
  /** Created document */
  record?: Maybe<Assets>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneBookingsInput = {
  ActivityID: Scalars['MongoID']['input'];
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate: Scalars['Date']['input'];
  CheckOutDate: Scalars['Date']['input'];
  GuideID: Scalars['MongoID']['input'];
  UserID: Scalars['MongoID']['input'];
};

export type CreateOneBookingsPayload = {
  /** Created document */
  record?: Maybe<Bookings>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneGeoLocationsInput = {
  Annotations?: InputMaybe<GeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<GeoLocationsBoundsInput>;
  Components?: InputMaybe<GeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<GeoLocationsGeometryInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateOneGeoLocationsPayload = {
  /** Created document */
  record?: Maybe<GeoLocations>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneGuidesInput = {
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID: Scalars['MongoID']['input'];
};

export type CreateOneGuidesPayload = {
  /** Created document */
  record?: Maybe<Guides>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOnePhonesInput = {
  CallingCode: Scalars['String']['input'];
  CountryCode: EnumPhonesCountryCode;
  Number: Scalars['String']['input'];
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOnePhonesPayload = {
  /** Created document */
  record?: Maybe<Phones>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneSchedulesInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<SchedulesRangesInput>>>>
  >;
};

export type CreateOneSchedulesPayload = {
  /** Created document */
  record?: Maybe<Schedules>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneUsersInput = {
  Active: Scalars['Boolean']['input'];
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday: Scalars['Date']['input'];
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email: Scalars['String']['input'];
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Password: Scalars['String']['input'];
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName: Scalars['String']['input'];
};

export type CreateOneUsersPayload = {
  /** Created document */
  record?: Maybe<Users>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export enum EnumPhonesCountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum EnumUsersGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
}

export enum EnumUsersSocialProviders {
  apple = 'apple',
  google = 'google',
}

export type FilterFindManyActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterFindManyActivitiesInput = {
  AND?: InputMaybe<Array<FilterFindManyActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterFindManyActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterFindManyActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Search?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterFindManyActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterFindManyActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterFindManyActivities_IdOperatorsInput>;
};

export type FilterFindManyActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyAssetsInput = {
  AND?: InputMaybe<Array<FilterFindManyAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindManyAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterFindManyAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindManyAssets_IdOperatorsInput>;
};

export type FilterFindManyAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBookingsInput = {
  AND?: InputMaybe<Array<FilterFindManyBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterFindManyBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterFindManyBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterFindManyBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindManyBookings_IdOperatorsInput>;
};

export type FilterFindManyBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyCountriesCountryGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyCountriesDataInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  countryCallingCode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryNameEn?: InputMaybe<Scalars['String']['input']>;
  countryNameLocal?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyNameEn?: InputMaybe<Scalars['String']['input']>;
  flag?: InputMaybe<Scalars['String']['input']>;
  officialLanguageCode?: InputMaybe<Scalars['String']['input']>;
  officialLanguageNameEn?: InputMaybe<Scalars['String']['input']>;
  officialLanguageNameLocal?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tinName?: InputMaybe<Scalars['String']['input']>;
  tinType?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyCountriesInput = {
  AND?: InputMaybe<Array<FilterFindManyCountriesInput>>;
  CountryGUID?: InputMaybe<Scalars['String']['input']>;
  Data?: InputMaybe<FilterFindManyCountriesDataInput>;
  OR?: InputMaybe<Array<FilterFindManyCountriesInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyCountriesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyCountriesOperatorsInput = {
  CountryGUID?: InputMaybe<FilterFindManyCountriesCountryGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindManyCountries_IdOperatorsInput>;
};

export type FilterFindManyCountries_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterFindManyGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterFindManyGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterFindManyGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterFindManyGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterFindManyGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterFindManyGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterFindManyGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterFindManyGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterFindManyGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterFindManyGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterFindManyGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterFindManyGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterFindManyGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterFindManyGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterFindManyGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterFindManyGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterFindManyGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterFindManyGeoLocationsAnnotationsSunSetInput>;
};

export type FilterFindManyGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterFindManyGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterFindManyGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterFindManyGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterFindManyGeoLocationsBoundsSouthwestInput>;
};

export type FilterFindManyGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterFindManyGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterFindManyGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterFindManyGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindManyGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindManyGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterFindManyGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterFindManyGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterFindManyGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterFindManyGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterFindManyGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterFindManyGeoLocationsInput>>;
  Search?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterFindManyGeoLocations_IdOperatorsInput>;
};

export type FilterFindManyGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyGuidesInput = {
  AND?: InputMaybe<Array<FilterFindManyGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindManyGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterFindManyGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterFindManyGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterFindManyGuides_IdOperatorsInput>;
};

export type FilterFindManyGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyPhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyPhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterFindManyPhonesInput = {
  AND?: InputMaybe<Array<FilterFindManyPhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindManyPhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyPhonesOperatorsInput>;
};

export type FilterFindManyPhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyPhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterFindManyPhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterFindManyPhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterFindManyPhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterFindManyPhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindManyPhones_IdOperatorsInput>;
};

export type FilterFindManyPhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyPhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManySchedulesInput = {
  AND?: InputMaybe<Array<FilterFindManySchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterFindManySchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterFindManySchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManySchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManySchedulesOperatorsInput = {
  _id?: InputMaybe<FilterFindManySchedules_IdOperatorsInput>;
};

export type FilterFindManySchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterFindManySchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterFindManyUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyUsersInput = {
  AND?: InputMaybe<Array<FilterFindManyUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindManyUsersInput>>;
  Search?: InputMaybe<Scalars['String']['input']>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyUsersOperatorsInput>;
};

export type FilterFindManyUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyUsersOperatorsInput = {
  Active?: InputMaybe<FilterFindManyUsersActiveOperatorsInput>;
  FirstName?: InputMaybe<FilterFindManyUsersFirstNameOperatorsInput>;
  LastName?: InputMaybe<FilterFindManyUsersLastNameOperatorsInput>;
  UserGUID?: InputMaybe<FilterFindManyUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterFindManyUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterFindManyUsers_IdOperatorsInput>;
};

export type FilterFindManyUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterFindOneActivitiesInput = {
  AND?: InputMaybe<Array<FilterFindOneActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterFindOneActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterFindOneActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterFindOneActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterFindOneActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterFindOneActivities_IdOperatorsInput>;
};

export type FilterFindOneActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneAssetsInput = {
  AND?: InputMaybe<Array<FilterFindOneAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindOneAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterFindOneAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindOneAssets_IdOperatorsInput>;
};

export type FilterFindOneAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneBookingsInput = {
  AND?: InputMaybe<Array<FilterFindOneBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterFindOneBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterFindOneBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterFindOneBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindOneBookings_IdOperatorsInput>;
};

export type FilterFindOneBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneCountriesCountryGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneCountriesDataInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  countryCallingCode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  countryNameEn?: InputMaybe<Scalars['String']['input']>;
  countryNameLocal?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyNameEn?: InputMaybe<Scalars['String']['input']>;
  flag?: InputMaybe<Scalars['String']['input']>;
  officialLanguageCode?: InputMaybe<Scalars['String']['input']>;
  officialLanguageNameEn?: InputMaybe<Scalars['String']['input']>;
  officialLanguageNameLocal?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tinName?: InputMaybe<Scalars['String']['input']>;
  tinType?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneCountriesInput = {
  AND?: InputMaybe<Array<FilterFindOneCountriesInput>>;
  CountryGUID?: InputMaybe<Scalars['String']['input']>;
  Data?: InputMaybe<FilterFindOneCountriesDataInput>;
  OR?: InputMaybe<Array<FilterFindOneCountriesInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneCountriesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneCountriesOperatorsInput = {
  CountryGUID?: InputMaybe<FilterFindOneCountriesCountryGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindOneCountries_IdOperatorsInput>;
};

export type FilterFindOneCountries_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterFindOneGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterFindOneGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterFindOneGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterFindOneGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterFindOneGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterFindOneGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterFindOneGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterFindOneGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterFindOneGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterFindOneGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterFindOneGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterFindOneGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterFindOneGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterFindOneGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterFindOneGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterFindOneGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterFindOneGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterFindOneGeoLocationsAnnotationsSunSetInput>;
};

export type FilterFindOneGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterFindOneGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterFindOneGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterFindOneGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterFindOneGeoLocationsBoundsSouthwestInput>;
};

export type FilterFindOneGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterFindOneGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterFindOneGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterFindOneGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterFindOneGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterFindOneGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterFindOneGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterFindOneGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterFindOneGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterFindOneGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterFindOneGeoLocationsInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterFindOneGeoLocations_IdOperatorsInput>;
};

export type FilterFindOneGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneGuidesInput = {
  AND?: InputMaybe<Array<FilterFindOneGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindOneGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterFindOneGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterFindOneGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterFindOneGuides_IdOperatorsInput>;
};

export type FilterFindOneGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOnePhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOnePhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterFindOnePhonesInput = {
  AND?: InputMaybe<Array<FilterFindOnePhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindOnePhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOnePhonesOperatorsInput>;
};

export type FilterFindOnePhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOnePhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterFindOnePhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterFindOnePhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterFindOnePhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterFindOnePhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterFindOnePhones_IdOperatorsInput>;
};

export type FilterFindOnePhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOnePhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneSchedulesInput = {
  AND?: InputMaybe<Array<FilterFindOneSchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterFindOneSchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterFindOneSchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneSchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneSchedulesOperatorsInput = {
  _id?: InputMaybe<FilterFindOneSchedules_IdOperatorsInput>;
};

export type FilterFindOneSchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterFindOneSchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterFindOneUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneUsersInput = {
  AND?: InputMaybe<Array<FilterFindOneUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterFindOneUsersInput>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneUsersOperatorsInput>;
};

export type FilterFindOneUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneUsersOperatorsInput = {
  Active?: InputMaybe<FilterFindOneUsersActiveOperatorsInput>;
  FirstName?: InputMaybe<FilterFindOneUsersFirstNameOperatorsInput>;
  LastName?: InputMaybe<FilterFindOneUsersLastNameOperatorsInput>;
  UserGUID?: InputMaybe<FilterFindOneUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterFindOneUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterFindOneUsers_IdOperatorsInput>;
};

export type FilterFindOneUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterRemoveManyActivitiesInput = {
  AND?: InputMaybe<Array<FilterRemoveManyActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterRemoveManyActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterRemoveManyActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterRemoveManyActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterRemoveManyActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyActivities_IdOperatorsInput>;
};

export type FilterRemoveManyActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyAssetsInput = {
  AND?: InputMaybe<Array<FilterRemoveManyAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManyAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterRemoveManyAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyAssets_IdOperatorsInput>;
};

export type FilterRemoveManyAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyBookingsInput = {
  AND?: InputMaybe<Array<FilterRemoveManyBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManyBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterRemoveManyBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterRemoveManyBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyBookings_IdOperatorsInput>;
};

export type FilterRemoveManyBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterRemoveManyGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterRemoveManyGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsSunSetInput>;
};

export type FilterRemoveManyGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterRemoveManyGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterRemoveManyGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterRemoveManyGeoLocationsBoundsSouthwestInput>;
};

export type FilterRemoveManyGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterRemoveManyGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterRemoveManyGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterRemoveManyGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveManyGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveManyGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterRemoveManyGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterRemoveManyGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterRemoveManyGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterRemoveManyGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterRemoveManyGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterRemoveManyGeoLocationsInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterRemoveManyGeoLocations_IdOperatorsInput>;
};

export type FilterRemoveManyGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyGuidesInput = {
  AND?: InputMaybe<Array<FilterRemoveManyGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManyGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterRemoveManyGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterRemoveManyGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyGuides_IdOperatorsInput>;
};

export type FilterRemoveManyGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyPhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyPhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterRemoveManyPhonesInput = {
  AND?: InputMaybe<Array<FilterRemoveManyPhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManyPhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyPhonesOperatorsInput>;
};

export type FilterRemoveManyPhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyPhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterRemoveManyPhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterRemoveManyPhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterRemoveManyPhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterRemoveManyPhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyPhones_IdOperatorsInput>;
};

export type FilterRemoveManyPhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyPhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManySchedulesInput = {
  AND?: InputMaybe<Array<FilterRemoveManySchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManySchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterRemoveManySchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManySchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManySchedulesOperatorsInput = {
  _id?: InputMaybe<FilterRemoveManySchedules_IdOperatorsInput>;
};

export type FilterRemoveManySchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterRemoveManySchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterRemoveManyUsersBirthdayOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type FilterRemoveManyUsersEmailOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyUsersGenderOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersGender>;
  gte?: InputMaybe<EnumUsersGender>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
  lt?: InputMaybe<EnumUsersGender>;
  lte?: InputMaybe<EnumUsersGender>;
  ne?: InputMaybe<EnumUsersGender>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
};

export type FilterRemoveManyUsersInput = {
  AND?: InputMaybe<Array<FilterRemoveManyUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveManyUsersInput>>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveManyUsersOperatorsInput>;
};

export type FilterRemoveManyUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveManyUsersOperatorsInput = {
  Active?: InputMaybe<FilterRemoveManyUsersActiveOperatorsInput>;
  Birthday?: InputMaybe<FilterRemoveManyUsersBirthdayOperatorsInput>;
  Email?: InputMaybe<FilterRemoveManyUsersEmailOperatorsInput>;
  FirstName?: InputMaybe<FilterRemoveManyUsersFirstNameOperatorsInput>;
  Gender?: InputMaybe<FilterRemoveManyUsersGenderOperatorsInput>;
  LastName?: InputMaybe<FilterRemoveManyUsersLastNameOperatorsInput>;
  PhoneID?: InputMaybe<FilterRemoveManyUsersPhoneIdOperatorsInput>;
  SocialProviders?: InputMaybe<FilterRemoveManyUsersSocialProvidersOperatorsInput>;
  UserGUID?: InputMaybe<FilterRemoveManyUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterRemoveManyUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterRemoveManyUsers_IdOperatorsInput>;
};

export type FilterRemoveManyUsersPhoneIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveManyUsersSocialProvidersOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersSocialProviders>;
  gte?: InputMaybe<EnumUsersSocialProviders>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  lt?: InputMaybe<EnumUsersSocialProviders>;
  lte?: InputMaybe<EnumUsersSocialProviders>;
  ne?: InputMaybe<EnumUsersSocialProviders>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
};

export type FilterRemoveManyUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveManyUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterRemoveOneActivitiesInput = {
  AND?: InputMaybe<Array<FilterRemoveOneActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterRemoveOneActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterRemoveOneActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterRemoveOneActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterRemoveOneActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOneActivities_IdOperatorsInput>;
};

export type FilterRemoveOneActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneAssetsInput = {
  AND?: InputMaybe<Array<FilterRemoveOneAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOneAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterRemoveOneAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOneAssets_IdOperatorsInput>;
};

export type FilterRemoveOneAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneBookingsInput = {
  AND?: InputMaybe<Array<FilterRemoveOneBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOneBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterRemoveOneBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterRemoveOneBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOneBookings_IdOperatorsInput>;
};

export type FilterRemoveOneBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterRemoveOneGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterRemoveOneGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsSunSetInput>;
};

export type FilterRemoveOneGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterRemoveOneGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterRemoveOneGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterRemoveOneGeoLocationsBoundsSouthwestInput>;
};

export type FilterRemoveOneGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterRemoveOneGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterRemoveOneGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterRemoveOneGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRemoveOneGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterRemoveOneGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterRemoveOneGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterRemoveOneGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterRemoveOneGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterRemoveOneGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterRemoveOneGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterRemoveOneGeoLocationsInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterRemoveOneGeoLocations_IdOperatorsInput>;
};

export type FilterRemoveOneGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneGuidesInput = {
  AND?: InputMaybe<Array<FilterRemoveOneGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOneGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterRemoveOneGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterRemoveOneGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOneGuides_IdOperatorsInput>;
};

export type FilterRemoveOneGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOnePhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOnePhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterRemoveOnePhonesInput = {
  AND?: InputMaybe<Array<FilterRemoveOnePhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOnePhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOnePhonesOperatorsInput>;
};

export type FilterRemoveOnePhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOnePhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterRemoveOnePhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterRemoveOnePhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterRemoveOnePhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterRemoveOnePhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOnePhones_IdOperatorsInput>;
};

export type FilterRemoveOnePhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOnePhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneSchedulesInput = {
  AND?: InputMaybe<Array<FilterRemoveOneSchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOneSchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterRemoveOneSchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneSchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneSchedulesOperatorsInput = {
  _id?: InputMaybe<FilterRemoveOneSchedules_IdOperatorsInput>;
};

export type FilterRemoveOneSchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterRemoveOneSchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterRemoveOneUsersBirthdayOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type FilterRemoveOneUsersEmailOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneUsersGenderOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersGender>;
  gte?: InputMaybe<EnumUsersGender>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
  lt?: InputMaybe<EnumUsersGender>;
  lte?: InputMaybe<EnumUsersGender>;
  ne?: InputMaybe<EnumUsersGender>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
};

export type FilterRemoveOneUsersInput = {
  AND?: InputMaybe<Array<FilterRemoveOneUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterRemoveOneUsersInput>>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneUsersOperatorsInput>;
};

export type FilterRemoveOneUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneUsersOperatorsInput = {
  Active?: InputMaybe<FilterRemoveOneUsersActiveOperatorsInput>;
  Birthday?: InputMaybe<FilterRemoveOneUsersBirthdayOperatorsInput>;
  Email?: InputMaybe<FilterRemoveOneUsersEmailOperatorsInput>;
  FirstName?: InputMaybe<FilterRemoveOneUsersFirstNameOperatorsInput>;
  Gender?: InputMaybe<FilterRemoveOneUsersGenderOperatorsInput>;
  LastName?: InputMaybe<FilterRemoveOneUsersLastNameOperatorsInput>;
  PhoneID?: InputMaybe<FilterRemoveOneUsersPhoneIdOperatorsInput>;
  SocialProviders?: InputMaybe<FilterRemoveOneUsersSocialProvidersOperatorsInput>;
  UserGUID?: InputMaybe<FilterRemoveOneUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterRemoveOneUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterRemoveOneUsers_IdOperatorsInput>;
};

export type FilterRemoveOneUsersPhoneIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneUsersSocialProvidersOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersSocialProviders>;
  gte?: InputMaybe<EnumUsersSocialProviders>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  lt?: InputMaybe<EnumUsersSocialProviders>;
  lte?: InputMaybe<EnumUsersSocialProviders>;
  ne?: InputMaybe<EnumUsersSocialProviders>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
};

export type FilterRemoveOneUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterRemoveOneUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterUpdateManyActivitiesInput = {
  AND?: InputMaybe<Array<FilterUpdateManyActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterUpdateManyActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterUpdateManyActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterUpdateManyActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterUpdateManyActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyActivities_IdOperatorsInput>;
};

export type FilterUpdateManyActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyAssetsInput = {
  AND?: InputMaybe<Array<FilterUpdateManyAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManyAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterUpdateManyAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyAssets_IdOperatorsInput>;
};

export type FilterUpdateManyAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyBookingsInput = {
  AND?: InputMaybe<Array<FilterUpdateManyBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManyBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterUpdateManyBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterUpdateManyBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyBookings_IdOperatorsInput>;
};

export type FilterUpdateManyBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterUpdateManyGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterUpdateManyGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsSunSetInput>;
};

export type FilterUpdateManyGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterUpdateManyGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterUpdateManyGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterUpdateManyGeoLocationsBoundsSouthwestInput>;
};

export type FilterUpdateManyGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterUpdateManyGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterUpdateManyGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterUpdateManyGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateManyGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateManyGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterUpdateManyGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterUpdateManyGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterUpdateManyGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterUpdateManyGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterUpdateManyGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterUpdateManyGeoLocationsInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterUpdateManyGeoLocations_IdOperatorsInput>;
};

export type FilterUpdateManyGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyGuidesInput = {
  AND?: InputMaybe<Array<FilterUpdateManyGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManyGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterUpdateManyGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterUpdateManyGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyGuides_IdOperatorsInput>;
};

export type FilterUpdateManyGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyPhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyPhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterUpdateManyPhonesInput = {
  AND?: InputMaybe<Array<FilterUpdateManyPhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManyPhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyPhonesOperatorsInput>;
};

export type FilterUpdateManyPhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyPhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterUpdateManyPhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterUpdateManyPhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterUpdateManyPhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterUpdateManyPhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyPhones_IdOperatorsInput>;
};

export type FilterUpdateManyPhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyPhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManySchedulesInput = {
  AND?: InputMaybe<Array<FilterUpdateManySchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManySchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterUpdateManySchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManySchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManySchedulesOperatorsInput = {
  _id?: InputMaybe<FilterUpdateManySchedules_IdOperatorsInput>;
};

export type FilterUpdateManySchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterUpdateManySchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterUpdateManyUsersBirthdayOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type FilterUpdateManyUsersEmailOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyUsersGenderOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersGender>;
  gte?: InputMaybe<EnumUsersGender>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
  lt?: InputMaybe<EnumUsersGender>;
  lte?: InputMaybe<EnumUsersGender>;
  ne?: InputMaybe<EnumUsersGender>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
};

export type FilterUpdateManyUsersInput = {
  AND?: InputMaybe<Array<FilterUpdateManyUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateManyUsersInput>>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateManyUsersOperatorsInput>;
};

export type FilterUpdateManyUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateManyUsersOperatorsInput = {
  Active?: InputMaybe<FilterUpdateManyUsersActiveOperatorsInput>;
  Birthday?: InputMaybe<FilterUpdateManyUsersBirthdayOperatorsInput>;
  Email?: InputMaybe<FilterUpdateManyUsersEmailOperatorsInput>;
  FirstName?: InputMaybe<FilterUpdateManyUsersFirstNameOperatorsInput>;
  Gender?: InputMaybe<FilterUpdateManyUsersGenderOperatorsInput>;
  LastName?: InputMaybe<FilterUpdateManyUsersLastNameOperatorsInput>;
  PhoneID?: InputMaybe<FilterUpdateManyUsersPhoneIdOperatorsInput>;
  SocialProviders?: InputMaybe<FilterUpdateManyUsersSocialProvidersOperatorsInput>;
  UserGUID?: InputMaybe<FilterUpdateManyUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterUpdateManyUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterUpdateManyUsers_IdOperatorsInput>;
};

export type FilterUpdateManyUsersPhoneIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateManyUsersSocialProvidersOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersSocialProviders>;
  gte?: InputMaybe<EnumUsersSocialProviders>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  lt?: InputMaybe<EnumUsersSocialProviders>;
  lte?: InputMaybe<EnumUsersSocialProviders>;
  ne?: InputMaybe<EnumUsersSocialProviders>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
};

export type FilterUpdateManyUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateManyUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneActivitiesActivityGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterUpdateOneActivitiesInput = {
  AND?: InputMaybe<Array<FilterUpdateOneActivitiesInput>>;
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<FilterUpdateOneActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  OR?: InputMaybe<Array<FilterUpdateOneActivitiesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneActivitiesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneActivitiesOperatorsInput = {
  ActivityGUID?: InputMaybe<FilterUpdateOneActivitiesActivityGuidOperatorsInput>;
  Title?: InputMaybe<FilterUpdateOneActivitiesTitleOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOneActivities_IdOperatorsInput>;
};

export type FilterUpdateOneActivitiesTitleOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneActivities_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneAssetsAssetGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneAssetsInput = {
  AND?: InputMaybe<Array<FilterUpdateOneAssetsInput>>;
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOneAssetsInput>>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneAssetsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneAssetsOperatorsInput = {
  AssetGUID?: InputMaybe<FilterUpdateOneAssetsAssetGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOneAssets_IdOperatorsInput>;
};

export type FilterUpdateOneAssets_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneBookingsActivityIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneBookingsBookingGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneBookingsInput = {
  AND?: InputMaybe<Array<FilterUpdateOneBookingsInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOneBookingsInput>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneBookingsOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneBookingsOperatorsInput = {
  ActivityID?: InputMaybe<FilterUpdateOneBookingsActivityIdOperatorsInput>;
  BookingGUID?: InputMaybe<FilterUpdateOneBookingsBookingGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOneBookings_IdOperatorsInput>;
};

export type FilterUpdateOneBookings_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput>;
};

export type FilterUpdateOneGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsNutsnuts3Input>;
};

export type FilterUpdateOneGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsSunSetInput>;
};

export type FilterUpdateOneGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type FilterUpdateOneGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsBoundsInput = {
  northeast?: InputMaybe<FilterUpdateOneGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<FilterUpdateOneGeoLocationsBoundsSouthwestInput>;
};

export type FilterUpdateOneGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<FilterUpdateOneGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FilterUpdateOneGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsComponentsInput = {
  ISO?: InputMaybe<FilterUpdateOneGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUpdateOneGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterUpdateOneGeoLocationsInput = {
  AND?: InputMaybe<Array<FilterUpdateOneGeoLocationsInput>>;
  Annotations?: InputMaybe<FilterUpdateOneGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<FilterUpdateOneGeoLocationsBoundsInput>;
  Components?: InputMaybe<FilterUpdateOneGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<FilterUpdateOneGeoLocationsGeometryInput>;
  OR?: InputMaybe<Array<FilterUpdateOneGeoLocationsInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneGeoLocationsOperatorsInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneGeoLocationsOperatorsInput = {
  _id?: InputMaybe<FilterUpdateOneGeoLocations_IdOperatorsInput>;
};

export type FilterUpdateOneGeoLocations_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneGuidesGuideGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneGuidesInput = {
  AND?: InputMaybe<Array<FilterUpdateOneGuidesInput>>;
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOneGuidesInput>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneGuidesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneGuidesOperatorsInput = {
  GuideGUID?: InputMaybe<FilterUpdateOneGuidesGuideGuidOperatorsInput>;
  UserID?: InputMaybe<FilterUpdateOneGuidesUserIdOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOneGuides_IdOperatorsInput>;
};

export type FilterUpdateOneGuidesUserIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneGuides_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOnePhonesCallingCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOnePhonesCountryCodeOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumPhonesCountryCode>;
  gte?: InputMaybe<EnumPhonesCountryCode>;
  in?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
  lt?: InputMaybe<EnumPhonesCountryCode>;
  lte?: InputMaybe<EnumPhonesCountryCode>;
  ne?: InputMaybe<EnumPhonesCountryCode>;
  nin?: InputMaybe<Array<InputMaybe<EnumPhonesCountryCode>>>;
};

export type FilterUpdateOnePhonesInput = {
  AND?: InputMaybe<Array<FilterUpdateOnePhonesInput>>;
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOnePhonesInput>>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOnePhonesOperatorsInput>;
};

export type FilterUpdateOnePhonesNumberOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOnePhonesOperatorsInput = {
  CallingCode?: InputMaybe<FilterUpdateOnePhonesCallingCodeOperatorsInput>;
  CountryCode?: InputMaybe<FilterUpdateOnePhonesCountryCodeOperatorsInput>;
  Number?: InputMaybe<FilterUpdateOnePhonesNumberOperatorsInput>;
  PhoneGUID?: InputMaybe<FilterUpdateOnePhonesPhoneGuidOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOnePhones_IdOperatorsInput>;
};

export type FilterUpdateOnePhonesPhoneGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOnePhones_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneSchedulesInput = {
  AND?: InputMaybe<Array<FilterUpdateOneSchedulesInput>>;
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOneSchedulesInput>>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<FilterUpdateOneSchedulesRangesInput>>>>
  >;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneSchedulesOperatorsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneSchedulesOperatorsInput = {
  _id?: InputMaybe<FilterUpdateOneSchedules_IdOperatorsInput>;
};

export type FilterUpdateOneSchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FilterUpdateOneSchedules_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneUsersActiveOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type FilterUpdateOneUsersBirthdayOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type FilterUpdateOneUsersEmailOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneUsersFirstNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneUsersGenderOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersGender>;
  gte?: InputMaybe<EnumUsersGender>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
  lt?: InputMaybe<EnumUsersGender>;
  lte?: InputMaybe<EnumUsersGender>;
  ne?: InputMaybe<EnumUsersGender>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersGender>>>;
};

export type FilterUpdateOneUsersInput = {
  AND?: InputMaybe<Array<FilterUpdateOneUsersInput>>;
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  OR?: InputMaybe<Array<FilterUpdateOneUsersInput>>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterUpdateOneUsersOperatorsInput>;
};

export type FilterUpdateOneUsersLastNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterUpdateOneUsersOperatorsInput = {
  Active?: InputMaybe<FilterUpdateOneUsersActiveOperatorsInput>;
  Birthday?: InputMaybe<FilterUpdateOneUsersBirthdayOperatorsInput>;
  Email?: InputMaybe<FilterUpdateOneUsersEmailOperatorsInput>;
  FirstName?: InputMaybe<FilterUpdateOneUsersFirstNameOperatorsInput>;
  Gender?: InputMaybe<FilterUpdateOneUsersGenderOperatorsInput>;
  LastName?: InputMaybe<FilterUpdateOneUsersLastNameOperatorsInput>;
  PhoneID?: InputMaybe<FilterUpdateOneUsersPhoneIdOperatorsInput>;
  SocialProviders?: InputMaybe<FilterUpdateOneUsersSocialProvidersOperatorsInput>;
  UserGUID?: InputMaybe<FilterUpdateOneUsersUserGuidOperatorsInput>;
  UserName?: InputMaybe<FilterUpdateOneUsersUserNameOperatorsInput>;
  _id?: InputMaybe<FilterUpdateOneUsers_IdOperatorsInput>;
};

export type FilterUpdateOneUsersPhoneIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterUpdateOneUsersSocialProvidersOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<EnumUsersSocialProviders>;
  gte?: InputMaybe<EnumUsersSocialProviders>;
  in?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  lt?: InputMaybe<EnumUsersSocialProviders>;
  lte?: InputMaybe<EnumUsersSocialProviders>;
  ne?: InputMaybe<EnumUsersSocialProviders>;
  nin?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
};

export type FilterUpdateOneUsersUserGuidOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneUsersUserNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterUpdateOneUsers_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export enum FinishReason {
  content_filter = 'content_filter',
  function_call = 'function_call',
  length = 'length',
  stop = 'stop',
  tool_calls = 'tool_calls',
}

export type FunctionCall = {
  arguments: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GeoLocationSearchInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  q: Scalars['String']['input'];
};

export type GeoLocations = {
  Annotations?: Maybe<GeoLocationsAnnotations>;
  Bounds?: Maybe<GeoLocationsBounds>;
  Components?: Maybe<GeoLocationsComponents>;
  Confidence?: Maybe<Scalars['Float']['output']>;
  Distance?: Maybe<Scalars['Float']['output']>;
  Formatted?: Maybe<Scalars['String']['output']>;
  GeoLocationGUID?: Maybe<Scalars['String']['output']>;
  Geometry?: Maybe<GeoLocationsGeometry>;
  _id: Scalars['MongoID']['output'];
};

export type GeoLocationsAnnotations = {
  DMS?: Maybe<GeoLocationsAnnotationsDms>;
  MGRS?: Maybe<Scalars['String']['output']>;
  Maidenhead?: Maybe<Scalars['String']['output']>;
  Mercator?: Maybe<GeoLocationsAnnotationsMercator>;
  NUTS?: Maybe<GeoLocationsAnnotationsNuts>;
  OSM?: Maybe<GeoLocationsAnnotationsOsm>;
  UN_M49?: Maybe<GeoLocationsAnnotationsUn_M49>;
  callingcode?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<GeoLocationsAnnotationsCurrency>;
  flag?: Maybe<Scalars['String']['output']>;
  geohash?: Maybe<Scalars['String']['output']>;
  qibla?: Maybe<Scalars['Float']['output']>;
  roadinfo?: Maybe<GeoLocationsAnnotationsRoadinfo>;
  sun?: Maybe<GeoLocationsAnnotationsSun>;
  timezone?: Maybe<GeoLocationsAnnotationsTimezone>;
  what3words?: Maybe<GeoLocationsAnnotationsWhat3words>;
};

export type GeoLocationsAnnotationsCurrency = {
  _id?: Maybe<Scalars['MongoID']['output']>;
  alternate_symbols?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  decimal_mark?: Maybe<Scalars['String']['output']>;
  html_entity?: Maybe<Scalars['String']['output']>;
  iso_code?: Maybe<Scalars['String']['output']>;
  iso_numeric?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  smallest_denomination?: Maybe<Scalars['Float']['output']>;
  subunit?: Maybe<Scalars['String']['output']>;
  subunit_to_unit?: Maybe<Scalars['Float']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  symbol_first?: Maybe<Scalars['Float']['output']>;
  thousands_separator?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsDms = {
  lat?: Maybe<Scalars['String']['output']>;
  lng?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<GeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<GeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<GeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<GeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<GeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<GeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<GeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<GeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<GeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<GeoLocationsAnnotationsWhat3wordsInput>;
};

export type GeoLocationsAnnotationsMercator = {
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type GeoLocationsAnnotationsNuts = {
  NUTS0?: Maybe<GeoLocationsAnnotationsNutsnuts0>;
  NUTS1?: Maybe<GeoLocationsAnnotationsNutsnuts1>;
  NUTS2?: Maybe<GeoLocationsAnnotationsNutsnuts2>;
  NUTS3?: Maybe<GeoLocationsAnnotationsNutsnuts3>;
};

export type GeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<GeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<GeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<GeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<GeoLocationsAnnotationsNutsnuts3Input>;
};

export type GeoLocationsAnnotationsNutsnuts0 = {
  code?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsNutsnuts1 = {
  code?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsNutsnuts2 = {
  code?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsNutsnuts3 = {
  code?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsOsm = {
  edit_url?: Maybe<Scalars['String']['output']>;
  note_url?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsRoadinfo = {
  drive_on?: Maybe<Scalars['String']['output']>;
  road?: Maybe<Scalars['String']['output']>;
  speed_in?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsSun = {
  rise?: Maybe<GeoLocationsAnnotationsSunRise>;
  set?: Maybe<GeoLocationsAnnotationsSunSet>;
};

export type GeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<GeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<GeoLocationsAnnotationsSunSetInput>;
};

export type GeoLocationsAnnotationsSunRise = {
  apparent?: Maybe<Scalars['Float']['output']>;
  astronomical?: Maybe<Scalars['Float']['output']>;
  civil?: Maybe<Scalars['Float']['output']>;
  nautical?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type GeoLocationsAnnotationsSunSet = {
  apparent?: Maybe<Scalars['Float']['output']>;
  astronomical?: Maybe<Scalars['Float']['output']>;
  civil?: Maybe<Scalars['Float']['output']>;
  nautical?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type GeoLocationsAnnotationsTimezone = {
  name?: Maybe<Scalars['String']['output']>;
  now_in_dst?: Maybe<Scalars['Float']['output']>;
  offset_sec?: Maybe<Scalars['Float']['output']>;
  offset_string?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsUn_M49 = {
  regions?: Maybe<GeoLocationsAnnotationsUn_M49Regions>;
};

export type GeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<GeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type GeoLocationsAnnotationsUn_M49Regions = {
  DE?: Maybe<Scalars['String']['output']>;
  EUROPE?: Maybe<Scalars['String']['output']>;
  WESTERN_EUROPE?: Maybe<Scalars['String']['output']>;
  WORLD?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsAnnotationsWhat3words = {
  words?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsBounds = {
  northeast?: Maybe<GeoLocationsBoundsNortheast>;
  southwest?: Maybe<GeoLocationsBoundsSouthwest>;
};

export type GeoLocationsBoundsInput = {
  northeast?: InputMaybe<GeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<GeoLocationsBoundsSouthwestInput>;
};

export type GeoLocationsBoundsNortheast = {
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type GeoLocationsBoundsSouthwest = {
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type GeoLocationsComponents = {
  ISO?: Maybe<GeoLocationsComponentsIso>;
  _category?: Maybe<Scalars['String']['output']>;
  _normalized_city?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  city_district?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  political_union?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  road?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  state_code?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsComponentsIso = {
  v1?: Maybe<GeoLocationsComponentsIsov1>;
  v2?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<GeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GeoLocationsComponentsIsov1 = {
  alpha2?: Maybe<Scalars['String']['output']>;
  alpha3?: Maybe<Scalars['String']['output']>;
};

export type GeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsComponentsInput = {
  ISO?: InputMaybe<GeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type GeoLocationsGeometry = {
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

/** List of items with pagination. */
export type GeoLocationsPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<GeoLocations>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Guides = {
  Biography?: Maybe<Scalars['String']['output']>;
  FavoredBy?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  GuideGUID?: Maybe<Scalars['String']['output']>;
  ScheduleID?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  Schedules: Array<Maybe<Schedules>>;
  User?: Maybe<Users>;
  UserID: Scalars['MongoID']['output'];
  _id: Scalars['MongoID']['output'];
};

/** List of items with pagination. */
export type GuidesPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Guides>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Logprobs = {
  content?: Maybe<Array<Maybe<ChatCompletionTokenLogprob>>>;
};

export type Message = {
  content: Scalars['String']['output'];
  function_call?: Maybe<FunctionCall>;
  role: Role;
  tool_calls?: Maybe<Array<Maybe<FunctionCall>>>;
};

export type Mutation = {
  Activate?: Maybe<Activate>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddActivities?: Maybe<CreateManyActivitiesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddActivity?: Maybe<CreateOneActivitiesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddAsset?: Maybe<CreateOneAssetsPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddAssets?: Maybe<CreateManyAssetsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddBooking?: Maybe<CreateOneBookingsPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddBookings?: Maybe<CreateManyBookingsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddGeoLocation?: Maybe<CreateOneGeoLocationsPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddGeoLocations?: Maybe<CreateManyGeoLocationsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddGuide?: Maybe<CreateOneGuidesPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddGuides?: Maybe<CreateManyGuidesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddPhone?: Maybe<CreateOnePhonesPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddPhones?: Maybe<CreateManyPhonesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddSchedule?: Maybe<CreateOneSchedulesPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddSchedules?: Maybe<CreateManySchedulesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AddUser?: Maybe<CreateOneUsersPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  AddUsers?: Maybe<CreateManyUsersPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteActivities?: Maybe<RemoveManyActivitiesPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteActivity?: Maybe<RemoveOneActivitiesPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteAsset?: Maybe<RemoveOneAssetsPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteAssets?: Maybe<RemoveManyAssetsPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteBooking?: Maybe<RemoveOneBookingsPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteBookings?: Maybe<RemoveManyBookingsPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteGeoLocation?: Maybe<RemoveOneGeoLocationsPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteGeoLocations?: Maybe<RemoveManyGeoLocationsPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteGuide?: Maybe<RemoveOneGuidesPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteGuides?: Maybe<RemoveManyGuidesPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeletePhone?: Maybe<RemoveOnePhonesPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeletePhones?: Maybe<RemoveManyPhonesPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteSchedule?: Maybe<RemoveOneSchedulesPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteSchedules?: Maybe<RemoveManySchedulesPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  DeleteUser?: Maybe<RemoveOneUsersPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  DeleteUsers?: Maybe<RemoveManyUsersPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PredictActivity?: Maybe<CreateOneActivitiesPayload>;
  RefreshToken?: Maybe<RefreshToken>;
  Register?: Maybe<Register>;
  SignIn?: Maybe<SignIn>;
  SignOut?: Maybe<SignOut>;
  SocialSignIn?: Maybe<SocialSignIn>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateActivities?: Maybe<UpdateManyActivitiesPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateActivity?: Maybe<UpdateOneActivitiesPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateAsset?: Maybe<UpdateOneAssetsPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateAssets?: Maybe<UpdateManyAssetsPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateBooking?: Maybe<UpdateOneBookingsPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateBookings?: Maybe<UpdateManyBookingsPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateGeoLocation?: Maybe<UpdateOneGeoLocationsPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateGeoLocations?: Maybe<UpdateManyGeoLocationsPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateGuide?: Maybe<UpdateOneGuidesPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateGuides?: Maybe<UpdateManyGuidesPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdatePhone?: Maybe<UpdateOnePhonesPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdatePhones?: Maybe<UpdateManyPhonesPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateSchedule?: Maybe<UpdateOneSchedulesPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateSchedules?: Maybe<UpdateManySchedulesPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  UpdateUser?: Maybe<UpdateOneUsersPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  UpdateUsers?: Maybe<UpdateManyUsersPayload>;
  UploadAsset?: Maybe<UploadAsset>;
  UploadAssets?: Maybe<Array<Maybe<UploadAsset>>>;
};

export type MutationActivateArgs = {
  Activate: ActivateInput;
};

export type MutationAddActivitiesArgs = {
  records: Array<CreateManyActivitiesInput>;
};

export type MutationAddActivityArgs = {
  record: CreateOneActivitiesInput;
};

export type MutationAddAssetArgs = {
  record: CreateOneAssetsInput;
};

export type MutationAddAssetsArgs = {
  records: Array<CreateManyAssetsInput>;
};

export type MutationAddBookingArgs = {
  record: CreateOneBookingsInput;
};

export type MutationAddBookingsArgs = {
  records: Array<CreateManyBookingsInput>;
};

export type MutationAddGeoLocationArgs = {
  record: CreateOneGeoLocationsInput;
};

export type MutationAddGeoLocationsArgs = {
  records: Array<CreateManyGeoLocationsInput>;
};

export type MutationAddGuideArgs = {
  record: CreateOneGuidesInput;
};

export type MutationAddGuidesArgs = {
  records: Array<CreateManyGuidesInput>;
};

export type MutationAddPhoneArgs = {
  record: CreateOnePhonesInput;
};

export type MutationAddPhonesArgs = {
  records: Array<CreateManyPhonesInput>;
};

export type MutationAddScheduleArgs = {
  record: CreateOneSchedulesInput;
};

export type MutationAddSchedulesArgs = {
  records: Array<CreateManySchedulesInput>;
};

export type MutationAddUserArgs = {
  record: CreateOneUsersInput;
};

export type MutationAddUsersArgs = {
  records: Array<CreateManyUsersInput>;
};

export type MutationDeleteActivitiesArgs = {
  filter: FilterRemoveManyActivitiesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteActivityArgs = {
  filter: FilterRemoveOneActivitiesInput;
  sort?: InputMaybe<SortRemoveOneActivitiesInput>;
};

export type MutationDeleteAssetArgs = {
  filter: FilterRemoveOneAssetsInput;
  sort?: InputMaybe<SortRemoveOneAssetsInput>;
};

export type MutationDeleteAssetsArgs = {
  filter: FilterRemoveManyAssetsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteBookingArgs = {
  filter: FilterRemoveOneBookingsInput;
  sort?: InputMaybe<SortRemoveOneBookingsInput>;
};

export type MutationDeleteBookingsArgs = {
  filter: FilterRemoveManyBookingsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteGeoLocationArgs = {
  filter: FilterRemoveOneGeoLocationsInput;
  sort?: InputMaybe<SortRemoveOneGeoLocationsInput>;
};

export type MutationDeleteGeoLocationsArgs = {
  filter: FilterRemoveManyGeoLocationsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteGuideArgs = {
  filter: FilterRemoveOneGuidesInput;
  sort?: InputMaybe<SortRemoveOneGuidesInput>;
};

export type MutationDeleteGuidesArgs = {
  filter: FilterRemoveManyGuidesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeletePhoneArgs = {
  filter: FilterRemoveOnePhonesInput;
  sort?: InputMaybe<SortRemoveOnePhonesInput>;
};

export type MutationDeletePhonesArgs = {
  filter: FilterRemoveManyPhonesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteScheduleArgs = {
  filter: FilterRemoveOneSchedulesInput;
  sort?: InputMaybe<SortRemoveOneSchedulesInput>;
};

export type MutationDeleteSchedulesArgs = {
  filter: FilterRemoveManySchedulesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteUserArgs = {
  filter: FilterRemoveOneUsersInput;
  sort?: InputMaybe<SortRemoveOneUsersInput>;
};

export type MutationDeleteUsersArgs = {
  filter: FilterRemoveManyUsersInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationPredictActivityArgs = {
  Activity: Scalars['String']['input'];
  record?: InputMaybe<CreateOneActivitiesInput>;
};

export type MutationRefreshTokenArgs = {
  RefreshToken: RefreshTokenInput;
};

export type MutationRegisterArgs = {
  Register: RegisterInput;
};

export type MutationSignInArgs = {
  SignIn: SignInInput;
};

export type MutationSignOutArgs = {
  SignOut: SignOutInput;
};

export type MutationSocialSignInArgs = {
  SocialSignIn: SocialSignInInput;
};

export type MutationUpdateActivitiesArgs = {
  filter: FilterUpdateManyActivitiesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyActivitiesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyActivitiesInput>;
};

export type MutationUpdateActivityArgs = {
  filter: FilterUpdateOneActivitiesInput;
  record: UpdateOneActivitiesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneActivitiesInput>;
};

export type MutationUpdateAssetArgs = {
  filter: FilterUpdateOneAssetsInput;
  record: UpdateOneAssetsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneAssetsInput>;
};

export type MutationUpdateAssetsArgs = {
  filter: FilterUpdateManyAssetsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyAssetsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyAssetsInput>;
};

export type MutationUpdateBookingArgs = {
  filter: FilterUpdateOneBookingsInput;
  record: UpdateOneBookingsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneBookingsInput>;
};

export type MutationUpdateBookingsArgs = {
  filter: FilterUpdateManyBookingsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyBookingsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyBookingsInput>;
};

export type MutationUpdateGeoLocationArgs = {
  filter: FilterUpdateOneGeoLocationsInput;
  record: UpdateOneGeoLocationsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneGeoLocationsInput>;
};

export type MutationUpdateGeoLocationsArgs = {
  filter: FilterUpdateManyGeoLocationsInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyGeoLocationsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyGeoLocationsInput>;
};

export type MutationUpdateGuideArgs = {
  filter: FilterUpdateOneGuidesInput;
  record: UpdateOneGuidesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneGuidesInput>;
};

export type MutationUpdateGuidesArgs = {
  filter: FilterUpdateManyGuidesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyGuidesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyGuidesInput>;
};

export type MutationUpdatePhoneArgs = {
  filter: FilterUpdateOnePhonesInput;
  record: UpdateOnePhonesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOnePhonesInput>;
};

export type MutationUpdatePhonesArgs = {
  filter: FilterUpdateManyPhonesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyPhonesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyPhonesInput>;
};

export type MutationUpdateScheduleArgs = {
  filter: FilterUpdateOneSchedulesInput;
  record: UpdateOneSchedulesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneSchedulesInput>;
};

export type MutationUpdateSchedulesArgs = {
  filter: FilterUpdateManySchedulesInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManySchedulesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManySchedulesInput>;
};

export type MutationUpdateUserArgs = {
  filter: FilterUpdateOneUsersInput;
  record: UpdateOneUsersInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateOneUsersInput>;
};

export type MutationUpdateUsersArgs = {
  filter: FilterUpdateManyUsersInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  record: UpdateManyUsersInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortUpdateManyUsersInput>;
};

export type MutationUploadAssetArgs = {
  UploadAsset: UploadAssetInput;
};

export type MutationUploadAssetsArgs = {
  UploadAssets: Array<UploadAssetInput>;
};

export type PaginationInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
};

export type Phones = {
  CallingCode: Scalars['String']['output'];
  CountryCode: EnumPhonesCountryCode;
  Number: Scalars['String']['output'];
  PhoneGUID?: Maybe<Scalars['String']['output']>;
  _id: Scalars['MongoID']['output'];
};

/** List of items with pagination. */
export type PhonesPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Phones>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Profile = {
  Active: Scalars['Boolean']['output'];
  Asset?: Maybe<Assets>;
  AssetID?: Maybe<Scalars['MongoID']['output']>;
  Birthday: Scalars['Date']['output'];
  Country?: Maybe<Countries>;
  CountryID?: Maybe<Scalars['MongoID']['output']>;
  Email: Scalars['String']['output'];
  FirstName?: Maybe<Scalars['String']['output']>;
  Gender?: Maybe<EnumUsersGender>;
  GeoLocation?: Maybe<GeoLocations>;
  GeoLocationID?: Maybe<Scalars['MongoID']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Password: Scalars['String']['output'];
  Phone?: Maybe<Phones>;
  PhoneID?: Maybe<Scalars['MongoID']['output']>;
  SocialProviders?: Maybe<Array<Maybe<EnumUsersSocialProviders>>>;
  UserGUID?: Maybe<Scalars['String']['output']>;
  UserName: Scalars['String']['output'];
  _id: Scalars['MongoID']['output'];
};

export enum Provider {
  apple = 'apple',
  google = 'google',
}

export type Query = {
  Activities?: Maybe<ActivitiesPagination>;
  Activity?: Maybe<Activities>;
  Asset?: Maybe<Assets>;
  Assets?: Maybe<AssetsPagination>;
  Booking?: Maybe<Bookings>;
  Bookings?: Maybe<BookingsPagination>;
  Countries: Array<Countries>;
  Country?: Maybe<Countries>;
  GeoLocation?: Maybe<GeoLocations>;
  GeoLocationSearch: Array<GeoLocations>;
  GeoLocations?: Maybe<GeoLocationsPagination>;
  Guide?: Maybe<Guides>;
  Guides?: Maybe<GuidesPagination>;
  Phone?: Maybe<Phones>;
  Phones?: Maybe<PhonesPagination>;
  Profile?: Maybe<Profile>;
  Schedule?: Maybe<Schedules>;
  Schedules?: Maybe<SchedulesPagination>;
  Suggestions?: Maybe<Array<Suggestion>>;
  User?: Maybe<Users>;
  UserLocationLookup?: Maybe<UserLocationLookup>;
  Users?: Maybe<UsersPagination>;
};

export type QueryActivitiesArgs = {
  filter: FilterFindManyActivitiesInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyActivitiesInput>;
};

export type QueryActivityArgs = {
  filter: FilterFindOneActivitiesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneActivitiesInput>;
};

export type QueryAssetArgs = {
  filter: FilterFindOneAssetsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneAssetsInput>;
};

export type QueryAssetsArgs = {
  filter: FilterFindManyAssetsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyAssetsInput>;
};

export type QueryBookingArgs = {
  filter: FilterFindOneBookingsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneBookingsInput>;
};

export type QueryBookingsArgs = {
  filter?: InputMaybe<FilterFindManyBookingsInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyBookingsInput>;
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<FilterFindManyCountriesInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyCountriesInput>;
};

export type QueryCountryArgs = {
  filter: FilterFindOneCountriesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneCountriesInput>;
};

export type QueryGeoLocationArgs = {
  filter: FilterFindOneGeoLocationsInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneGeoLocationsInput>;
};

export type QueryGeoLocationSearchArgs = {
  GeoLocationSearchInput?: InputMaybe<GeoLocationSearchInput>;
};

export type QueryGeoLocationsArgs = {
  filter: FilterFindManyGeoLocationsInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyGeoLocationsInput>;
};

export type QueryGuideArgs = {
  filter: FilterFindOneGuidesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneGuidesInput>;
};

export type QueryGuidesArgs = {
  filter: FilterFindManyGuidesInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyGuidesInput>;
};

export type QueryPhoneArgs = {
  filter: FilterFindOnePhonesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOnePhonesInput>;
};

export type QueryPhonesArgs = {
  filter: FilterFindManyPhonesInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyPhonesInput>;
};

export type QueryScheduleArgs = {
  filter: FilterFindOneSchedulesInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneSchedulesInput>;
};

export type QuerySchedulesArgs = {
  filter: FilterFindManySchedulesInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManySchedulesInput>;
};

export type QuerySuggestionsArgs = {
  Suggestions: SuggestionsInput;
};

export type QueryUserArgs = {
  filter: FilterFindOneUsersInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneUsersInput>;
};

export type QueryUserLocationLookupArgs = {
  UserLocationLookup: UserLocationLookupInput;
};

export type QueryUsersArgs = {
  filter: FilterFindManyUsersInput;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyUsersInput>;
};

export type RefreshToken = {
  AccessToken?: Maybe<Scalars['JWT']['output']>;
  ExpiresIn?: Maybe<Scalars['Int']['output']>;
};

export type RefreshTokenInput = {
  RefreshToken?: InputMaybe<Scalars['JWT']['input']>;
};

export type Register = {
  RegistrationGUID?: Maybe<Scalars['UUID']['output']>;
};

export type RegisterInput = {
  Email?: InputMaybe<Scalars['EmailAddress']['input']>;
  Guide?: InputMaybe<Scalars['Boolean']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveManyActivitiesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyAssetsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyBookingsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyGeoLocationsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyGuidesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyPhonesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManySchedulesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveManyUsersPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type RemoveOneActivitiesPayload = {
  /** Removed document */
  record?: Maybe<Activities>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneAssetsPayload = {
  /** Removed document */
  record?: Maybe<Assets>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneBookingsPayload = {
  /** Removed document */
  record?: Maybe<Bookings>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneGeoLocationsPayload = {
  /** Removed document */
  record?: Maybe<GeoLocations>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneGuidesPayload = {
  /** Removed document */
  record?: Maybe<Guides>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOnePhonesPayload = {
  /** Removed document */
  record?: Maybe<Phones>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneSchedulesPayload = {
  /** Removed document */
  record?: Maybe<Schedules>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneUsersPayload = {
  /** Removed document */
  record?: Maybe<Users>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export enum Role {
  assistant = 'assistant',
}

export type Schedules = {
  Activity?: Maybe<Activities>;
  ActivityID?: Maybe<Scalars['MongoID']['output']>;
  Guide?: Maybe<Guides>;
  GuideID?: Maybe<Scalars['MongoID']['output']>;
  Intervals?: Maybe<Scalars['Float']['output']>;
  Ranges?: Maybe<Array<Maybe<Array<Maybe<SchedulesRanges>>>>>;
  _id: Scalars['MongoID']['output'];
};

/** List of items with pagination. */
export type SchedulesPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Schedules>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SchedulesRanges = {
  From?: Maybe<Scalars['String']['output']>;
  To?: Maybe<Scalars['String']['output']>;
  _id?: Maybe<Scalars['MongoID']['output']>;
};

export type SchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type SignIn = {
  AccessToken?: Maybe<Scalars['JWT']['output']>;
  CreatedAt?: Maybe<Scalars['DateTime']['output']>;
  ExpiresIn?: Maybe<Scalars['Int']['output']>;
  LastSignedInAt?: Maybe<Scalars['DateTime']['output']>;
  RefetchIn?: Maybe<Scalars['Int']['output']>;
  RefreshToken?: Maybe<Scalars['JWT']['output']>;
  UserGUID?: Maybe<Scalars['UUID']['output']>;
};

export type SignInInput = {
  Email: Scalars['EmailAddress']['input'];
  Password: Scalars['String']['input'];
};

export type SignOut = {
  SignOut?: Maybe<Scalars['Boolean']['output']>;
};

export type SignOutInput = {
  RefreshToken: Scalars['JWT']['input'];
};

export type SocialSignIn = {
  AccessToken?: Maybe<Scalars['JWT']['output']>;
  CreatedAt?: Maybe<Scalars['DateTime']['output']>;
  Existing?: Maybe<Scalars['Boolean']['output']>;
  ExpiresIn?: Maybe<Scalars['Int']['output']>;
  LastSignedInAt?: Maybe<Scalars['DateTime']['output']>;
  RefetchIn?: Maybe<Scalars['Int']['output']>;
  RefreshToken?: Maybe<Scalars['JWT']['output']>;
  UserGUID?: Maybe<Scalars['UUID']['output']>;
};

export type SocialSignInInput = {
  AccessToken?: InputMaybe<Scalars['JWT']['input']>;
  Avatar?: InputMaybe<Scalars['URL']['input']>;
  Email?: InputMaybe<Scalars['EmailAddress']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Guide?: InputMaybe<Scalars['Boolean']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Provider: Provider;
  Token?: InputMaybe<Scalars['String']['input']>;
};

export enum SortFindManyActivitiesInput {
  ACTIVITYGUID_ASC = 'ACTIVITYGUID_ASC',
  ACTIVITYGUID_DESC = 'ACTIVITYGUID_DESC',
  /** Sort by the length of FavoredBy in ascending order */
  FAVORED_BY_ASC = 'FAVORED_BY_ASC',
  /** Sort by the length of FavoredBy in descending order */
  FAVORED_BY_DESC = 'FAVORED_BY_DESC',
  /** Sort by the length of Guides in ascending order */
  GUIDES_ASC = 'GUIDES_ASC',
  /** Sort by the length of Guides in descending order */
  GUIDES_DESC = 'GUIDES_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyAssetsInput {
  ASSETGUID_ASC = 'ASSETGUID_ASC',
  ASSETGUID_DESC = 'ASSETGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyBookingsInput {
  ACTIVITYID_ASC = 'ACTIVITYID_ASC',
  ACTIVITYID_DESC = 'ACTIVITYID_DESC',
  BOOKINGGUID_ASC = 'BOOKINGGUID_ASC',
  BOOKINGGUID_DESC = 'BOOKINGGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyCountriesInput {
  COUNTRYGUID_ASC = 'COUNTRYGUID_ASC',
  COUNTRYGUID_DESC = 'COUNTRYGUID_DESC',
  DATA_ASC = 'DATA_ASC',
  DATA_DESC = 'DATA_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyGeoLocationsInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyGuidesInput {
  GUIDEGUID_ASC = 'GUIDEGUID_ASC',
  GUIDEGUID_DESC = 'GUIDEGUID_DESC',
  USERID_ASC = 'USERID_ASC',
  USERID_DESC = 'USERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyPhonesInput {
  CALLINGCODE_ASC = 'CALLINGCODE_ASC',
  CALLINGCODE_DESC = 'CALLINGCODE_DESC',
  COUNTRYCODE_ASC = 'COUNTRYCODE_ASC',
  COUNTRYCODE_DESC = 'COUNTRYCODE_DESC',
  NUMBER_ASC = 'NUMBER_ASC',
  NUMBER_DESC = 'NUMBER_DESC',
  PHONEGUID_ASC = 'PHONEGUID_ASC',
  PHONEGUID_DESC = 'PHONEGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManySchedulesInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindManyUsersInput {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  BIRTHDAY_ASC = 'BIRTHDAY_ASC',
  BIRTHDAY_DESC = 'BIRTHDAY_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  PHONEID_ASC = 'PHONEID_ASC',
  PHONEID_DESC = 'PHONEID_DESC',
  SOCIALPROVIDERS_ASC = 'SOCIALPROVIDERS_ASC',
  SOCIALPROVIDERS_DESC = 'SOCIALPROVIDERS_DESC',
  USERGUID_ASC = 'USERGUID_ASC',
  USERGUID_DESC = 'USERGUID_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  USERNAME_DESC = 'USERNAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneActivitiesInput {
  ACTIVITYGUID_ASC = 'ACTIVITYGUID_ASC',
  ACTIVITYGUID_DESC = 'ACTIVITYGUID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneAssetsInput {
  ASSETGUID_ASC = 'ASSETGUID_ASC',
  ASSETGUID_DESC = 'ASSETGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneBookingsInput {
  ACTIVITYID_ASC = 'ACTIVITYID_ASC',
  ACTIVITYID_DESC = 'ACTIVITYID_DESC',
  BOOKINGGUID_ASC = 'BOOKINGGUID_ASC',
  BOOKINGGUID_DESC = 'BOOKINGGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneCountriesInput {
  COUNTRYGUID_ASC = 'COUNTRYGUID_ASC',
  COUNTRYGUID_DESC = 'COUNTRYGUID_DESC',
  DATA_ASC = 'DATA_ASC',
  DATA_DESC = 'DATA_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneGeoLocationsInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneGuidesInput {
  GUIDEGUID_ASC = 'GUIDEGUID_ASC',
  GUIDEGUID_DESC = 'GUIDEGUID_DESC',
  USERID_ASC = 'USERID_ASC',
  USERID_DESC = 'USERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOnePhonesInput {
  CALLINGCODE_ASC = 'CALLINGCODE_ASC',
  CALLINGCODE_DESC = 'CALLINGCODE_DESC',
  COUNTRYCODE_ASC = 'COUNTRYCODE_ASC',
  COUNTRYCODE_DESC = 'COUNTRYCODE_DESC',
  NUMBER_ASC = 'NUMBER_ASC',
  NUMBER_DESC = 'NUMBER_DESC',
  PHONEGUID_ASC = 'PHONEGUID_ASC',
  PHONEGUID_DESC = 'PHONEGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneSchedulesInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortFindOneUsersInput {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  BIRTHDAY_ASC = 'BIRTHDAY_ASC',
  BIRTHDAY_DESC = 'BIRTHDAY_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  PHONEID_ASC = 'PHONEID_ASC',
  PHONEID_DESC = 'PHONEID_DESC',
  SOCIALPROVIDERS_ASC = 'SOCIALPROVIDERS_ASC',
  SOCIALPROVIDERS_DESC = 'SOCIALPROVIDERS_DESC',
  USERGUID_ASC = 'USERGUID_ASC',
  USERGUID_DESC = 'USERGUID_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  USERNAME_DESC = 'USERNAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneActivitiesInput {
  ACTIVITYGUID_ASC = 'ACTIVITYGUID_ASC',
  ACTIVITYGUID_DESC = 'ACTIVITYGUID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneAssetsInput {
  ASSETGUID_ASC = 'ASSETGUID_ASC',
  ASSETGUID_DESC = 'ASSETGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneBookingsInput {
  ACTIVITYID_ASC = 'ACTIVITYID_ASC',
  ACTIVITYID_DESC = 'ACTIVITYID_DESC',
  BOOKINGGUID_ASC = 'BOOKINGGUID_ASC',
  BOOKINGGUID_DESC = 'BOOKINGGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneGeoLocationsInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneGuidesInput {
  GUIDEGUID_ASC = 'GUIDEGUID_ASC',
  GUIDEGUID_DESC = 'GUIDEGUID_DESC',
  USERID_ASC = 'USERID_ASC',
  USERID_DESC = 'USERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOnePhonesInput {
  CALLINGCODE_ASC = 'CALLINGCODE_ASC',
  CALLINGCODE_DESC = 'CALLINGCODE_DESC',
  COUNTRYCODE_ASC = 'COUNTRYCODE_ASC',
  COUNTRYCODE_DESC = 'COUNTRYCODE_DESC',
  NUMBER_ASC = 'NUMBER_ASC',
  NUMBER_DESC = 'NUMBER_DESC',
  PHONEGUID_ASC = 'PHONEGUID_ASC',
  PHONEGUID_DESC = 'PHONEGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneSchedulesInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortRemoveOneUsersInput {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  BIRTHDAY_ASC = 'BIRTHDAY_ASC',
  BIRTHDAY_DESC = 'BIRTHDAY_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  PHONEID_ASC = 'PHONEID_ASC',
  PHONEID_DESC = 'PHONEID_DESC',
  SOCIALPROVIDERS_ASC = 'SOCIALPROVIDERS_ASC',
  SOCIALPROVIDERS_DESC = 'SOCIALPROVIDERS_DESC',
  USERGUID_ASC = 'USERGUID_ASC',
  USERGUID_DESC = 'USERGUID_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  USERNAME_DESC = 'USERNAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyActivitiesInput {
  ACTIVITYGUID_ASC = 'ACTIVITYGUID_ASC',
  ACTIVITYGUID_DESC = 'ACTIVITYGUID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyAssetsInput {
  ASSETGUID_ASC = 'ASSETGUID_ASC',
  ASSETGUID_DESC = 'ASSETGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyBookingsInput {
  ACTIVITYID_ASC = 'ACTIVITYID_ASC',
  ACTIVITYID_DESC = 'ACTIVITYID_DESC',
  BOOKINGGUID_ASC = 'BOOKINGGUID_ASC',
  BOOKINGGUID_DESC = 'BOOKINGGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyGeoLocationsInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyGuidesInput {
  GUIDEGUID_ASC = 'GUIDEGUID_ASC',
  GUIDEGUID_DESC = 'GUIDEGUID_DESC',
  USERID_ASC = 'USERID_ASC',
  USERID_DESC = 'USERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyPhonesInput {
  CALLINGCODE_ASC = 'CALLINGCODE_ASC',
  CALLINGCODE_DESC = 'CALLINGCODE_DESC',
  COUNTRYCODE_ASC = 'COUNTRYCODE_ASC',
  COUNTRYCODE_DESC = 'COUNTRYCODE_DESC',
  NUMBER_ASC = 'NUMBER_ASC',
  NUMBER_DESC = 'NUMBER_DESC',
  PHONEGUID_ASC = 'PHONEGUID_ASC',
  PHONEGUID_DESC = 'PHONEGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManySchedulesInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateManyUsersInput {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  BIRTHDAY_ASC = 'BIRTHDAY_ASC',
  BIRTHDAY_DESC = 'BIRTHDAY_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  PHONEID_ASC = 'PHONEID_ASC',
  PHONEID_DESC = 'PHONEID_DESC',
  SOCIALPROVIDERS_ASC = 'SOCIALPROVIDERS_ASC',
  SOCIALPROVIDERS_DESC = 'SOCIALPROVIDERS_DESC',
  USERGUID_ASC = 'USERGUID_ASC',
  USERGUID_DESC = 'USERGUID_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  USERNAME_DESC = 'USERNAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneActivitiesInput {
  ACTIVITYGUID_ASC = 'ACTIVITYGUID_ASC',
  ACTIVITYGUID_DESC = 'ACTIVITYGUID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneAssetsInput {
  ASSETGUID_ASC = 'ASSETGUID_ASC',
  ASSETGUID_DESC = 'ASSETGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneBookingsInput {
  ACTIVITYID_ASC = 'ACTIVITYID_ASC',
  ACTIVITYID_DESC = 'ACTIVITYID_DESC',
  BOOKINGGUID_ASC = 'BOOKINGGUID_ASC',
  BOOKINGGUID_DESC = 'BOOKINGGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneGeoLocationsInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneGuidesInput {
  GUIDEGUID_ASC = 'GUIDEGUID_ASC',
  GUIDEGUID_DESC = 'GUIDEGUID_DESC',
  USERID_ASC = 'USERID_ASC',
  USERID_DESC = 'USERID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOnePhonesInput {
  CALLINGCODE_ASC = 'CALLINGCODE_ASC',
  CALLINGCODE_DESC = 'CALLINGCODE_DESC',
  COUNTRYCODE_ASC = 'COUNTRYCODE_ASC',
  COUNTRYCODE_DESC = 'COUNTRYCODE_DESC',
  NUMBER_ASC = 'NUMBER_ASC',
  NUMBER_DESC = 'NUMBER_DESC',
  PHONEGUID_ASC = 'PHONEGUID_ASC',
  PHONEGUID_DESC = 'PHONEGUID_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneSchedulesInput {
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export enum SortUpdateOneUsersInput {
  ACTIVE_ASC = 'ACTIVE_ASC',
  ACTIVE_DESC = 'ACTIVE_DESC',
  BIRTHDAY_ASC = 'BIRTHDAY_ASC',
  BIRTHDAY_DESC = 'BIRTHDAY_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRSTNAME_ASC = 'FIRSTNAME_ASC',
  FIRSTNAME_DESC = 'FIRSTNAME_DESC',
  GENDER_ASC = 'GENDER_ASC',
  GENDER_DESC = 'GENDER_DESC',
  LASTNAME_ASC = 'LASTNAME_ASC',
  LASTNAME_DESC = 'LASTNAME_DESC',
  PHONEID_ASC = 'PHONEID_ASC',
  PHONEID_DESC = 'PHONEID_DESC',
  SOCIALPROVIDERS_ASC = 'SOCIALPROVIDERS_ASC',
  SOCIALPROVIDERS_DESC = 'SOCIALPROVIDERS_DESC',
  USERGUID_ASC = 'USERGUID_ASC',
  USERGUID_DESC = 'USERGUID_DESC',
  USERNAME_ASC = 'USERNAME_ASC',
  USERNAME_DESC = 'USERNAME_DESC',
  _ID_ASC = '_ID_ASC',
  _ID_DESC = '_ID_DESC',
}

export type Subscription = {
  Suggestions?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionSuggestionsArgs = {
  Suggestions: SuggestionsInput;
};

export type Suggestion = {
  Activities: Array<Scalars['String']['output']>;
  Address: Scalars['String']['output'];
  City: Scalars['String']['output'];
  Commute: Scalars['String']['output'];
  Country: Scalars['String']['output'];
  Introduction: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type SuggestionsInput = {
  Count?: InputMaybe<Scalars['Int']['input']>;
  Origin?: InputMaybe<Scalars['String']['input']>;
  Place: Scalars['String']['input'];
};

export type TopLogprob = {
  bytes?: Maybe<Array<Scalars['Int']['output']>>;
  logprob: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type UpdateManyActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateManyActivitiesInput = {
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<UpdateManyActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyActivitiesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManyAssetsInput = {
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyAssetsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManyBookingsInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateManyBookingsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManyGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<UpdateManyGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<UpdateManyGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<UpdateManyGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<UpdateManyGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<UpdateManyGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<UpdateManyGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<UpdateManyGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<UpdateManyGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<UpdateManyGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<UpdateManyGeoLocationsAnnotationsWhat3wordsInput>;
};

export type UpdateManyGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<UpdateManyGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<UpdateManyGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<UpdateManyGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<UpdateManyGeoLocationsAnnotationsNutsnuts3Input>;
};

export type UpdateManyGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<UpdateManyGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<UpdateManyGeoLocationsAnnotationsSunSetInput>;
};

export type UpdateManyGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<UpdateManyGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type UpdateManyGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsBoundsInput = {
  northeast?: InputMaybe<UpdateManyGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<UpdateManyGeoLocationsBoundsSouthwestInput>;
};

export type UpdateManyGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<UpdateManyGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateManyGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsComponentsInput = {
  ISO?: InputMaybe<UpdateManyGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateManyGeoLocationsInput = {
  Annotations?: InputMaybe<UpdateManyGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<UpdateManyGeoLocationsBoundsInput>;
  Components?: InputMaybe<UpdateManyGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<UpdateManyGeoLocationsGeometryInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateManyGeoLocationsPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManyGuidesInput = {
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateManyGuidesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManyPhonesInput = {
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyPhonesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManySchedulesInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<UpdateManySchedulesRangesInput>>>>
  >;
};

export type UpdateManySchedulesPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateManySchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateManyUsersInput = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyUsersPayload = {
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']['output']>;
};

export type UpdateOneActivitiesDescriptionInput = {
  Conclusion?: InputMaybe<Scalars['String']['input']>;
  Introduction?: InputMaybe<Scalars['String']['input']>;
  Main?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateOneActivitiesInput = {
  ActivityGUID?: InputMaybe<Scalars['String']['input']>;
  AssetID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Description?: InputMaybe<UpdateOneActivitiesDescriptionInput>;
  Details?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneActivitiesPayload = {
  /** Updated document */
  record?: Maybe<Activities>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneAssetsInput = {
  AssetGUID?: InputMaybe<Scalars['String']['input']>;
  Path?: InputMaybe<Scalars['String']['input']>;
  Size?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneAssetsPayload = {
  /** Updated document */
  record?: Maybe<Assets>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneBookingsInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  BookingGUID?: InputMaybe<Scalars['String']['input']>;
  CheckInDate?: InputMaybe<Scalars['Date']['input']>;
  CheckOutDate?: InputMaybe<Scalars['Date']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateOneBookingsPayload = {
  /** Updated document */
  record?: Maybe<Bookings>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneGeoLocationsAnnotationsCurrencyInput = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  alternate_symbols?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  decimal_mark?: InputMaybe<Scalars['String']['input']>;
  html_entity?: InputMaybe<Scalars['String']['input']>;
  iso_code?: InputMaybe<Scalars['String']['input']>;
  iso_numeric?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  smallest_denomination?: InputMaybe<Scalars['Float']['input']>;
  subunit?: InputMaybe<Scalars['String']['input']>;
  subunit_to_unit?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbol_first?: InputMaybe<Scalars['Float']['input']>;
  thousands_separator?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsDmsInput = {
  lat?: InputMaybe<Scalars['String']['input']>;
  lng?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsInput = {
  DMS?: InputMaybe<UpdateOneGeoLocationsAnnotationsDmsInput>;
  MGRS?: InputMaybe<Scalars['String']['input']>;
  Maidenhead?: InputMaybe<Scalars['String']['input']>;
  Mercator?: InputMaybe<UpdateOneGeoLocationsAnnotationsMercatorInput>;
  NUTS?: InputMaybe<UpdateOneGeoLocationsAnnotationsNutsInput>;
  OSM?: InputMaybe<UpdateOneGeoLocationsAnnotationsOsmInput>;
  UN_M49?: InputMaybe<UpdateOneGeoLocationsAnnotationsUn_M49Input>;
  callingcode?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<UpdateOneGeoLocationsAnnotationsCurrencyInput>;
  flag?: InputMaybe<Scalars['String']['input']>;
  geohash?: InputMaybe<Scalars['String']['input']>;
  qibla?: InputMaybe<Scalars['Float']['input']>;
  roadinfo?: InputMaybe<UpdateOneGeoLocationsAnnotationsRoadinfoInput>;
  sun?: InputMaybe<UpdateOneGeoLocationsAnnotationsSunInput>;
  timezone?: InputMaybe<UpdateOneGeoLocationsAnnotationsTimezoneInput>;
  what3words?: InputMaybe<UpdateOneGeoLocationsAnnotationsWhat3wordsInput>;
};

export type UpdateOneGeoLocationsAnnotationsMercatorInput = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsNutsInput = {
  NUTS0?: InputMaybe<UpdateOneGeoLocationsAnnotationsNutsnuts0Input>;
  NUTS1?: InputMaybe<UpdateOneGeoLocationsAnnotationsNutsnuts1Input>;
  NUTS2?: InputMaybe<UpdateOneGeoLocationsAnnotationsNutsnuts2Input>;
  NUTS3?: InputMaybe<UpdateOneGeoLocationsAnnotationsNutsnuts3Input>;
};

export type UpdateOneGeoLocationsAnnotationsNutsnuts0Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsNutsnuts1Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsNutsnuts2Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsNutsnuts3Input = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsOsmInput = {
  edit_url?: InputMaybe<Scalars['String']['input']>;
  note_url?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsRoadinfoInput = {
  drive_on?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  speed_in?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsSunInput = {
  rise?: InputMaybe<UpdateOneGeoLocationsAnnotationsSunRiseInput>;
  set?: InputMaybe<UpdateOneGeoLocationsAnnotationsSunSetInput>;
};

export type UpdateOneGeoLocationsAnnotationsSunRiseInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsSunSetInput = {
  apparent?: InputMaybe<Scalars['Float']['input']>;
  astronomical?: InputMaybe<Scalars['Float']['input']>;
  civil?: InputMaybe<Scalars['Float']['input']>;
  nautical?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsTimezoneInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  now_in_dst?: InputMaybe<Scalars['Float']['input']>;
  offset_sec?: InputMaybe<Scalars['Float']['input']>;
  offset_string?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsUn_M49Input = {
  regions?: InputMaybe<UpdateOneGeoLocationsAnnotationsUn_M49RegionsInput>;
};

export type UpdateOneGeoLocationsAnnotationsUn_M49RegionsInput = {
  DE?: InputMaybe<Scalars['String']['input']>;
  EUROPE?: InputMaybe<Scalars['String']['input']>;
  WESTERN_EUROPE?: InputMaybe<Scalars['String']['input']>;
  WORLD?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsAnnotationsWhat3wordsInput = {
  words?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsBoundsInput = {
  northeast?: InputMaybe<UpdateOneGeoLocationsBoundsNortheastInput>;
  southwest?: InputMaybe<UpdateOneGeoLocationsBoundsSouthwestInput>;
};

export type UpdateOneGeoLocationsBoundsNortheastInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsBoundsSouthwestInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsComponentsIsoInput = {
  v1?: InputMaybe<UpdateOneGeoLocationsComponentsIsov1Input>;
  v2?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateOneGeoLocationsComponentsIsov1Input = {
  alpha2?: InputMaybe<Scalars['String']['input']>;
  alpha3?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsComponentsInput = {
  ISO?: InputMaybe<UpdateOneGeoLocationsComponentsIsoInput>;
  _category?: InputMaybe<Scalars['String']['input']>;
  _normalized_city?: InputMaybe<Scalars['String']['input']>;
  _type?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_district?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  political_union?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  road?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneGeoLocationsGeometryInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneGeoLocationsInput = {
  Annotations?: InputMaybe<UpdateOneGeoLocationsAnnotationsInput>;
  Bounds?: InputMaybe<UpdateOneGeoLocationsBoundsInput>;
  Components?: InputMaybe<UpdateOneGeoLocationsComponentsInput>;
  Confidence?: InputMaybe<Scalars['Float']['input']>;
  Distance?: InputMaybe<Scalars['Float']['input']>;
  Formatted?: InputMaybe<Scalars['String']['input']>;
  GeoLocationGUID?: InputMaybe<Scalars['String']['input']>;
  Geometry?: InputMaybe<UpdateOneGeoLocationsGeometryInput>;
  q?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateOneGeoLocationsPayload = {
  /** Updated document */
  record?: Maybe<GeoLocations>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneGuidesInput = {
  Biography?: InputMaybe<Scalars['String']['input']>;
  FavoredBy?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  GuideGUID?: InputMaybe<Scalars['String']['input']>;
  ScheduleID?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  UserID?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateOneGuidesPayload = {
  /** Updated document */
  record?: Maybe<Guides>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOnePhonesInput = {
  CallingCode?: InputMaybe<Scalars['String']['input']>;
  CountryCode?: InputMaybe<EnumPhonesCountryCode>;
  Number?: InputMaybe<Scalars['String']['input']>;
  PhoneGUID?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOnePhonesPayload = {
  /** Updated document */
  record?: Maybe<Phones>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneSchedulesInput = {
  ActivityID?: InputMaybe<Scalars['MongoID']['input']>;
  GuideID?: InputMaybe<Scalars['MongoID']['input']>;
  Intervals?: InputMaybe<Scalars['Float']['input']>;
  Ranges?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<UpdateOneSchedulesRangesInput>>>>
  >;
};

export type UpdateOneSchedulesPayload = {
  /** Updated document */
  record?: Maybe<Schedules>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateOneSchedulesRangesInput = {
  From?: InputMaybe<Scalars['String']['input']>;
  To?: InputMaybe<Scalars['String']['input']>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UpdateOneUsersInput = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AssetID?: InputMaybe<Scalars['MongoID']['input']>;
  Birthday?: InputMaybe<Scalars['Date']['input']>;
  CountryID?: InputMaybe<Scalars['MongoID']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Gender?: InputMaybe<EnumUsersGender>;
  GeoLocationID?: InputMaybe<Scalars['MongoID']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PhoneID?: InputMaybe<Scalars['MongoID']['input']>;
  SocialProviders?: InputMaybe<Array<InputMaybe<EnumUsersSocialProviders>>>;
  UserGUID?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOneUsersPayload = {
  /** Updated document */
  record?: Maybe<Users>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UploadAsset = {
  AssetGUID: Scalars['UUID']['output'];
  Path: Scalars['String']['output'];
  Size: Scalars['Int']['output'];
  _id: Scalars['ObjectID']['output'];
};

export type UploadAssetInput = {
  DataSource: AssetDataSource;
  File: Scalars['Upload']['input'];
};

export type UserLocationLookup = {
  carrier?: Maybe<UserLocationLookupCarrier>;
  company?: Maybe<UserLocationLookupCompany>;
  connection?: Maybe<UserLocationLookupConnection>;
  currency?: Maybe<UserLocationLookupCurrency>;
  hostname?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  location?: Maybe<UserLocationLookupLocation>;
  security?: Maybe<UserLocationLookupSecurity>;
  time_zone?: Maybe<UserLocationLookupTimeZone>;
  type?: Maybe<UserLocationLookupType>;
};

export type UserLocationLookupCarrier = {
  mcc?: Maybe<Scalars['String']['output']>;
  mnc?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupCompany = {
  domain?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<UserLocationLookupCompanyType>;
};

export enum UserLocationLookupCompanyType {
  business = 'business',
  education = 'education',
  government = 'government',
  hosting = 'hosting',
  isp = 'isp',
}

export type UserLocationLookupConnection = {
  asn?: Maybe<Scalars['Float']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  type?: Maybe<UserLocationLookupConnectionType>;
};

export enum UserLocationLookupConnectionType {
  business = 'business',
  education = 'education',
  government = 'government',
  hosting = 'hosting',
  inactive = 'inactive',
  isp = 'isp',
}

export type UserLocationLookupCurrency = {
  code?: Maybe<Scalars['String']['output']>;
  format?: Maybe<UserLocationLookupCurrencyFormat>;
  name?: Maybe<Scalars['String']['output']>;
  name_native?: Maybe<Scalars['String']['output']>;
  plural?: Maybe<Scalars['String']['output']>;
  plural_native?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  symbol_native?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupCurrencyFormat = {
  negative?: Maybe<UserLocationLookupCurrencyFormatPrefixSuffix>;
  positive?: Maybe<UserLocationLookupCurrencyFormatPrefixSuffix>;
};

export type UserLocationLookupCurrencyFormatPrefixSuffix = {
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupInput = {
  ip: Scalars['String']['input'];
};

export type UserLocationLookupLocation = {
  city?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<UserLocationLookupLocationContinent>;
  country?: Maybe<UserLocationLookupLocationCountry>;
  in_eu?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<UserLocationLookupLocationLanguage>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  region?: Maybe<UserLocationLookupLocationRegion>;
};

export type UserLocationLookupLocationContinent = {
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupLocationCountry = {
  area?: Maybe<Scalars['Int']['output']>;
  borders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  calling_code?: Maybe<Scalars['String']['output']>;
  capital?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  flag?: Maybe<UserLocationLookupLocationCountryFlag>;
  languages?: Maybe<Array<Maybe<UserLocationLookupLocationLanguage>>>;
  name?: Maybe<Scalars['String']['output']>;
  population?: Maybe<Scalars['Int']['output']>;
  population_density?: Maybe<Scalars['Float']['output']>;
  tld?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupLocationCountryFlag = {
  emoji?: Maybe<Scalars['String']['output']>;
  emoji_unicode?: Maybe<Scalars['String']['output']>;
  emojitwo?: Maybe<Scalars['String']['output']>;
  noto?: Maybe<Scalars['String']['output']>;
  twemoji?: Maybe<Scalars['String']['output']>;
  wikimedia?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupLocationLanguage = {
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  native?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupLocationRegion = {
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserLocationLookupSecurity = {
  is_abuser?: Maybe<Scalars['Boolean']['output']>;
  is_anonymous?: Maybe<Scalars['Boolean']['output']>;
  is_attacker?: Maybe<Scalars['Boolean']['output']>;
  is_bogon?: Maybe<Scalars['Boolean']['output']>;
  is_cloud_provider?: Maybe<Scalars['Boolean']['output']>;
  is_proxy?: Maybe<Scalars['Boolean']['output']>;
  is_relay?: Maybe<Scalars['Boolean']['output']>;
  is_threat?: Maybe<Scalars['Boolean']['output']>;
  is_tor?: Maybe<Scalars['Boolean']['output']>;
  is_tor_exit?: Maybe<Scalars['Boolean']['output']>;
  is_vpn?: Maybe<Scalars['Boolean']['output']>;
};

export type UserLocationLookupTimeZone = {
  abbreviation?: Maybe<Scalars['String']['output']>;
  current_time?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  in_daylight_saving?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum UserLocationLookupType {
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
}

export type Users = {
  Active: Scalars['Boolean']['output'];
  Asset?: Maybe<Assets>;
  AssetID?: Maybe<Scalars['MongoID']['output']>;
  Country?: Maybe<Countries>;
  CountryID?: Maybe<Scalars['MongoID']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  UserGUID?: Maybe<Scalars['String']['output']>;
  UserName: Scalars['String']['output'];
  _id: Scalars['MongoID']['output'];
};

/** List of items with pagination. */
export type UsersPagination = {
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Users>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountNumber: ResolverTypeWrapper<Scalars['AccountNumber']['output']>;
  Activate: ResolverTypeWrapper<Activate>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  ActivateInput: ActivateInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Activities: ResolverTypeWrapper<Activities>;
  ActivitiesDescription: ResolverTypeWrapper<ActivitiesDescription>;
  ActivitiesDescriptionInput: ActivitiesDescriptionInput;
  ActivitiesPagination: ResolverTypeWrapper<ActivitiesPagination>;
  AssetDataSource: AssetDataSource;
  AssetDataSourceValue: ResolverTypeWrapper<
    Scalars['AssetDataSourceValue']['output']
  >;
  Assets: ResolverTypeWrapper<Assets>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  AssetsDataSourceName: AssetsDataSourceName;
  AssetsPagination: ResolverTypeWrapper<AssetsPagination>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  Bookings: ResolverTypeWrapper<Bookings>;
  BookingsPagination: ResolverTypeWrapper<BookingsPagination>;
  Byte: ResolverTypeWrapper<Scalars['Byte']['output']>;
  ChatCompletionTokenLogprob: ResolverTypeWrapper<ChatCompletionTokenLogprob>;
  Countries: ResolverTypeWrapper<Countries>;
  CountriesData: ResolverTypeWrapper<CountriesData>;
  CountryCode: ResolverTypeWrapper<Scalars['CountryCode']['output']>;
  CreateManyActivitiesInput: CreateManyActivitiesInput;
  CreateManyActivitiesPayload: ResolverTypeWrapper<CreateManyActivitiesPayload>;
  CreateManyAssetsInput: CreateManyAssetsInput;
  CreateManyAssetsPayload: ResolverTypeWrapper<CreateManyAssetsPayload>;
  CreateManyBookingsInput: CreateManyBookingsInput;
  CreateManyBookingsPayload: ResolverTypeWrapper<CreateManyBookingsPayload>;
  CreateManyGeoLocationsInput: CreateManyGeoLocationsInput;
  CreateManyGeoLocationsPayload: ResolverTypeWrapper<CreateManyGeoLocationsPayload>;
  CreateManyGuidesInput: CreateManyGuidesInput;
  CreateManyGuidesPayload: ResolverTypeWrapper<CreateManyGuidesPayload>;
  CreateManyPhonesInput: CreateManyPhonesInput;
  CreateManyPhonesPayload: ResolverTypeWrapper<CreateManyPhonesPayload>;
  CreateManySchedulesInput: CreateManySchedulesInput;
  CreateManySchedulesPayload: ResolverTypeWrapper<CreateManySchedulesPayload>;
  CreateManyUsersInput: CreateManyUsersInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CreateManyUsersPayload: ResolverTypeWrapper<CreateManyUsersPayload>;
  CreateOneActivitiesInput: CreateOneActivitiesInput;
  CreateOneActivitiesPayload: ResolverTypeWrapper<CreateOneActivitiesPayload>;
  CreateOneAssetsInput: CreateOneAssetsInput;
  CreateOneAssetsPayload: ResolverTypeWrapper<CreateOneAssetsPayload>;
  CreateOneBookingsInput: CreateOneBookingsInput;
  CreateOneBookingsPayload: ResolverTypeWrapper<CreateOneBookingsPayload>;
  CreateOneGeoLocationsInput: CreateOneGeoLocationsInput;
  CreateOneGeoLocationsPayload: ResolverTypeWrapper<CreateOneGeoLocationsPayload>;
  CreateOneGuidesInput: CreateOneGuidesInput;
  CreateOneGuidesPayload: ResolverTypeWrapper<CreateOneGuidesPayload>;
  CreateOnePhonesInput: CreateOnePhonesInput;
  CreateOnePhonesPayload: ResolverTypeWrapper<CreateOnePhonesPayload>;
  CreateOneSchedulesInput: CreateOneSchedulesInput;
  CreateOneSchedulesPayload: ResolverTypeWrapper<CreateOneSchedulesPayload>;
  CreateOneUsersInput: CreateOneUsersInput;
  CreateOneUsersPayload: ResolverTypeWrapper<CreateOneUsersPayload>;
  Cuid: ResolverTypeWrapper<Scalars['Cuid']['output']>;
  Currency: ResolverTypeWrapper<Scalars['Currency']['output']>;
  DID: ResolverTypeWrapper<Scalars['DID']['output']>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeISO: ResolverTypeWrapper<Scalars['DateTimeISO']['output']>;
  DeweyDecimal: ResolverTypeWrapper<Scalars['DeweyDecimal']['output']>;
  Duration: ResolverTypeWrapper<Scalars['Duration']['output']>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']['output']>;
  EnumPhonesCountryCode: EnumPhonesCountryCode;
  EnumUsersGender: EnumUsersGender;
  EnumUsersSocialProviders: EnumUsersSocialProviders;
  FilterFindManyActivitiesActivityGUIDOperatorsInput: FilterFindManyActivitiesActivityGuidOperatorsInput;
  FilterFindManyActivitiesDescriptionInput: FilterFindManyActivitiesDescriptionInput;
  FilterFindManyActivitiesInput: FilterFindManyActivitiesInput;
  FilterFindManyActivitiesOperatorsInput: FilterFindManyActivitiesOperatorsInput;
  FilterFindManyActivitiesTitleOperatorsInput: FilterFindManyActivitiesTitleOperatorsInput;
  FilterFindManyActivities_idOperatorsInput: FilterFindManyActivities_IdOperatorsInput;
  FilterFindManyAssetsAssetGUIDOperatorsInput: FilterFindManyAssetsAssetGuidOperatorsInput;
  FilterFindManyAssetsInput: FilterFindManyAssetsInput;
  FilterFindManyAssetsOperatorsInput: FilterFindManyAssetsOperatorsInput;
  FilterFindManyAssets_idOperatorsInput: FilterFindManyAssets_IdOperatorsInput;
  FilterFindManyBookingsActivityIDOperatorsInput: FilterFindManyBookingsActivityIdOperatorsInput;
  FilterFindManyBookingsBookingGUIDOperatorsInput: FilterFindManyBookingsBookingGuidOperatorsInput;
  FilterFindManyBookingsInput: FilterFindManyBookingsInput;
  FilterFindManyBookingsOperatorsInput: FilterFindManyBookingsOperatorsInput;
  FilterFindManyBookings_idOperatorsInput: FilterFindManyBookings_IdOperatorsInput;
  FilterFindManyCountriesCountryGUIDOperatorsInput: FilterFindManyCountriesCountryGuidOperatorsInput;
  FilterFindManyCountriesDataInput: FilterFindManyCountriesDataInput;
  FilterFindManyCountriesInput: FilterFindManyCountriesInput;
  FilterFindManyCountriesOperatorsInput: FilterFindManyCountriesOperatorsInput;
  FilterFindManyCountries_idOperatorsInput: FilterFindManyCountries_IdOperatorsInput;
  FilterFindManyGeoLocationsAnnotationsCurrencyInput: FilterFindManyGeoLocationsAnnotationsCurrencyInput;
  FilterFindManyGeoLocationsAnnotationsDMSInput: FilterFindManyGeoLocationsAnnotationsDmsInput;
  FilterFindManyGeoLocationsAnnotationsInput: FilterFindManyGeoLocationsAnnotationsInput;
  FilterFindManyGeoLocationsAnnotationsMercatorInput: FilterFindManyGeoLocationsAnnotationsMercatorInput;
  FilterFindManyGeoLocationsAnnotationsNUTSInput: FilterFindManyGeoLocationsAnnotationsNutsInput;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterFindManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterFindManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterFindManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterFindManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterFindManyGeoLocationsAnnotationsOSMInput: FilterFindManyGeoLocationsAnnotationsOsmInput;
  FilterFindManyGeoLocationsAnnotationsRoadinfoInput: FilterFindManyGeoLocationsAnnotationsRoadinfoInput;
  FilterFindManyGeoLocationsAnnotationsSunInput: FilterFindManyGeoLocationsAnnotationsSunInput;
  FilterFindManyGeoLocationsAnnotationsSunRiseInput: FilterFindManyGeoLocationsAnnotationsSunRiseInput;
  FilterFindManyGeoLocationsAnnotationsSunSetInput: FilterFindManyGeoLocationsAnnotationsSunSetInput;
  FilterFindManyGeoLocationsAnnotationsTimezoneInput: FilterFindManyGeoLocationsAnnotationsTimezoneInput;
  FilterFindManyGeoLocationsAnnotationsUN_M49Input: FilterFindManyGeoLocationsAnnotationsUn_M49Input;
  FilterFindManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterFindManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterFindManyGeoLocationsAnnotationsWhat3wordsInput: FilterFindManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterFindManyGeoLocationsBoundsInput: FilterFindManyGeoLocationsBoundsInput;
  FilterFindManyGeoLocationsBoundsNortheastInput: FilterFindManyGeoLocationsBoundsNortheastInput;
  FilterFindManyGeoLocationsBoundsSouthwestInput: FilterFindManyGeoLocationsBoundsSouthwestInput;
  FilterFindManyGeoLocationsComponentsISOInput: FilterFindManyGeoLocationsComponentsIsoInput;
  FilterFindManyGeoLocationsComponentsISOV1Input: FilterFindManyGeoLocationsComponentsIsov1Input;
  FilterFindManyGeoLocationsComponentsInput: FilterFindManyGeoLocationsComponentsInput;
  FilterFindManyGeoLocationsGeometryInput: FilterFindManyGeoLocationsGeometryInput;
  FilterFindManyGeoLocationsInput: FilterFindManyGeoLocationsInput;
  FilterFindManyGeoLocationsOperatorsInput: FilterFindManyGeoLocationsOperatorsInput;
  FilterFindManyGeoLocations_idOperatorsInput: FilterFindManyGeoLocations_IdOperatorsInput;
  FilterFindManyGuidesGuideGUIDOperatorsInput: FilterFindManyGuidesGuideGuidOperatorsInput;
  FilterFindManyGuidesInput: FilterFindManyGuidesInput;
  FilterFindManyGuidesOperatorsInput: FilterFindManyGuidesOperatorsInput;
  FilterFindManyGuidesUserIDOperatorsInput: FilterFindManyGuidesUserIdOperatorsInput;
  FilterFindManyGuides_idOperatorsInput: FilterFindManyGuides_IdOperatorsInput;
  FilterFindManyPhonesCallingCodeOperatorsInput: FilterFindManyPhonesCallingCodeOperatorsInput;
  FilterFindManyPhonesCountryCodeOperatorsInput: FilterFindManyPhonesCountryCodeOperatorsInput;
  FilterFindManyPhonesInput: FilterFindManyPhonesInput;
  FilterFindManyPhonesNumberOperatorsInput: FilterFindManyPhonesNumberOperatorsInput;
  FilterFindManyPhonesOperatorsInput: FilterFindManyPhonesOperatorsInput;
  FilterFindManyPhonesPhoneGUIDOperatorsInput: FilterFindManyPhonesPhoneGuidOperatorsInput;
  FilterFindManyPhones_idOperatorsInput: FilterFindManyPhones_IdOperatorsInput;
  FilterFindManySchedulesInput: FilterFindManySchedulesInput;
  FilterFindManySchedulesOperatorsInput: FilterFindManySchedulesOperatorsInput;
  FilterFindManySchedulesRangesInput: FilterFindManySchedulesRangesInput;
  FilterFindManySchedules_idOperatorsInput: FilterFindManySchedules_IdOperatorsInput;
  FilterFindManyUsersActiveOperatorsInput: FilterFindManyUsersActiveOperatorsInput;
  FilterFindManyUsersFirstNameOperatorsInput: FilterFindManyUsersFirstNameOperatorsInput;
  FilterFindManyUsersInput: FilterFindManyUsersInput;
  FilterFindManyUsersLastNameOperatorsInput: FilterFindManyUsersLastNameOperatorsInput;
  FilterFindManyUsersOperatorsInput: FilterFindManyUsersOperatorsInput;
  FilterFindManyUsersUserGUIDOperatorsInput: FilterFindManyUsersUserGuidOperatorsInput;
  FilterFindManyUsersUserNameOperatorsInput: FilterFindManyUsersUserNameOperatorsInput;
  FilterFindManyUsers_idOperatorsInput: FilterFindManyUsers_IdOperatorsInput;
  FilterFindOneActivitiesActivityGUIDOperatorsInput: FilterFindOneActivitiesActivityGuidOperatorsInput;
  FilterFindOneActivitiesDescriptionInput: FilterFindOneActivitiesDescriptionInput;
  FilterFindOneActivitiesInput: FilterFindOneActivitiesInput;
  FilterFindOneActivitiesOperatorsInput: FilterFindOneActivitiesOperatorsInput;
  FilterFindOneActivitiesTitleOperatorsInput: FilterFindOneActivitiesTitleOperatorsInput;
  FilterFindOneActivities_idOperatorsInput: FilterFindOneActivities_IdOperatorsInput;
  FilterFindOneAssetsAssetGUIDOperatorsInput: FilterFindOneAssetsAssetGuidOperatorsInput;
  FilterFindOneAssetsInput: FilterFindOneAssetsInput;
  FilterFindOneAssetsOperatorsInput: FilterFindOneAssetsOperatorsInput;
  FilterFindOneAssets_idOperatorsInput: FilterFindOneAssets_IdOperatorsInput;
  FilterFindOneBookingsActivityIDOperatorsInput: FilterFindOneBookingsActivityIdOperatorsInput;
  FilterFindOneBookingsBookingGUIDOperatorsInput: FilterFindOneBookingsBookingGuidOperatorsInput;
  FilterFindOneBookingsInput: FilterFindOneBookingsInput;
  FilterFindOneBookingsOperatorsInput: FilterFindOneBookingsOperatorsInput;
  FilterFindOneBookings_idOperatorsInput: FilterFindOneBookings_IdOperatorsInput;
  FilterFindOneCountriesCountryGUIDOperatorsInput: FilterFindOneCountriesCountryGuidOperatorsInput;
  FilterFindOneCountriesDataInput: FilterFindOneCountriesDataInput;
  FilterFindOneCountriesInput: FilterFindOneCountriesInput;
  FilterFindOneCountriesOperatorsInput: FilterFindOneCountriesOperatorsInput;
  FilterFindOneCountries_idOperatorsInput: FilterFindOneCountries_IdOperatorsInput;
  FilterFindOneGeoLocationsAnnotationsCurrencyInput: FilterFindOneGeoLocationsAnnotationsCurrencyInput;
  FilterFindOneGeoLocationsAnnotationsDMSInput: FilterFindOneGeoLocationsAnnotationsDmsInput;
  FilterFindOneGeoLocationsAnnotationsInput: FilterFindOneGeoLocationsAnnotationsInput;
  FilterFindOneGeoLocationsAnnotationsMercatorInput: FilterFindOneGeoLocationsAnnotationsMercatorInput;
  FilterFindOneGeoLocationsAnnotationsNUTSInput: FilterFindOneGeoLocationsAnnotationsNutsInput;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterFindOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterFindOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterFindOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterFindOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterFindOneGeoLocationsAnnotationsOSMInput: FilterFindOneGeoLocationsAnnotationsOsmInput;
  FilterFindOneGeoLocationsAnnotationsRoadinfoInput: FilterFindOneGeoLocationsAnnotationsRoadinfoInput;
  FilterFindOneGeoLocationsAnnotationsSunInput: FilterFindOneGeoLocationsAnnotationsSunInput;
  FilterFindOneGeoLocationsAnnotationsSunRiseInput: FilterFindOneGeoLocationsAnnotationsSunRiseInput;
  FilterFindOneGeoLocationsAnnotationsSunSetInput: FilterFindOneGeoLocationsAnnotationsSunSetInput;
  FilterFindOneGeoLocationsAnnotationsTimezoneInput: FilterFindOneGeoLocationsAnnotationsTimezoneInput;
  FilterFindOneGeoLocationsAnnotationsUN_M49Input: FilterFindOneGeoLocationsAnnotationsUn_M49Input;
  FilterFindOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterFindOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterFindOneGeoLocationsAnnotationsWhat3wordsInput: FilterFindOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterFindOneGeoLocationsBoundsInput: FilterFindOneGeoLocationsBoundsInput;
  FilterFindOneGeoLocationsBoundsNortheastInput: FilterFindOneGeoLocationsBoundsNortheastInput;
  FilterFindOneGeoLocationsBoundsSouthwestInput: FilterFindOneGeoLocationsBoundsSouthwestInput;
  FilterFindOneGeoLocationsComponentsISOInput: FilterFindOneGeoLocationsComponentsIsoInput;
  FilterFindOneGeoLocationsComponentsISOV1Input: FilterFindOneGeoLocationsComponentsIsov1Input;
  FilterFindOneGeoLocationsComponentsInput: FilterFindOneGeoLocationsComponentsInput;
  FilterFindOneGeoLocationsGeometryInput: FilterFindOneGeoLocationsGeometryInput;
  FilterFindOneGeoLocationsInput: FilterFindOneGeoLocationsInput;
  FilterFindOneGeoLocationsOperatorsInput: FilterFindOneGeoLocationsOperatorsInput;
  FilterFindOneGeoLocations_idOperatorsInput: FilterFindOneGeoLocations_IdOperatorsInput;
  FilterFindOneGuidesGuideGUIDOperatorsInput: FilterFindOneGuidesGuideGuidOperatorsInput;
  FilterFindOneGuidesInput: FilterFindOneGuidesInput;
  FilterFindOneGuidesOperatorsInput: FilterFindOneGuidesOperatorsInput;
  FilterFindOneGuidesUserIDOperatorsInput: FilterFindOneGuidesUserIdOperatorsInput;
  FilterFindOneGuides_idOperatorsInput: FilterFindOneGuides_IdOperatorsInput;
  FilterFindOnePhonesCallingCodeOperatorsInput: FilterFindOnePhonesCallingCodeOperatorsInput;
  FilterFindOnePhonesCountryCodeOperatorsInput: FilterFindOnePhonesCountryCodeOperatorsInput;
  FilterFindOnePhonesInput: FilterFindOnePhonesInput;
  FilterFindOnePhonesNumberOperatorsInput: FilterFindOnePhonesNumberOperatorsInput;
  FilterFindOnePhonesOperatorsInput: FilterFindOnePhonesOperatorsInput;
  FilterFindOnePhonesPhoneGUIDOperatorsInput: FilterFindOnePhonesPhoneGuidOperatorsInput;
  FilterFindOnePhones_idOperatorsInput: FilterFindOnePhones_IdOperatorsInput;
  FilterFindOneSchedulesInput: FilterFindOneSchedulesInput;
  FilterFindOneSchedulesOperatorsInput: FilterFindOneSchedulesOperatorsInput;
  FilterFindOneSchedulesRangesInput: FilterFindOneSchedulesRangesInput;
  FilterFindOneSchedules_idOperatorsInput: FilterFindOneSchedules_IdOperatorsInput;
  FilterFindOneUsersActiveOperatorsInput: FilterFindOneUsersActiveOperatorsInput;
  FilterFindOneUsersFirstNameOperatorsInput: FilterFindOneUsersFirstNameOperatorsInput;
  FilterFindOneUsersInput: FilterFindOneUsersInput;
  FilterFindOneUsersLastNameOperatorsInput: FilterFindOneUsersLastNameOperatorsInput;
  FilterFindOneUsersOperatorsInput: FilterFindOneUsersOperatorsInput;
  FilterFindOneUsersUserGUIDOperatorsInput: FilterFindOneUsersUserGuidOperatorsInput;
  FilterFindOneUsersUserNameOperatorsInput: FilterFindOneUsersUserNameOperatorsInput;
  FilterFindOneUsers_idOperatorsInput: FilterFindOneUsers_IdOperatorsInput;
  FilterRemoveManyActivitiesActivityGUIDOperatorsInput: FilterRemoveManyActivitiesActivityGuidOperatorsInput;
  FilterRemoveManyActivitiesDescriptionInput: FilterRemoveManyActivitiesDescriptionInput;
  FilterRemoveManyActivitiesInput: FilterRemoveManyActivitiesInput;
  FilterRemoveManyActivitiesOperatorsInput: FilterRemoveManyActivitiesOperatorsInput;
  FilterRemoveManyActivitiesTitleOperatorsInput: FilterRemoveManyActivitiesTitleOperatorsInput;
  FilterRemoveManyActivities_idOperatorsInput: FilterRemoveManyActivities_IdOperatorsInput;
  FilterRemoveManyAssetsAssetGUIDOperatorsInput: FilterRemoveManyAssetsAssetGuidOperatorsInput;
  FilterRemoveManyAssetsInput: FilterRemoveManyAssetsInput;
  FilterRemoveManyAssetsOperatorsInput: FilterRemoveManyAssetsOperatorsInput;
  FilterRemoveManyAssets_idOperatorsInput: FilterRemoveManyAssets_IdOperatorsInput;
  FilterRemoveManyBookingsActivityIDOperatorsInput: FilterRemoveManyBookingsActivityIdOperatorsInput;
  FilterRemoveManyBookingsBookingGUIDOperatorsInput: FilterRemoveManyBookingsBookingGuidOperatorsInput;
  FilterRemoveManyBookingsInput: FilterRemoveManyBookingsInput;
  FilterRemoveManyBookingsOperatorsInput: FilterRemoveManyBookingsOperatorsInput;
  FilterRemoveManyBookings_idOperatorsInput: FilterRemoveManyBookings_IdOperatorsInput;
  FilterRemoveManyGeoLocationsAnnotationsCurrencyInput: FilterRemoveManyGeoLocationsAnnotationsCurrencyInput;
  FilterRemoveManyGeoLocationsAnnotationsDMSInput: FilterRemoveManyGeoLocationsAnnotationsDmsInput;
  FilterRemoveManyGeoLocationsAnnotationsInput: FilterRemoveManyGeoLocationsAnnotationsInput;
  FilterRemoveManyGeoLocationsAnnotationsMercatorInput: FilterRemoveManyGeoLocationsAnnotationsMercatorInput;
  FilterRemoveManyGeoLocationsAnnotationsNUTSInput: FilterRemoveManyGeoLocationsAnnotationsNutsInput;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterRemoveManyGeoLocationsAnnotationsOSMInput: FilterRemoveManyGeoLocationsAnnotationsOsmInput;
  FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput: FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput;
  FilterRemoveManyGeoLocationsAnnotationsSunInput: FilterRemoveManyGeoLocationsAnnotationsSunInput;
  FilterRemoveManyGeoLocationsAnnotationsSunRiseInput: FilterRemoveManyGeoLocationsAnnotationsSunRiseInput;
  FilterRemoveManyGeoLocationsAnnotationsSunSetInput: FilterRemoveManyGeoLocationsAnnotationsSunSetInput;
  FilterRemoveManyGeoLocationsAnnotationsTimezoneInput: FilterRemoveManyGeoLocationsAnnotationsTimezoneInput;
  FilterRemoveManyGeoLocationsAnnotationsUN_M49Input: FilterRemoveManyGeoLocationsAnnotationsUn_M49Input;
  FilterRemoveManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterRemoveManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput: FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterRemoveManyGeoLocationsBoundsInput: FilterRemoveManyGeoLocationsBoundsInput;
  FilterRemoveManyGeoLocationsBoundsNortheastInput: FilterRemoveManyGeoLocationsBoundsNortheastInput;
  FilterRemoveManyGeoLocationsBoundsSouthwestInput: FilterRemoveManyGeoLocationsBoundsSouthwestInput;
  FilterRemoveManyGeoLocationsComponentsISOInput: FilterRemoveManyGeoLocationsComponentsIsoInput;
  FilterRemoveManyGeoLocationsComponentsISOV1Input: FilterRemoveManyGeoLocationsComponentsIsov1Input;
  FilterRemoveManyGeoLocationsComponentsInput: FilterRemoveManyGeoLocationsComponentsInput;
  FilterRemoveManyGeoLocationsGeometryInput: FilterRemoveManyGeoLocationsGeometryInput;
  FilterRemoveManyGeoLocationsInput: FilterRemoveManyGeoLocationsInput;
  FilterRemoveManyGeoLocationsOperatorsInput: FilterRemoveManyGeoLocationsOperatorsInput;
  FilterRemoveManyGeoLocations_idOperatorsInput: FilterRemoveManyGeoLocations_IdOperatorsInput;
  FilterRemoveManyGuidesGuideGUIDOperatorsInput: FilterRemoveManyGuidesGuideGuidOperatorsInput;
  FilterRemoveManyGuidesInput: FilterRemoveManyGuidesInput;
  FilterRemoveManyGuidesOperatorsInput: FilterRemoveManyGuidesOperatorsInput;
  FilterRemoveManyGuidesUserIDOperatorsInput: FilterRemoveManyGuidesUserIdOperatorsInput;
  FilterRemoveManyGuides_idOperatorsInput: FilterRemoveManyGuides_IdOperatorsInput;
  FilterRemoveManyPhonesCallingCodeOperatorsInput: FilterRemoveManyPhonesCallingCodeOperatorsInput;
  FilterRemoveManyPhonesCountryCodeOperatorsInput: FilterRemoveManyPhonesCountryCodeOperatorsInput;
  FilterRemoveManyPhonesInput: FilterRemoveManyPhonesInput;
  FilterRemoveManyPhonesNumberOperatorsInput: FilterRemoveManyPhonesNumberOperatorsInput;
  FilterRemoveManyPhonesOperatorsInput: FilterRemoveManyPhonesOperatorsInput;
  FilterRemoveManyPhonesPhoneGUIDOperatorsInput: FilterRemoveManyPhonesPhoneGuidOperatorsInput;
  FilterRemoveManyPhones_idOperatorsInput: FilterRemoveManyPhones_IdOperatorsInput;
  FilterRemoveManySchedulesInput: FilterRemoveManySchedulesInput;
  FilterRemoveManySchedulesOperatorsInput: FilterRemoveManySchedulesOperatorsInput;
  FilterRemoveManySchedulesRangesInput: FilterRemoveManySchedulesRangesInput;
  FilterRemoveManySchedules_idOperatorsInput: FilterRemoveManySchedules_IdOperatorsInput;
  FilterRemoveManyUsersActiveOperatorsInput: FilterRemoveManyUsersActiveOperatorsInput;
  FilterRemoveManyUsersBirthdayOperatorsInput: FilterRemoveManyUsersBirthdayOperatorsInput;
  FilterRemoveManyUsersEmailOperatorsInput: FilterRemoveManyUsersEmailOperatorsInput;
  FilterRemoveManyUsersFirstNameOperatorsInput: FilterRemoveManyUsersFirstNameOperatorsInput;
  FilterRemoveManyUsersGenderOperatorsInput: FilterRemoveManyUsersGenderOperatorsInput;
  FilterRemoveManyUsersInput: FilterRemoveManyUsersInput;
  FilterRemoveManyUsersLastNameOperatorsInput: FilterRemoveManyUsersLastNameOperatorsInput;
  FilterRemoveManyUsersOperatorsInput: FilterRemoveManyUsersOperatorsInput;
  FilterRemoveManyUsersPhoneIDOperatorsInput: FilterRemoveManyUsersPhoneIdOperatorsInput;
  FilterRemoveManyUsersSocialProvidersOperatorsInput: FilterRemoveManyUsersSocialProvidersOperatorsInput;
  FilterRemoveManyUsersUserGUIDOperatorsInput: FilterRemoveManyUsersUserGuidOperatorsInput;
  FilterRemoveManyUsersUserNameOperatorsInput: FilterRemoveManyUsersUserNameOperatorsInput;
  FilterRemoveManyUsers_idOperatorsInput: FilterRemoveManyUsers_IdOperatorsInput;
  FilterRemoveOneActivitiesActivityGUIDOperatorsInput: FilterRemoveOneActivitiesActivityGuidOperatorsInput;
  FilterRemoveOneActivitiesDescriptionInput: FilterRemoveOneActivitiesDescriptionInput;
  FilterRemoveOneActivitiesInput: FilterRemoveOneActivitiesInput;
  FilterRemoveOneActivitiesOperatorsInput: FilterRemoveOneActivitiesOperatorsInput;
  FilterRemoveOneActivitiesTitleOperatorsInput: FilterRemoveOneActivitiesTitleOperatorsInput;
  FilterRemoveOneActivities_idOperatorsInput: FilterRemoveOneActivities_IdOperatorsInput;
  FilterRemoveOneAssetsAssetGUIDOperatorsInput: FilterRemoveOneAssetsAssetGuidOperatorsInput;
  FilterRemoveOneAssetsInput: FilterRemoveOneAssetsInput;
  FilterRemoveOneAssetsOperatorsInput: FilterRemoveOneAssetsOperatorsInput;
  FilterRemoveOneAssets_idOperatorsInput: FilterRemoveOneAssets_IdOperatorsInput;
  FilterRemoveOneBookingsActivityIDOperatorsInput: FilterRemoveOneBookingsActivityIdOperatorsInput;
  FilterRemoveOneBookingsBookingGUIDOperatorsInput: FilterRemoveOneBookingsBookingGuidOperatorsInput;
  FilterRemoveOneBookingsInput: FilterRemoveOneBookingsInput;
  FilterRemoveOneBookingsOperatorsInput: FilterRemoveOneBookingsOperatorsInput;
  FilterRemoveOneBookings_idOperatorsInput: FilterRemoveOneBookings_IdOperatorsInput;
  FilterRemoveOneGeoLocationsAnnotationsCurrencyInput: FilterRemoveOneGeoLocationsAnnotationsCurrencyInput;
  FilterRemoveOneGeoLocationsAnnotationsDMSInput: FilterRemoveOneGeoLocationsAnnotationsDmsInput;
  FilterRemoveOneGeoLocationsAnnotationsInput: FilterRemoveOneGeoLocationsAnnotationsInput;
  FilterRemoveOneGeoLocationsAnnotationsMercatorInput: FilterRemoveOneGeoLocationsAnnotationsMercatorInput;
  FilterRemoveOneGeoLocationsAnnotationsNUTSInput: FilterRemoveOneGeoLocationsAnnotationsNutsInput;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterRemoveOneGeoLocationsAnnotationsOSMInput: FilterRemoveOneGeoLocationsAnnotationsOsmInput;
  FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput: FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput;
  FilterRemoveOneGeoLocationsAnnotationsSunInput: FilterRemoveOneGeoLocationsAnnotationsSunInput;
  FilterRemoveOneGeoLocationsAnnotationsSunRiseInput: FilterRemoveOneGeoLocationsAnnotationsSunRiseInput;
  FilterRemoveOneGeoLocationsAnnotationsSunSetInput: FilterRemoveOneGeoLocationsAnnotationsSunSetInput;
  FilterRemoveOneGeoLocationsAnnotationsTimezoneInput: FilterRemoveOneGeoLocationsAnnotationsTimezoneInput;
  FilterRemoveOneGeoLocationsAnnotationsUN_M49Input: FilterRemoveOneGeoLocationsAnnotationsUn_M49Input;
  FilterRemoveOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterRemoveOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput: FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterRemoveOneGeoLocationsBoundsInput: FilterRemoveOneGeoLocationsBoundsInput;
  FilterRemoveOneGeoLocationsBoundsNortheastInput: FilterRemoveOneGeoLocationsBoundsNortheastInput;
  FilterRemoveOneGeoLocationsBoundsSouthwestInput: FilterRemoveOneGeoLocationsBoundsSouthwestInput;
  FilterRemoveOneGeoLocationsComponentsISOInput: FilterRemoveOneGeoLocationsComponentsIsoInput;
  FilterRemoveOneGeoLocationsComponentsISOV1Input: FilterRemoveOneGeoLocationsComponentsIsov1Input;
  FilterRemoveOneGeoLocationsComponentsInput: FilterRemoveOneGeoLocationsComponentsInput;
  FilterRemoveOneGeoLocationsGeometryInput: FilterRemoveOneGeoLocationsGeometryInput;
  FilterRemoveOneGeoLocationsInput: FilterRemoveOneGeoLocationsInput;
  FilterRemoveOneGeoLocationsOperatorsInput: FilterRemoveOneGeoLocationsOperatorsInput;
  FilterRemoveOneGeoLocations_idOperatorsInput: FilterRemoveOneGeoLocations_IdOperatorsInput;
  FilterRemoveOneGuidesGuideGUIDOperatorsInput: FilterRemoveOneGuidesGuideGuidOperatorsInput;
  FilterRemoveOneGuidesInput: FilterRemoveOneGuidesInput;
  FilterRemoveOneGuidesOperatorsInput: FilterRemoveOneGuidesOperatorsInput;
  FilterRemoveOneGuidesUserIDOperatorsInput: FilterRemoveOneGuidesUserIdOperatorsInput;
  FilterRemoveOneGuides_idOperatorsInput: FilterRemoveOneGuides_IdOperatorsInput;
  FilterRemoveOnePhonesCallingCodeOperatorsInput: FilterRemoveOnePhonesCallingCodeOperatorsInput;
  FilterRemoveOnePhonesCountryCodeOperatorsInput: FilterRemoveOnePhonesCountryCodeOperatorsInput;
  FilterRemoveOnePhonesInput: FilterRemoveOnePhonesInput;
  FilterRemoveOnePhonesNumberOperatorsInput: FilterRemoveOnePhonesNumberOperatorsInput;
  FilterRemoveOnePhonesOperatorsInput: FilterRemoveOnePhonesOperatorsInput;
  FilterRemoveOnePhonesPhoneGUIDOperatorsInput: FilterRemoveOnePhonesPhoneGuidOperatorsInput;
  FilterRemoveOnePhones_idOperatorsInput: FilterRemoveOnePhones_IdOperatorsInput;
  FilterRemoveOneSchedulesInput: FilterRemoveOneSchedulesInput;
  FilterRemoveOneSchedulesOperatorsInput: FilterRemoveOneSchedulesOperatorsInput;
  FilterRemoveOneSchedulesRangesInput: FilterRemoveOneSchedulesRangesInput;
  FilterRemoveOneSchedules_idOperatorsInput: FilterRemoveOneSchedules_IdOperatorsInput;
  FilterRemoveOneUsersActiveOperatorsInput: FilterRemoveOneUsersActiveOperatorsInput;
  FilterRemoveOneUsersBirthdayOperatorsInput: FilterRemoveOneUsersBirthdayOperatorsInput;
  FilterRemoveOneUsersEmailOperatorsInput: FilterRemoveOneUsersEmailOperatorsInput;
  FilterRemoveOneUsersFirstNameOperatorsInput: FilterRemoveOneUsersFirstNameOperatorsInput;
  FilterRemoveOneUsersGenderOperatorsInput: FilterRemoveOneUsersGenderOperatorsInput;
  FilterRemoveOneUsersInput: FilterRemoveOneUsersInput;
  FilterRemoveOneUsersLastNameOperatorsInput: FilterRemoveOneUsersLastNameOperatorsInput;
  FilterRemoveOneUsersOperatorsInput: FilterRemoveOneUsersOperatorsInput;
  FilterRemoveOneUsersPhoneIDOperatorsInput: FilterRemoveOneUsersPhoneIdOperatorsInput;
  FilterRemoveOneUsersSocialProvidersOperatorsInput: FilterRemoveOneUsersSocialProvidersOperatorsInput;
  FilterRemoveOneUsersUserGUIDOperatorsInput: FilterRemoveOneUsersUserGuidOperatorsInput;
  FilterRemoveOneUsersUserNameOperatorsInput: FilterRemoveOneUsersUserNameOperatorsInput;
  FilterRemoveOneUsers_idOperatorsInput: FilterRemoveOneUsers_IdOperatorsInput;
  FilterUpdateManyActivitiesActivityGUIDOperatorsInput: FilterUpdateManyActivitiesActivityGuidOperatorsInput;
  FilterUpdateManyActivitiesDescriptionInput: FilterUpdateManyActivitiesDescriptionInput;
  FilterUpdateManyActivitiesInput: FilterUpdateManyActivitiesInput;
  FilterUpdateManyActivitiesOperatorsInput: FilterUpdateManyActivitiesOperatorsInput;
  FilterUpdateManyActivitiesTitleOperatorsInput: FilterUpdateManyActivitiesTitleOperatorsInput;
  FilterUpdateManyActivities_idOperatorsInput: FilterUpdateManyActivities_IdOperatorsInput;
  FilterUpdateManyAssetsAssetGUIDOperatorsInput: FilterUpdateManyAssetsAssetGuidOperatorsInput;
  FilterUpdateManyAssetsInput: FilterUpdateManyAssetsInput;
  FilterUpdateManyAssetsOperatorsInput: FilterUpdateManyAssetsOperatorsInput;
  FilterUpdateManyAssets_idOperatorsInput: FilterUpdateManyAssets_IdOperatorsInput;
  FilterUpdateManyBookingsActivityIDOperatorsInput: FilterUpdateManyBookingsActivityIdOperatorsInput;
  FilterUpdateManyBookingsBookingGUIDOperatorsInput: FilterUpdateManyBookingsBookingGuidOperatorsInput;
  FilterUpdateManyBookingsInput: FilterUpdateManyBookingsInput;
  FilterUpdateManyBookingsOperatorsInput: FilterUpdateManyBookingsOperatorsInput;
  FilterUpdateManyBookings_idOperatorsInput: FilterUpdateManyBookings_IdOperatorsInput;
  FilterUpdateManyGeoLocationsAnnotationsCurrencyInput: FilterUpdateManyGeoLocationsAnnotationsCurrencyInput;
  FilterUpdateManyGeoLocationsAnnotationsDMSInput: FilterUpdateManyGeoLocationsAnnotationsDmsInput;
  FilterUpdateManyGeoLocationsAnnotationsInput: FilterUpdateManyGeoLocationsAnnotationsInput;
  FilterUpdateManyGeoLocationsAnnotationsMercatorInput: FilterUpdateManyGeoLocationsAnnotationsMercatorInput;
  FilterUpdateManyGeoLocationsAnnotationsNUTSInput: FilterUpdateManyGeoLocationsAnnotationsNutsInput;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterUpdateManyGeoLocationsAnnotationsOSMInput: FilterUpdateManyGeoLocationsAnnotationsOsmInput;
  FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput: FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput;
  FilterUpdateManyGeoLocationsAnnotationsSunInput: FilterUpdateManyGeoLocationsAnnotationsSunInput;
  FilterUpdateManyGeoLocationsAnnotationsSunRiseInput: FilterUpdateManyGeoLocationsAnnotationsSunRiseInput;
  FilterUpdateManyGeoLocationsAnnotationsSunSetInput: FilterUpdateManyGeoLocationsAnnotationsSunSetInput;
  FilterUpdateManyGeoLocationsAnnotationsTimezoneInput: FilterUpdateManyGeoLocationsAnnotationsTimezoneInput;
  FilterUpdateManyGeoLocationsAnnotationsUN_M49Input: FilterUpdateManyGeoLocationsAnnotationsUn_M49Input;
  FilterUpdateManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterUpdateManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput: FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterUpdateManyGeoLocationsBoundsInput: FilterUpdateManyGeoLocationsBoundsInput;
  FilterUpdateManyGeoLocationsBoundsNortheastInput: FilterUpdateManyGeoLocationsBoundsNortheastInput;
  FilterUpdateManyGeoLocationsBoundsSouthwestInput: FilterUpdateManyGeoLocationsBoundsSouthwestInput;
  FilterUpdateManyGeoLocationsComponentsISOInput: FilterUpdateManyGeoLocationsComponentsIsoInput;
  FilterUpdateManyGeoLocationsComponentsISOV1Input: FilterUpdateManyGeoLocationsComponentsIsov1Input;
  FilterUpdateManyGeoLocationsComponentsInput: FilterUpdateManyGeoLocationsComponentsInput;
  FilterUpdateManyGeoLocationsGeometryInput: FilterUpdateManyGeoLocationsGeometryInput;
  FilterUpdateManyGeoLocationsInput: FilterUpdateManyGeoLocationsInput;
  FilterUpdateManyGeoLocationsOperatorsInput: FilterUpdateManyGeoLocationsOperatorsInput;
  FilterUpdateManyGeoLocations_idOperatorsInput: FilterUpdateManyGeoLocations_IdOperatorsInput;
  FilterUpdateManyGuidesGuideGUIDOperatorsInput: FilterUpdateManyGuidesGuideGuidOperatorsInput;
  FilterUpdateManyGuidesInput: FilterUpdateManyGuidesInput;
  FilterUpdateManyGuidesOperatorsInput: FilterUpdateManyGuidesOperatorsInput;
  FilterUpdateManyGuidesUserIDOperatorsInput: FilterUpdateManyGuidesUserIdOperatorsInput;
  FilterUpdateManyGuides_idOperatorsInput: FilterUpdateManyGuides_IdOperatorsInput;
  FilterUpdateManyPhonesCallingCodeOperatorsInput: FilterUpdateManyPhonesCallingCodeOperatorsInput;
  FilterUpdateManyPhonesCountryCodeOperatorsInput: FilterUpdateManyPhonesCountryCodeOperatorsInput;
  FilterUpdateManyPhonesInput: FilterUpdateManyPhonesInput;
  FilterUpdateManyPhonesNumberOperatorsInput: FilterUpdateManyPhonesNumberOperatorsInput;
  FilterUpdateManyPhonesOperatorsInput: FilterUpdateManyPhonesOperatorsInput;
  FilterUpdateManyPhonesPhoneGUIDOperatorsInput: FilterUpdateManyPhonesPhoneGuidOperatorsInput;
  FilterUpdateManyPhones_idOperatorsInput: FilterUpdateManyPhones_IdOperatorsInput;
  FilterUpdateManySchedulesInput: FilterUpdateManySchedulesInput;
  FilterUpdateManySchedulesOperatorsInput: FilterUpdateManySchedulesOperatorsInput;
  FilterUpdateManySchedulesRangesInput: FilterUpdateManySchedulesRangesInput;
  FilterUpdateManySchedules_idOperatorsInput: FilterUpdateManySchedules_IdOperatorsInput;
  FilterUpdateManyUsersActiveOperatorsInput: FilterUpdateManyUsersActiveOperatorsInput;
  FilterUpdateManyUsersBirthdayOperatorsInput: FilterUpdateManyUsersBirthdayOperatorsInput;
  FilterUpdateManyUsersEmailOperatorsInput: FilterUpdateManyUsersEmailOperatorsInput;
  FilterUpdateManyUsersFirstNameOperatorsInput: FilterUpdateManyUsersFirstNameOperatorsInput;
  FilterUpdateManyUsersGenderOperatorsInput: FilterUpdateManyUsersGenderOperatorsInput;
  FilterUpdateManyUsersInput: FilterUpdateManyUsersInput;
  FilterUpdateManyUsersLastNameOperatorsInput: FilterUpdateManyUsersLastNameOperatorsInput;
  FilterUpdateManyUsersOperatorsInput: FilterUpdateManyUsersOperatorsInput;
  FilterUpdateManyUsersPhoneIDOperatorsInput: FilterUpdateManyUsersPhoneIdOperatorsInput;
  FilterUpdateManyUsersSocialProvidersOperatorsInput: FilterUpdateManyUsersSocialProvidersOperatorsInput;
  FilterUpdateManyUsersUserGUIDOperatorsInput: FilterUpdateManyUsersUserGuidOperatorsInput;
  FilterUpdateManyUsersUserNameOperatorsInput: FilterUpdateManyUsersUserNameOperatorsInput;
  FilterUpdateManyUsers_idOperatorsInput: FilterUpdateManyUsers_IdOperatorsInput;
  FilterUpdateOneActivitiesActivityGUIDOperatorsInput: FilterUpdateOneActivitiesActivityGuidOperatorsInput;
  FilterUpdateOneActivitiesDescriptionInput: FilterUpdateOneActivitiesDescriptionInput;
  FilterUpdateOneActivitiesInput: FilterUpdateOneActivitiesInput;
  FilterUpdateOneActivitiesOperatorsInput: FilterUpdateOneActivitiesOperatorsInput;
  FilterUpdateOneActivitiesTitleOperatorsInput: FilterUpdateOneActivitiesTitleOperatorsInput;
  FilterUpdateOneActivities_idOperatorsInput: FilterUpdateOneActivities_IdOperatorsInput;
  FilterUpdateOneAssetsAssetGUIDOperatorsInput: FilterUpdateOneAssetsAssetGuidOperatorsInput;
  FilterUpdateOneAssetsInput: FilterUpdateOneAssetsInput;
  FilterUpdateOneAssetsOperatorsInput: FilterUpdateOneAssetsOperatorsInput;
  FilterUpdateOneAssets_idOperatorsInput: FilterUpdateOneAssets_IdOperatorsInput;
  FilterUpdateOneBookingsActivityIDOperatorsInput: FilterUpdateOneBookingsActivityIdOperatorsInput;
  FilterUpdateOneBookingsBookingGUIDOperatorsInput: FilterUpdateOneBookingsBookingGuidOperatorsInput;
  FilterUpdateOneBookingsInput: FilterUpdateOneBookingsInput;
  FilterUpdateOneBookingsOperatorsInput: FilterUpdateOneBookingsOperatorsInput;
  FilterUpdateOneBookings_idOperatorsInput: FilterUpdateOneBookings_IdOperatorsInput;
  FilterUpdateOneGeoLocationsAnnotationsCurrencyInput: FilterUpdateOneGeoLocationsAnnotationsCurrencyInput;
  FilterUpdateOneGeoLocationsAnnotationsDMSInput: FilterUpdateOneGeoLocationsAnnotationsDmsInput;
  FilterUpdateOneGeoLocationsAnnotationsInput: FilterUpdateOneGeoLocationsAnnotationsInput;
  FilterUpdateOneGeoLocationsAnnotationsMercatorInput: FilterUpdateOneGeoLocationsAnnotationsMercatorInput;
  FilterUpdateOneGeoLocationsAnnotationsNUTSInput: FilterUpdateOneGeoLocationsAnnotationsNutsInput;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterUpdateOneGeoLocationsAnnotationsOSMInput: FilterUpdateOneGeoLocationsAnnotationsOsmInput;
  FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput: FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput;
  FilterUpdateOneGeoLocationsAnnotationsSunInput: FilterUpdateOneGeoLocationsAnnotationsSunInput;
  FilterUpdateOneGeoLocationsAnnotationsSunRiseInput: FilterUpdateOneGeoLocationsAnnotationsSunRiseInput;
  FilterUpdateOneGeoLocationsAnnotationsSunSetInput: FilterUpdateOneGeoLocationsAnnotationsSunSetInput;
  FilterUpdateOneGeoLocationsAnnotationsTimezoneInput: FilterUpdateOneGeoLocationsAnnotationsTimezoneInput;
  FilterUpdateOneGeoLocationsAnnotationsUN_M49Input: FilterUpdateOneGeoLocationsAnnotationsUn_M49Input;
  FilterUpdateOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterUpdateOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput: FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterUpdateOneGeoLocationsBoundsInput: FilterUpdateOneGeoLocationsBoundsInput;
  FilterUpdateOneGeoLocationsBoundsNortheastInput: FilterUpdateOneGeoLocationsBoundsNortheastInput;
  FilterUpdateOneGeoLocationsBoundsSouthwestInput: FilterUpdateOneGeoLocationsBoundsSouthwestInput;
  FilterUpdateOneGeoLocationsComponentsISOInput: FilterUpdateOneGeoLocationsComponentsIsoInput;
  FilterUpdateOneGeoLocationsComponentsISOV1Input: FilterUpdateOneGeoLocationsComponentsIsov1Input;
  FilterUpdateOneGeoLocationsComponentsInput: FilterUpdateOneGeoLocationsComponentsInput;
  FilterUpdateOneGeoLocationsGeometryInput: FilterUpdateOneGeoLocationsGeometryInput;
  FilterUpdateOneGeoLocationsInput: FilterUpdateOneGeoLocationsInput;
  FilterUpdateOneGeoLocationsOperatorsInput: FilterUpdateOneGeoLocationsOperatorsInput;
  FilterUpdateOneGeoLocations_idOperatorsInput: FilterUpdateOneGeoLocations_IdOperatorsInput;
  FilterUpdateOneGuidesGuideGUIDOperatorsInput: FilterUpdateOneGuidesGuideGuidOperatorsInput;
  FilterUpdateOneGuidesInput: FilterUpdateOneGuidesInput;
  FilterUpdateOneGuidesOperatorsInput: FilterUpdateOneGuidesOperatorsInput;
  FilterUpdateOneGuidesUserIDOperatorsInput: FilterUpdateOneGuidesUserIdOperatorsInput;
  FilterUpdateOneGuides_idOperatorsInput: FilterUpdateOneGuides_IdOperatorsInput;
  FilterUpdateOnePhonesCallingCodeOperatorsInput: FilterUpdateOnePhonesCallingCodeOperatorsInput;
  FilterUpdateOnePhonesCountryCodeOperatorsInput: FilterUpdateOnePhonesCountryCodeOperatorsInput;
  FilterUpdateOnePhonesInput: FilterUpdateOnePhonesInput;
  FilterUpdateOnePhonesNumberOperatorsInput: FilterUpdateOnePhonesNumberOperatorsInput;
  FilterUpdateOnePhonesOperatorsInput: FilterUpdateOnePhonesOperatorsInput;
  FilterUpdateOnePhonesPhoneGUIDOperatorsInput: FilterUpdateOnePhonesPhoneGuidOperatorsInput;
  FilterUpdateOnePhones_idOperatorsInput: FilterUpdateOnePhones_IdOperatorsInput;
  FilterUpdateOneSchedulesInput: FilterUpdateOneSchedulesInput;
  FilterUpdateOneSchedulesOperatorsInput: FilterUpdateOneSchedulesOperatorsInput;
  FilterUpdateOneSchedulesRangesInput: FilterUpdateOneSchedulesRangesInput;
  FilterUpdateOneSchedules_idOperatorsInput: FilterUpdateOneSchedules_IdOperatorsInput;
  FilterUpdateOneUsersActiveOperatorsInput: FilterUpdateOneUsersActiveOperatorsInput;
  FilterUpdateOneUsersBirthdayOperatorsInput: FilterUpdateOneUsersBirthdayOperatorsInput;
  FilterUpdateOneUsersEmailOperatorsInput: FilterUpdateOneUsersEmailOperatorsInput;
  FilterUpdateOneUsersFirstNameOperatorsInput: FilterUpdateOneUsersFirstNameOperatorsInput;
  FilterUpdateOneUsersGenderOperatorsInput: FilterUpdateOneUsersGenderOperatorsInput;
  FilterUpdateOneUsersInput: FilterUpdateOneUsersInput;
  FilterUpdateOneUsersLastNameOperatorsInput: FilterUpdateOneUsersLastNameOperatorsInput;
  FilterUpdateOneUsersOperatorsInput: FilterUpdateOneUsersOperatorsInput;
  FilterUpdateOneUsersPhoneIDOperatorsInput: FilterUpdateOneUsersPhoneIdOperatorsInput;
  FilterUpdateOneUsersSocialProvidersOperatorsInput: FilterUpdateOneUsersSocialProvidersOperatorsInput;
  FilterUpdateOneUsersUserGUIDOperatorsInput: FilterUpdateOneUsersUserGuidOperatorsInput;
  FilterUpdateOneUsersUserNameOperatorsInput: FilterUpdateOneUsersUserNameOperatorsInput;
  FilterUpdateOneUsers_idOperatorsInput: FilterUpdateOneUsers_IdOperatorsInput;
  FinishReason: FinishReason;
  FunctionCall: ResolverTypeWrapper<FunctionCall>;
  GUID: ResolverTypeWrapper<Scalars['GUID']['output']>;
  GeoLocationSearchInput: GeoLocationSearchInput;
  GeoLocations: ResolverTypeWrapper<GeoLocations>;
  GeoLocationsAnnotations: ResolverTypeWrapper<GeoLocationsAnnotations>;
  GeoLocationsAnnotationsCurrency: ResolverTypeWrapper<GeoLocationsAnnotationsCurrency>;
  GeoLocationsAnnotationsCurrencyInput: GeoLocationsAnnotationsCurrencyInput;
  GeoLocationsAnnotationsDMS: ResolverTypeWrapper<GeoLocationsAnnotationsDms>;
  GeoLocationsAnnotationsDMSInput: GeoLocationsAnnotationsDmsInput;
  GeoLocationsAnnotationsInput: GeoLocationsAnnotationsInput;
  GeoLocationsAnnotationsMercator: ResolverTypeWrapper<GeoLocationsAnnotationsMercator>;
  GeoLocationsAnnotationsMercatorInput: GeoLocationsAnnotationsMercatorInput;
  GeoLocationsAnnotationsNUTS: ResolverTypeWrapper<GeoLocationsAnnotationsNuts>;
  GeoLocationsAnnotationsNUTSInput: GeoLocationsAnnotationsNutsInput;
  GeoLocationsAnnotationsNUTSNUTS0: ResolverTypeWrapper<GeoLocationsAnnotationsNutsnuts0>;
  GeoLocationsAnnotationsNUTSNUTS0Input: GeoLocationsAnnotationsNutsnuts0Input;
  GeoLocationsAnnotationsNUTSNUTS1: ResolverTypeWrapper<GeoLocationsAnnotationsNutsnuts1>;
  GeoLocationsAnnotationsNUTSNUTS1Input: GeoLocationsAnnotationsNutsnuts1Input;
  GeoLocationsAnnotationsNUTSNUTS2: ResolverTypeWrapper<GeoLocationsAnnotationsNutsnuts2>;
  GeoLocationsAnnotationsNUTSNUTS2Input: GeoLocationsAnnotationsNutsnuts2Input;
  GeoLocationsAnnotationsNUTSNUTS3: ResolverTypeWrapper<GeoLocationsAnnotationsNutsnuts3>;
  GeoLocationsAnnotationsNUTSNUTS3Input: GeoLocationsAnnotationsNutsnuts3Input;
  GeoLocationsAnnotationsOSM: ResolverTypeWrapper<GeoLocationsAnnotationsOsm>;
  GeoLocationsAnnotationsOSMInput: GeoLocationsAnnotationsOsmInput;
  GeoLocationsAnnotationsRoadinfo: ResolverTypeWrapper<GeoLocationsAnnotationsRoadinfo>;
  GeoLocationsAnnotationsRoadinfoInput: GeoLocationsAnnotationsRoadinfoInput;
  GeoLocationsAnnotationsSun: ResolverTypeWrapper<GeoLocationsAnnotationsSun>;
  GeoLocationsAnnotationsSunInput: GeoLocationsAnnotationsSunInput;
  GeoLocationsAnnotationsSunRise: ResolverTypeWrapper<GeoLocationsAnnotationsSunRise>;
  GeoLocationsAnnotationsSunRiseInput: GeoLocationsAnnotationsSunRiseInput;
  GeoLocationsAnnotationsSunSet: ResolverTypeWrapper<GeoLocationsAnnotationsSunSet>;
  GeoLocationsAnnotationsSunSetInput: GeoLocationsAnnotationsSunSetInput;
  GeoLocationsAnnotationsTimezone: ResolverTypeWrapper<GeoLocationsAnnotationsTimezone>;
  GeoLocationsAnnotationsTimezoneInput: GeoLocationsAnnotationsTimezoneInput;
  GeoLocationsAnnotationsUN_M49: ResolverTypeWrapper<GeoLocationsAnnotationsUn_M49>;
  GeoLocationsAnnotationsUN_M49Input: GeoLocationsAnnotationsUn_M49Input;
  GeoLocationsAnnotationsUN_M49Regions: ResolverTypeWrapper<GeoLocationsAnnotationsUn_M49Regions>;
  GeoLocationsAnnotationsUN_M49RegionsInput: GeoLocationsAnnotationsUn_M49RegionsInput;
  GeoLocationsAnnotationsWhat3words: ResolverTypeWrapper<GeoLocationsAnnotationsWhat3words>;
  GeoLocationsAnnotationsWhat3wordsInput: GeoLocationsAnnotationsWhat3wordsInput;
  GeoLocationsBounds: ResolverTypeWrapper<GeoLocationsBounds>;
  GeoLocationsBoundsInput: GeoLocationsBoundsInput;
  GeoLocationsBoundsNortheast: ResolverTypeWrapper<GeoLocationsBoundsNortheast>;
  GeoLocationsBoundsNortheastInput: GeoLocationsBoundsNortheastInput;
  GeoLocationsBoundsSouthwest: ResolverTypeWrapper<GeoLocationsBoundsSouthwest>;
  GeoLocationsBoundsSouthwestInput: GeoLocationsBoundsSouthwestInput;
  GeoLocationsComponents: ResolverTypeWrapper<GeoLocationsComponents>;
  GeoLocationsComponentsISO: ResolverTypeWrapper<GeoLocationsComponentsIso>;
  GeoLocationsComponentsISOInput: GeoLocationsComponentsIsoInput;
  GeoLocationsComponentsISOV1: ResolverTypeWrapper<GeoLocationsComponentsIsov1>;
  GeoLocationsComponentsISOV1Input: GeoLocationsComponentsIsov1Input;
  GeoLocationsComponentsInput: GeoLocationsComponentsInput;
  GeoLocationsGeometry: ResolverTypeWrapper<GeoLocationsGeometry>;
  GeoLocationsGeometryInput: GeoLocationsGeometryInput;
  GeoLocationsPagination: ResolverTypeWrapper<GeoLocationsPagination>;
  Guides: ResolverTypeWrapper<Guides>;
  GuidesPagination: ResolverTypeWrapper<GuidesPagination>;
  HSL: ResolverTypeWrapper<Scalars['HSL']['output']>;
  HSLA: ResolverTypeWrapper<Scalars['HSLA']['output']>;
  HexColorCode: ResolverTypeWrapper<Scalars['HexColorCode']['output']>;
  Hexadecimal: ResolverTypeWrapper<Scalars['Hexadecimal']['output']>;
  IBAN: ResolverTypeWrapper<Scalars['IBAN']['output']>;
  IP: ResolverTypeWrapper<Scalars['IP']['output']>;
  IPCPatent: ResolverTypeWrapper<Scalars['IPCPatent']['output']>;
  IPv4: ResolverTypeWrapper<Scalars['IPv4']['output']>;
  IPv6: ResolverTypeWrapper<Scalars['IPv6']['output']>;
  ISBN: ResolverTypeWrapper<Scalars['ISBN']['output']>;
  ISO8601Duration: ResolverTypeWrapper<Scalars['ISO8601Duration']['output']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  JWT: ResolverTypeWrapper<Scalars['JWT']['output']>;
  LCCSubclass: ResolverTypeWrapper<Scalars['LCCSubclass']['output']>;
  Latitude: ResolverTypeWrapper<Scalars['Latitude']['output']>;
  LocalDate: ResolverTypeWrapper<Scalars['LocalDate']['output']>;
  LocalDateTime: ResolverTypeWrapper<Scalars['LocalDateTime']['output']>;
  LocalEndTime: ResolverTypeWrapper<Scalars['LocalEndTime']['output']>;
  LocalTime: ResolverTypeWrapper<Scalars['LocalTime']['output']>;
  Locale: ResolverTypeWrapper<Scalars['Locale']['output']>;
  Logprobs: ResolverTypeWrapper<Logprobs>;
  Long: ResolverTypeWrapper<Scalars['Long']['output']>;
  Longitude: ResolverTypeWrapper<Scalars['Longitude']['output']>;
  MAC: ResolverTypeWrapper<Scalars['MAC']['output']>;
  Message: ResolverTypeWrapper<Message>;
  MongoID: ResolverTypeWrapper<Scalars['MongoID']['output']>;
  Mutation: ResolverTypeWrapper<{}>;
  NegativeFloat: ResolverTypeWrapper<Scalars['NegativeFloat']['output']>;
  NegativeInt: ResolverTypeWrapper<Scalars['NegativeInt']['output']>;
  NonEmptyString: ResolverTypeWrapper<Scalars['NonEmptyString']['output']>;
  NonNegativeFloat: ResolverTypeWrapper<Scalars['NonNegativeFloat']['output']>;
  NonNegativeInt: ResolverTypeWrapper<Scalars['NonNegativeInt']['output']>;
  NonPositiveFloat: ResolverTypeWrapper<Scalars['NonPositiveFloat']['output']>;
  NonPositiveInt: ResolverTypeWrapper<Scalars['NonPositiveInt']['output']>;
  ObjectID: ResolverTypeWrapper<Scalars['ObjectID']['output']>;
  PaginationInfo: ResolverTypeWrapper<PaginationInfo>;
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']['output']>;
  Phones: ResolverTypeWrapper<Phones>;
  PhonesPagination: ResolverTypeWrapper<PhonesPagination>;
  Port: ResolverTypeWrapper<Scalars['Port']['output']>;
  PositiveFloat: ResolverTypeWrapper<Scalars['PositiveFloat']['output']>;
  PositiveInt: ResolverTypeWrapper<Scalars['PositiveInt']['output']>;
  PostalCode: ResolverTypeWrapper<Scalars['PostalCode']['output']>;
  Profile: ResolverTypeWrapper<Profile>;
  Provider: Provider;
  Query: ResolverTypeWrapper<{}>;
  RGB: ResolverTypeWrapper<Scalars['RGB']['output']>;
  RGBA: ResolverTypeWrapper<Scalars['RGBA']['output']>;
  RefreshToken: ResolverTypeWrapper<RefreshToken>;
  RefreshTokenInput: RefreshTokenInput;
  RegExpAsString: ResolverTypeWrapper<Scalars['RegExpAsString']['output']>;
  Register: ResolverTypeWrapper<Register>;
  RegisterInput: RegisterInput;
  RemoveManyActivitiesPayload: ResolverTypeWrapper<RemoveManyActivitiesPayload>;
  RemoveManyAssetsPayload: ResolverTypeWrapper<RemoveManyAssetsPayload>;
  RemoveManyBookingsPayload: ResolverTypeWrapper<RemoveManyBookingsPayload>;
  RemoveManyGeoLocationsPayload: ResolverTypeWrapper<RemoveManyGeoLocationsPayload>;
  RemoveManyGuidesPayload: ResolverTypeWrapper<RemoveManyGuidesPayload>;
  RemoveManyPhonesPayload: ResolverTypeWrapper<RemoveManyPhonesPayload>;
  RemoveManySchedulesPayload: ResolverTypeWrapper<RemoveManySchedulesPayload>;
  RemoveManyUsersPayload: ResolverTypeWrapper<RemoveManyUsersPayload>;
  RemoveOneActivitiesPayload: ResolverTypeWrapper<RemoveOneActivitiesPayload>;
  RemoveOneAssetsPayload: ResolverTypeWrapper<RemoveOneAssetsPayload>;
  RemoveOneBookingsPayload: ResolverTypeWrapper<RemoveOneBookingsPayload>;
  RemoveOneGeoLocationsPayload: ResolverTypeWrapper<RemoveOneGeoLocationsPayload>;
  RemoveOneGuidesPayload: ResolverTypeWrapper<RemoveOneGuidesPayload>;
  RemoveOnePhonesPayload: ResolverTypeWrapper<RemoveOnePhonesPayload>;
  RemoveOneSchedulesPayload: ResolverTypeWrapper<RemoveOneSchedulesPayload>;
  RemoveOneUsersPayload: ResolverTypeWrapper<RemoveOneUsersPayload>;
  Role: Role;
  RoutingNumber: ResolverTypeWrapper<Scalars['RoutingNumber']['output']>;
  SESSN: ResolverTypeWrapper<Scalars['SESSN']['output']>;
  SafeInt: ResolverTypeWrapper<Scalars['SafeInt']['output']>;
  Schedules: ResolverTypeWrapper<Schedules>;
  SchedulesPagination: ResolverTypeWrapper<SchedulesPagination>;
  SchedulesRanges: ResolverTypeWrapper<SchedulesRanges>;
  SchedulesRangesInput: SchedulesRangesInput;
  SemVer: ResolverTypeWrapper<Scalars['SemVer']['output']>;
  SignIn: ResolverTypeWrapper<SignIn>;
  SignInInput: SignInInput;
  SignOut: ResolverTypeWrapper<SignOut>;
  SignOutInput: SignOutInput;
  SocialSignIn: ResolverTypeWrapper<SocialSignIn>;
  SocialSignInInput: SocialSignInInput;
  SortFindManyActivitiesInput: SortFindManyActivitiesInput;
  SortFindManyAssetsInput: SortFindManyAssetsInput;
  SortFindManyBookingsInput: SortFindManyBookingsInput;
  SortFindManyCountriesInput: SortFindManyCountriesInput;
  SortFindManyGeoLocationsInput: SortFindManyGeoLocationsInput;
  SortFindManyGuidesInput: SortFindManyGuidesInput;
  SortFindManyPhonesInput: SortFindManyPhonesInput;
  SortFindManySchedulesInput: SortFindManySchedulesInput;
  SortFindManyUsersInput: SortFindManyUsersInput;
  SortFindOneActivitiesInput: SortFindOneActivitiesInput;
  SortFindOneAssetsInput: SortFindOneAssetsInput;
  SortFindOneBookingsInput: SortFindOneBookingsInput;
  SortFindOneCountriesInput: SortFindOneCountriesInput;
  SortFindOneGeoLocationsInput: SortFindOneGeoLocationsInput;
  SortFindOneGuidesInput: SortFindOneGuidesInput;
  SortFindOnePhonesInput: SortFindOnePhonesInput;
  SortFindOneSchedulesInput: SortFindOneSchedulesInput;
  SortFindOneUsersInput: SortFindOneUsersInput;
  SortRemoveOneActivitiesInput: SortRemoveOneActivitiesInput;
  SortRemoveOneAssetsInput: SortRemoveOneAssetsInput;
  SortRemoveOneBookingsInput: SortRemoveOneBookingsInput;
  SortRemoveOneGeoLocationsInput: SortRemoveOneGeoLocationsInput;
  SortRemoveOneGuidesInput: SortRemoveOneGuidesInput;
  SortRemoveOnePhonesInput: SortRemoveOnePhonesInput;
  SortRemoveOneSchedulesInput: SortRemoveOneSchedulesInput;
  SortRemoveOneUsersInput: SortRemoveOneUsersInput;
  SortUpdateManyActivitiesInput: SortUpdateManyActivitiesInput;
  SortUpdateManyAssetsInput: SortUpdateManyAssetsInput;
  SortUpdateManyBookingsInput: SortUpdateManyBookingsInput;
  SortUpdateManyGeoLocationsInput: SortUpdateManyGeoLocationsInput;
  SortUpdateManyGuidesInput: SortUpdateManyGuidesInput;
  SortUpdateManyPhonesInput: SortUpdateManyPhonesInput;
  SortUpdateManySchedulesInput: SortUpdateManySchedulesInput;
  SortUpdateManyUsersInput: SortUpdateManyUsersInput;
  SortUpdateOneActivitiesInput: SortUpdateOneActivitiesInput;
  SortUpdateOneAssetsInput: SortUpdateOneAssetsInput;
  SortUpdateOneBookingsInput: SortUpdateOneBookingsInput;
  SortUpdateOneGeoLocationsInput: SortUpdateOneGeoLocationsInput;
  SortUpdateOneGuidesInput: SortUpdateOneGuidesInput;
  SortUpdateOnePhonesInput: SortUpdateOnePhonesInput;
  SortUpdateOneSchedulesInput: SortUpdateOneSchedulesInput;
  SortUpdateOneUsersInput: SortUpdateOneUsersInput;
  Subscription: ResolverTypeWrapper<{}>;
  Suggestion: ResolverTypeWrapper<Suggestion>;
  SuggestionsInput: SuggestionsInput;
  Time: ResolverTypeWrapper<Scalars['Time']['output']>;
  TimeZone: ResolverTypeWrapper<Scalars['TimeZone']['output']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']['output']>;
  TopLogprob: ResolverTypeWrapper<TopLogprob>;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  USCurrency: ResolverTypeWrapper<Scalars['USCurrency']['output']>;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UnsignedFloat: ResolverTypeWrapper<Scalars['UnsignedFloat']['output']>;
  UnsignedInt: ResolverTypeWrapper<Scalars['UnsignedInt']['output']>;
  UpdateManyActivitiesDescriptionInput: UpdateManyActivitiesDescriptionInput;
  UpdateManyActivitiesInput: UpdateManyActivitiesInput;
  UpdateManyActivitiesPayload: ResolverTypeWrapper<UpdateManyActivitiesPayload>;
  UpdateManyAssetsInput: UpdateManyAssetsInput;
  UpdateManyAssetsPayload: ResolverTypeWrapper<UpdateManyAssetsPayload>;
  UpdateManyBookingsInput: UpdateManyBookingsInput;
  UpdateManyBookingsPayload: ResolverTypeWrapper<UpdateManyBookingsPayload>;
  UpdateManyGeoLocationsAnnotationsCurrencyInput: UpdateManyGeoLocationsAnnotationsCurrencyInput;
  UpdateManyGeoLocationsAnnotationsDMSInput: UpdateManyGeoLocationsAnnotationsDmsInput;
  UpdateManyGeoLocationsAnnotationsInput: UpdateManyGeoLocationsAnnotationsInput;
  UpdateManyGeoLocationsAnnotationsMercatorInput: UpdateManyGeoLocationsAnnotationsMercatorInput;
  UpdateManyGeoLocationsAnnotationsNUTSInput: UpdateManyGeoLocationsAnnotationsNutsInput;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS0Input: UpdateManyGeoLocationsAnnotationsNutsnuts0Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS1Input: UpdateManyGeoLocationsAnnotationsNutsnuts1Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS2Input: UpdateManyGeoLocationsAnnotationsNutsnuts2Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS3Input: UpdateManyGeoLocationsAnnotationsNutsnuts3Input;
  UpdateManyGeoLocationsAnnotationsOSMInput: UpdateManyGeoLocationsAnnotationsOsmInput;
  UpdateManyGeoLocationsAnnotationsRoadinfoInput: UpdateManyGeoLocationsAnnotationsRoadinfoInput;
  UpdateManyGeoLocationsAnnotationsSunInput: UpdateManyGeoLocationsAnnotationsSunInput;
  UpdateManyGeoLocationsAnnotationsSunRiseInput: UpdateManyGeoLocationsAnnotationsSunRiseInput;
  UpdateManyGeoLocationsAnnotationsSunSetInput: UpdateManyGeoLocationsAnnotationsSunSetInput;
  UpdateManyGeoLocationsAnnotationsTimezoneInput: UpdateManyGeoLocationsAnnotationsTimezoneInput;
  UpdateManyGeoLocationsAnnotationsUN_M49Input: UpdateManyGeoLocationsAnnotationsUn_M49Input;
  UpdateManyGeoLocationsAnnotationsUN_M49RegionsInput: UpdateManyGeoLocationsAnnotationsUn_M49RegionsInput;
  UpdateManyGeoLocationsAnnotationsWhat3wordsInput: UpdateManyGeoLocationsAnnotationsWhat3wordsInput;
  UpdateManyGeoLocationsBoundsInput: UpdateManyGeoLocationsBoundsInput;
  UpdateManyGeoLocationsBoundsNortheastInput: UpdateManyGeoLocationsBoundsNortheastInput;
  UpdateManyGeoLocationsBoundsSouthwestInput: UpdateManyGeoLocationsBoundsSouthwestInput;
  UpdateManyGeoLocationsComponentsISOInput: UpdateManyGeoLocationsComponentsIsoInput;
  UpdateManyGeoLocationsComponentsISOV1Input: UpdateManyGeoLocationsComponentsIsov1Input;
  UpdateManyGeoLocationsComponentsInput: UpdateManyGeoLocationsComponentsInput;
  UpdateManyGeoLocationsGeometryInput: UpdateManyGeoLocationsGeometryInput;
  UpdateManyGeoLocationsInput: UpdateManyGeoLocationsInput;
  UpdateManyGeoLocationsPayload: ResolverTypeWrapper<UpdateManyGeoLocationsPayload>;
  UpdateManyGuidesInput: UpdateManyGuidesInput;
  UpdateManyGuidesPayload: ResolverTypeWrapper<UpdateManyGuidesPayload>;
  UpdateManyPhonesInput: UpdateManyPhonesInput;
  UpdateManyPhonesPayload: ResolverTypeWrapper<UpdateManyPhonesPayload>;
  UpdateManySchedulesInput: UpdateManySchedulesInput;
  UpdateManySchedulesPayload: ResolverTypeWrapper<UpdateManySchedulesPayload>;
  UpdateManySchedulesRangesInput: UpdateManySchedulesRangesInput;
  UpdateManyUsersInput: UpdateManyUsersInput;
  UpdateManyUsersPayload: ResolverTypeWrapper<UpdateManyUsersPayload>;
  UpdateOneActivitiesDescriptionInput: UpdateOneActivitiesDescriptionInput;
  UpdateOneActivitiesInput: UpdateOneActivitiesInput;
  UpdateOneActivitiesPayload: ResolverTypeWrapper<UpdateOneActivitiesPayload>;
  UpdateOneAssetsInput: UpdateOneAssetsInput;
  UpdateOneAssetsPayload: ResolverTypeWrapper<UpdateOneAssetsPayload>;
  UpdateOneBookingsInput: UpdateOneBookingsInput;
  UpdateOneBookingsPayload: ResolverTypeWrapper<UpdateOneBookingsPayload>;
  UpdateOneGeoLocationsAnnotationsCurrencyInput: UpdateOneGeoLocationsAnnotationsCurrencyInput;
  UpdateOneGeoLocationsAnnotationsDMSInput: UpdateOneGeoLocationsAnnotationsDmsInput;
  UpdateOneGeoLocationsAnnotationsInput: UpdateOneGeoLocationsAnnotationsInput;
  UpdateOneGeoLocationsAnnotationsMercatorInput: UpdateOneGeoLocationsAnnotationsMercatorInput;
  UpdateOneGeoLocationsAnnotationsNUTSInput: UpdateOneGeoLocationsAnnotationsNutsInput;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS0Input: UpdateOneGeoLocationsAnnotationsNutsnuts0Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS1Input: UpdateOneGeoLocationsAnnotationsNutsnuts1Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS2Input: UpdateOneGeoLocationsAnnotationsNutsnuts2Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS3Input: UpdateOneGeoLocationsAnnotationsNutsnuts3Input;
  UpdateOneGeoLocationsAnnotationsOSMInput: UpdateOneGeoLocationsAnnotationsOsmInput;
  UpdateOneGeoLocationsAnnotationsRoadinfoInput: UpdateOneGeoLocationsAnnotationsRoadinfoInput;
  UpdateOneGeoLocationsAnnotationsSunInput: UpdateOneGeoLocationsAnnotationsSunInput;
  UpdateOneGeoLocationsAnnotationsSunRiseInput: UpdateOneGeoLocationsAnnotationsSunRiseInput;
  UpdateOneGeoLocationsAnnotationsSunSetInput: UpdateOneGeoLocationsAnnotationsSunSetInput;
  UpdateOneGeoLocationsAnnotationsTimezoneInput: UpdateOneGeoLocationsAnnotationsTimezoneInput;
  UpdateOneGeoLocationsAnnotationsUN_M49Input: UpdateOneGeoLocationsAnnotationsUn_M49Input;
  UpdateOneGeoLocationsAnnotationsUN_M49RegionsInput: UpdateOneGeoLocationsAnnotationsUn_M49RegionsInput;
  UpdateOneGeoLocationsAnnotationsWhat3wordsInput: UpdateOneGeoLocationsAnnotationsWhat3wordsInput;
  UpdateOneGeoLocationsBoundsInput: UpdateOneGeoLocationsBoundsInput;
  UpdateOneGeoLocationsBoundsNortheastInput: UpdateOneGeoLocationsBoundsNortheastInput;
  UpdateOneGeoLocationsBoundsSouthwestInput: UpdateOneGeoLocationsBoundsSouthwestInput;
  UpdateOneGeoLocationsComponentsISOInput: UpdateOneGeoLocationsComponentsIsoInput;
  UpdateOneGeoLocationsComponentsISOV1Input: UpdateOneGeoLocationsComponentsIsov1Input;
  UpdateOneGeoLocationsComponentsInput: UpdateOneGeoLocationsComponentsInput;
  UpdateOneGeoLocationsGeometryInput: UpdateOneGeoLocationsGeometryInput;
  UpdateOneGeoLocationsInput: UpdateOneGeoLocationsInput;
  UpdateOneGeoLocationsPayload: ResolverTypeWrapper<UpdateOneGeoLocationsPayload>;
  UpdateOneGuidesInput: UpdateOneGuidesInput;
  UpdateOneGuidesPayload: ResolverTypeWrapper<UpdateOneGuidesPayload>;
  UpdateOnePhonesInput: UpdateOnePhonesInput;
  UpdateOnePhonesPayload: ResolverTypeWrapper<UpdateOnePhonesPayload>;
  UpdateOneSchedulesInput: UpdateOneSchedulesInput;
  UpdateOneSchedulesPayload: ResolverTypeWrapper<UpdateOneSchedulesPayload>;
  UpdateOneSchedulesRangesInput: UpdateOneSchedulesRangesInput;
  UpdateOneUsersInput: UpdateOneUsersInput;
  UpdateOneUsersPayload: ResolverTypeWrapper<UpdateOneUsersPayload>;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  UploadAsset: ResolverTypeWrapper<UploadAsset>;
  UploadAssetInput: UploadAssetInput;
  UserLocationLookup: ResolverTypeWrapper<UserLocationLookup>;
  UserLocationLookupCarrier: ResolverTypeWrapper<UserLocationLookupCarrier>;
  UserLocationLookupCompany: ResolverTypeWrapper<UserLocationLookupCompany>;
  UserLocationLookupCompanyType: UserLocationLookupCompanyType;
  UserLocationLookupConnection: ResolverTypeWrapper<UserLocationLookupConnection>;
  UserLocationLookupConnectionType: UserLocationLookupConnectionType;
  UserLocationLookupCurrency: ResolverTypeWrapper<UserLocationLookupCurrency>;
  UserLocationLookupCurrencyFormat: ResolverTypeWrapper<UserLocationLookupCurrencyFormat>;
  UserLocationLookupCurrencyFormatPrefixSuffix: ResolverTypeWrapper<UserLocationLookupCurrencyFormatPrefixSuffix>;
  UserLocationLookupInput: UserLocationLookupInput;
  UserLocationLookupLocation: ResolverTypeWrapper<UserLocationLookupLocation>;
  UserLocationLookupLocationContinent: ResolverTypeWrapper<UserLocationLookupLocationContinent>;
  UserLocationLookupLocationCountry: ResolverTypeWrapper<UserLocationLookupLocationCountry>;
  UserLocationLookupLocationCountryFlag: ResolverTypeWrapper<UserLocationLookupLocationCountryFlag>;
  UserLocationLookupLocationLanguage: ResolverTypeWrapper<UserLocationLookupLocationLanguage>;
  UserLocationLookupLocationRegion: ResolverTypeWrapper<UserLocationLookupLocationRegion>;
  UserLocationLookupSecurity: ResolverTypeWrapper<UserLocationLookupSecurity>;
  UserLocationLookupTimeZone: ResolverTypeWrapper<UserLocationLookupTimeZone>;
  UserLocationLookupType: UserLocationLookupType;
  Users: ResolverTypeWrapper<Users>;
  UsersPagination: ResolverTypeWrapper<UsersPagination>;
  UtcOffset: ResolverTypeWrapper<Scalars['UtcOffset']['output']>;
  Void: ResolverTypeWrapper<Scalars['Void']['output']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountNumber: Scalars['AccountNumber']['output'];
  Activate: Activate;
  Int: Scalars['Int']['output'];
  ActivateInput: ActivateInput;
  String: Scalars['String']['output'];
  Activities: Activities;
  ActivitiesDescription: ActivitiesDescription;
  ActivitiesDescriptionInput: ActivitiesDescriptionInput;
  ActivitiesPagination: ActivitiesPagination;
  AssetDataSource: AssetDataSource;
  AssetDataSourceValue: Scalars['AssetDataSourceValue']['output'];
  Assets: Assets;
  Float: Scalars['Float']['output'];
  AssetsPagination: AssetsPagination;
  BigInt: Scalars['BigInt']['output'];
  Bookings: Bookings;
  BookingsPagination: BookingsPagination;
  Byte: Scalars['Byte']['output'];
  ChatCompletionTokenLogprob: ChatCompletionTokenLogprob;
  Countries: Countries;
  CountriesData: CountriesData;
  CountryCode: Scalars['CountryCode']['output'];
  CreateManyActivitiesInput: CreateManyActivitiesInput;
  CreateManyActivitiesPayload: CreateManyActivitiesPayload;
  CreateManyAssetsInput: CreateManyAssetsInput;
  CreateManyAssetsPayload: CreateManyAssetsPayload;
  CreateManyBookingsInput: CreateManyBookingsInput;
  CreateManyBookingsPayload: CreateManyBookingsPayload;
  CreateManyGeoLocationsInput: CreateManyGeoLocationsInput;
  CreateManyGeoLocationsPayload: CreateManyGeoLocationsPayload;
  CreateManyGuidesInput: CreateManyGuidesInput;
  CreateManyGuidesPayload: CreateManyGuidesPayload;
  CreateManyPhonesInput: CreateManyPhonesInput;
  CreateManyPhonesPayload: CreateManyPhonesPayload;
  CreateManySchedulesInput: CreateManySchedulesInput;
  CreateManySchedulesPayload: CreateManySchedulesPayload;
  CreateManyUsersInput: CreateManyUsersInput;
  Boolean: Scalars['Boolean']['output'];
  CreateManyUsersPayload: CreateManyUsersPayload;
  CreateOneActivitiesInput: CreateOneActivitiesInput;
  CreateOneActivitiesPayload: CreateOneActivitiesPayload;
  CreateOneAssetsInput: CreateOneAssetsInput;
  CreateOneAssetsPayload: CreateOneAssetsPayload;
  CreateOneBookingsInput: CreateOneBookingsInput;
  CreateOneBookingsPayload: CreateOneBookingsPayload;
  CreateOneGeoLocationsInput: CreateOneGeoLocationsInput;
  CreateOneGeoLocationsPayload: CreateOneGeoLocationsPayload;
  CreateOneGuidesInput: CreateOneGuidesInput;
  CreateOneGuidesPayload: CreateOneGuidesPayload;
  CreateOnePhonesInput: CreateOnePhonesInput;
  CreateOnePhonesPayload: CreateOnePhonesPayload;
  CreateOneSchedulesInput: CreateOneSchedulesInput;
  CreateOneSchedulesPayload: CreateOneSchedulesPayload;
  CreateOneUsersInput: CreateOneUsersInput;
  CreateOneUsersPayload: CreateOneUsersPayload;
  Cuid: Scalars['Cuid']['output'];
  Currency: Scalars['Currency']['output'];
  DID: Scalars['DID']['output'];
  Date: Scalars['Date']['output'];
  DateTime: Scalars['DateTime']['output'];
  DateTimeISO: Scalars['DateTimeISO']['output'];
  DeweyDecimal: Scalars['DeweyDecimal']['output'];
  Duration: Scalars['Duration']['output'];
  EmailAddress: Scalars['EmailAddress']['output'];
  FilterFindManyActivitiesActivityGUIDOperatorsInput: FilterFindManyActivitiesActivityGuidOperatorsInput;
  FilterFindManyActivitiesDescriptionInput: FilterFindManyActivitiesDescriptionInput;
  FilterFindManyActivitiesInput: FilterFindManyActivitiesInput;
  FilterFindManyActivitiesOperatorsInput: FilterFindManyActivitiesOperatorsInput;
  FilterFindManyActivitiesTitleOperatorsInput: FilterFindManyActivitiesTitleOperatorsInput;
  FilterFindManyActivities_idOperatorsInput: FilterFindManyActivities_IdOperatorsInput;
  FilterFindManyAssetsAssetGUIDOperatorsInput: FilterFindManyAssetsAssetGuidOperatorsInput;
  FilterFindManyAssetsInput: FilterFindManyAssetsInput;
  FilterFindManyAssetsOperatorsInput: FilterFindManyAssetsOperatorsInput;
  FilterFindManyAssets_idOperatorsInput: FilterFindManyAssets_IdOperatorsInput;
  FilterFindManyBookingsActivityIDOperatorsInput: FilterFindManyBookingsActivityIdOperatorsInput;
  FilterFindManyBookingsBookingGUIDOperatorsInput: FilterFindManyBookingsBookingGuidOperatorsInput;
  FilterFindManyBookingsInput: FilterFindManyBookingsInput;
  FilterFindManyBookingsOperatorsInput: FilterFindManyBookingsOperatorsInput;
  FilterFindManyBookings_idOperatorsInput: FilterFindManyBookings_IdOperatorsInput;
  FilterFindManyCountriesCountryGUIDOperatorsInput: FilterFindManyCountriesCountryGuidOperatorsInput;
  FilterFindManyCountriesDataInput: FilterFindManyCountriesDataInput;
  FilterFindManyCountriesInput: FilterFindManyCountriesInput;
  FilterFindManyCountriesOperatorsInput: FilterFindManyCountriesOperatorsInput;
  FilterFindManyCountries_idOperatorsInput: FilterFindManyCountries_IdOperatorsInput;
  FilterFindManyGeoLocationsAnnotationsCurrencyInput: FilterFindManyGeoLocationsAnnotationsCurrencyInput;
  FilterFindManyGeoLocationsAnnotationsDMSInput: FilterFindManyGeoLocationsAnnotationsDmsInput;
  FilterFindManyGeoLocationsAnnotationsInput: FilterFindManyGeoLocationsAnnotationsInput;
  FilterFindManyGeoLocationsAnnotationsMercatorInput: FilterFindManyGeoLocationsAnnotationsMercatorInput;
  FilterFindManyGeoLocationsAnnotationsNUTSInput: FilterFindManyGeoLocationsAnnotationsNutsInput;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterFindManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterFindManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterFindManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterFindManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterFindManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterFindManyGeoLocationsAnnotationsOSMInput: FilterFindManyGeoLocationsAnnotationsOsmInput;
  FilterFindManyGeoLocationsAnnotationsRoadinfoInput: FilterFindManyGeoLocationsAnnotationsRoadinfoInput;
  FilterFindManyGeoLocationsAnnotationsSunInput: FilterFindManyGeoLocationsAnnotationsSunInput;
  FilterFindManyGeoLocationsAnnotationsSunRiseInput: FilterFindManyGeoLocationsAnnotationsSunRiseInput;
  FilterFindManyGeoLocationsAnnotationsSunSetInput: FilterFindManyGeoLocationsAnnotationsSunSetInput;
  FilterFindManyGeoLocationsAnnotationsTimezoneInput: FilterFindManyGeoLocationsAnnotationsTimezoneInput;
  FilterFindManyGeoLocationsAnnotationsUN_M49Input: FilterFindManyGeoLocationsAnnotationsUn_M49Input;
  FilterFindManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterFindManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterFindManyGeoLocationsAnnotationsWhat3wordsInput: FilterFindManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterFindManyGeoLocationsBoundsInput: FilterFindManyGeoLocationsBoundsInput;
  FilterFindManyGeoLocationsBoundsNortheastInput: FilterFindManyGeoLocationsBoundsNortheastInput;
  FilterFindManyGeoLocationsBoundsSouthwestInput: FilterFindManyGeoLocationsBoundsSouthwestInput;
  FilterFindManyGeoLocationsComponentsISOInput: FilterFindManyGeoLocationsComponentsIsoInput;
  FilterFindManyGeoLocationsComponentsISOV1Input: FilterFindManyGeoLocationsComponentsIsov1Input;
  FilterFindManyGeoLocationsComponentsInput: FilterFindManyGeoLocationsComponentsInput;
  FilterFindManyGeoLocationsGeometryInput: FilterFindManyGeoLocationsGeometryInput;
  FilterFindManyGeoLocationsInput: FilterFindManyGeoLocationsInput;
  FilterFindManyGeoLocationsOperatorsInput: FilterFindManyGeoLocationsOperatorsInput;
  FilterFindManyGeoLocations_idOperatorsInput: FilterFindManyGeoLocations_IdOperatorsInput;
  FilterFindManyGuidesGuideGUIDOperatorsInput: FilterFindManyGuidesGuideGuidOperatorsInput;
  FilterFindManyGuidesInput: FilterFindManyGuidesInput;
  FilterFindManyGuidesOperatorsInput: FilterFindManyGuidesOperatorsInput;
  FilterFindManyGuidesUserIDOperatorsInput: FilterFindManyGuidesUserIdOperatorsInput;
  FilterFindManyGuides_idOperatorsInput: FilterFindManyGuides_IdOperatorsInput;
  FilterFindManyPhonesCallingCodeOperatorsInput: FilterFindManyPhonesCallingCodeOperatorsInput;
  FilterFindManyPhonesCountryCodeOperatorsInput: FilterFindManyPhonesCountryCodeOperatorsInput;
  FilterFindManyPhonesInput: FilterFindManyPhonesInput;
  FilterFindManyPhonesNumberOperatorsInput: FilterFindManyPhonesNumberOperatorsInput;
  FilterFindManyPhonesOperatorsInput: FilterFindManyPhonesOperatorsInput;
  FilterFindManyPhonesPhoneGUIDOperatorsInput: FilterFindManyPhonesPhoneGuidOperatorsInput;
  FilterFindManyPhones_idOperatorsInput: FilterFindManyPhones_IdOperatorsInput;
  FilterFindManySchedulesInput: FilterFindManySchedulesInput;
  FilterFindManySchedulesOperatorsInput: FilterFindManySchedulesOperatorsInput;
  FilterFindManySchedulesRangesInput: FilterFindManySchedulesRangesInput;
  FilterFindManySchedules_idOperatorsInput: FilterFindManySchedules_IdOperatorsInput;
  FilterFindManyUsersActiveOperatorsInput: FilterFindManyUsersActiveOperatorsInput;
  FilterFindManyUsersFirstNameOperatorsInput: FilterFindManyUsersFirstNameOperatorsInput;
  FilterFindManyUsersInput: FilterFindManyUsersInput;
  FilterFindManyUsersLastNameOperatorsInput: FilterFindManyUsersLastNameOperatorsInput;
  FilterFindManyUsersOperatorsInput: FilterFindManyUsersOperatorsInput;
  FilterFindManyUsersUserGUIDOperatorsInput: FilterFindManyUsersUserGuidOperatorsInput;
  FilterFindManyUsersUserNameOperatorsInput: FilterFindManyUsersUserNameOperatorsInput;
  FilterFindManyUsers_idOperatorsInput: FilterFindManyUsers_IdOperatorsInput;
  FilterFindOneActivitiesActivityGUIDOperatorsInput: FilterFindOneActivitiesActivityGuidOperatorsInput;
  FilterFindOneActivitiesDescriptionInput: FilterFindOneActivitiesDescriptionInput;
  FilterFindOneActivitiesInput: FilterFindOneActivitiesInput;
  FilterFindOneActivitiesOperatorsInput: FilterFindOneActivitiesOperatorsInput;
  FilterFindOneActivitiesTitleOperatorsInput: FilterFindOneActivitiesTitleOperatorsInput;
  FilterFindOneActivities_idOperatorsInput: FilterFindOneActivities_IdOperatorsInput;
  FilterFindOneAssetsAssetGUIDOperatorsInput: FilterFindOneAssetsAssetGuidOperatorsInput;
  FilterFindOneAssetsInput: FilterFindOneAssetsInput;
  FilterFindOneAssetsOperatorsInput: FilterFindOneAssetsOperatorsInput;
  FilterFindOneAssets_idOperatorsInput: FilterFindOneAssets_IdOperatorsInput;
  FilterFindOneBookingsActivityIDOperatorsInput: FilterFindOneBookingsActivityIdOperatorsInput;
  FilterFindOneBookingsBookingGUIDOperatorsInput: FilterFindOneBookingsBookingGuidOperatorsInput;
  FilterFindOneBookingsInput: FilterFindOneBookingsInput;
  FilterFindOneBookingsOperatorsInput: FilterFindOneBookingsOperatorsInput;
  FilterFindOneBookings_idOperatorsInput: FilterFindOneBookings_IdOperatorsInput;
  FilterFindOneCountriesCountryGUIDOperatorsInput: FilterFindOneCountriesCountryGuidOperatorsInput;
  FilterFindOneCountriesDataInput: FilterFindOneCountriesDataInput;
  FilterFindOneCountriesInput: FilterFindOneCountriesInput;
  FilterFindOneCountriesOperatorsInput: FilterFindOneCountriesOperatorsInput;
  FilterFindOneCountries_idOperatorsInput: FilterFindOneCountries_IdOperatorsInput;
  FilterFindOneGeoLocationsAnnotationsCurrencyInput: FilterFindOneGeoLocationsAnnotationsCurrencyInput;
  FilterFindOneGeoLocationsAnnotationsDMSInput: FilterFindOneGeoLocationsAnnotationsDmsInput;
  FilterFindOneGeoLocationsAnnotationsInput: FilterFindOneGeoLocationsAnnotationsInput;
  FilterFindOneGeoLocationsAnnotationsMercatorInput: FilterFindOneGeoLocationsAnnotationsMercatorInput;
  FilterFindOneGeoLocationsAnnotationsNUTSInput: FilterFindOneGeoLocationsAnnotationsNutsInput;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterFindOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterFindOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterFindOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterFindOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterFindOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterFindOneGeoLocationsAnnotationsOSMInput: FilterFindOneGeoLocationsAnnotationsOsmInput;
  FilterFindOneGeoLocationsAnnotationsRoadinfoInput: FilterFindOneGeoLocationsAnnotationsRoadinfoInput;
  FilterFindOneGeoLocationsAnnotationsSunInput: FilterFindOneGeoLocationsAnnotationsSunInput;
  FilterFindOneGeoLocationsAnnotationsSunRiseInput: FilterFindOneGeoLocationsAnnotationsSunRiseInput;
  FilterFindOneGeoLocationsAnnotationsSunSetInput: FilterFindOneGeoLocationsAnnotationsSunSetInput;
  FilterFindOneGeoLocationsAnnotationsTimezoneInput: FilterFindOneGeoLocationsAnnotationsTimezoneInput;
  FilterFindOneGeoLocationsAnnotationsUN_M49Input: FilterFindOneGeoLocationsAnnotationsUn_M49Input;
  FilterFindOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterFindOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterFindOneGeoLocationsAnnotationsWhat3wordsInput: FilterFindOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterFindOneGeoLocationsBoundsInput: FilterFindOneGeoLocationsBoundsInput;
  FilterFindOneGeoLocationsBoundsNortheastInput: FilterFindOneGeoLocationsBoundsNortheastInput;
  FilterFindOneGeoLocationsBoundsSouthwestInput: FilterFindOneGeoLocationsBoundsSouthwestInput;
  FilterFindOneGeoLocationsComponentsISOInput: FilterFindOneGeoLocationsComponentsIsoInput;
  FilterFindOneGeoLocationsComponentsISOV1Input: FilterFindOneGeoLocationsComponentsIsov1Input;
  FilterFindOneGeoLocationsComponentsInput: FilterFindOneGeoLocationsComponentsInput;
  FilterFindOneGeoLocationsGeometryInput: FilterFindOneGeoLocationsGeometryInput;
  FilterFindOneGeoLocationsInput: FilterFindOneGeoLocationsInput;
  FilterFindOneGeoLocationsOperatorsInput: FilterFindOneGeoLocationsOperatorsInput;
  FilterFindOneGeoLocations_idOperatorsInput: FilterFindOneGeoLocations_IdOperatorsInput;
  FilterFindOneGuidesGuideGUIDOperatorsInput: FilterFindOneGuidesGuideGuidOperatorsInput;
  FilterFindOneGuidesInput: FilterFindOneGuidesInput;
  FilterFindOneGuidesOperatorsInput: FilterFindOneGuidesOperatorsInput;
  FilterFindOneGuidesUserIDOperatorsInput: FilterFindOneGuidesUserIdOperatorsInput;
  FilterFindOneGuides_idOperatorsInput: FilterFindOneGuides_IdOperatorsInput;
  FilterFindOnePhonesCallingCodeOperatorsInput: FilterFindOnePhonesCallingCodeOperatorsInput;
  FilterFindOnePhonesCountryCodeOperatorsInput: FilterFindOnePhonesCountryCodeOperatorsInput;
  FilterFindOnePhonesInput: FilterFindOnePhonesInput;
  FilterFindOnePhonesNumberOperatorsInput: FilterFindOnePhonesNumberOperatorsInput;
  FilterFindOnePhonesOperatorsInput: FilterFindOnePhonesOperatorsInput;
  FilterFindOnePhonesPhoneGUIDOperatorsInput: FilterFindOnePhonesPhoneGuidOperatorsInput;
  FilterFindOnePhones_idOperatorsInput: FilterFindOnePhones_IdOperatorsInput;
  FilterFindOneSchedulesInput: FilterFindOneSchedulesInput;
  FilterFindOneSchedulesOperatorsInput: FilterFindOneSchedulesOperatorsInput;
  FilterFindOneSchedulesRangesInput: FilterFindOneSchedulesRangesInput;
  FilterFindOneSchedules_idOperatorsInput: FilterFindOneSchedules_IdOperatorsInput;
  FilterFindOneUsersActiveOperatorsInput: FilterFindOneUsersActiveOperatorsInput;
  FilterFindOneUsersFirstNameOperatorsInput: FilterFindOneUsersFirstNameOperatorsInput;
  FilterFindOneUsersInput: FilterFindOneUsersInput;
  FilterFindOneUsersLastNameOperatorsInput: FilterFindOneUsersLastNameOperatorsInput;
  FilterFindOneUsersOperatorsInput: FilterFindOneUsersOperatorsInput;
  FilterFindOneUsersUserGUIDOperatorsInput: FilterFindOneUsersUserGuidOperatorsInput;
  FilterFindOneUsersUserNameOperatorsInput: FilterFindOneUsersUserNameOperatorsInput;
  FilterFindOneUsers_idOperatorsInput: FilterFindOneUsers_IdOperatorsInput;
  FilterRemoveManyActivitiesActivityGUIDOperatorsInput: FilterRemoveManyActivitiesActivityGuidOperatorsInput;
  FilterRemoveManyActivitiesDescriptionInput: FilterRemoveManyActivitiesDescriptionInput;
  FilterRemoveManyActivitiesInput: FilterRemoveManyActivitiesInput;
  FilterRemoveManyActivitiesOperatorsInput: FilterRemoveManyActivitiesOperatorsInput;
  FilterRemoveManyActivitiesTitleOperatorsInput: FilterRemoveManyActivitiesTitleOperatorsInput;
  FilterRemoveManyActivities_idOperatorsInput: FilterRemoveManyActivities_IdOperatorsInput;
  FilterRemoveManyAssetsAssetGUIDOperatorsInput: FilterRemoveManyAssetsAssetGuidOperatorsInput;
  FilterRemoveManyAssetsInput: FilterRemoveManyAssetsInput;
  FilterRemoveManyAssetsOperatorsInput: FilterRemoveManyAssetsOperatorsInput;
  FilterRemoveManyAssets_idOperatorsInput: FilterRemoveManyAssets_IdOperatorsInput;
  FilterRemoveManyBookingsActivityIDOperatorsInput: FilterRemoveManyBookingsActivityIdOperatorsInput;
  FilterRemoveManyBookingsBookingGUIDOperatorsInput: FilterRemoveManyBookingsBookingGuidOperatorsInput;
  FilterRemoveManyBookingsInput: FilterRemoveManyBookingsInput;
  FilterRemoveManyBookingsOperatorsInput: FilterRemoveManyBookingsOperatorsInput;
  FilterRemoveManyBookings_idOperatorsInput: FilterRemoveManyBookings_IdOperatorsInput;
  FilterRemoveManyGeoLocationsAnnotationsCurrencyInput: FilterRemoveManyGeoLocationsAnnotationsCurrencyInput;
  FilterRemoveManyGeoLocationsAnnotationsDMSInput: FilterRemoveManyGeoLocationsAnnotationsDmsInput;
  FilterRemoveManyGeoLocationsAnnotationsInput: FilterRemoveManyGeoLocationsAnnotationsInput;
  FilterRemoveManyGeoLocationsAnnotationsMercatorInput: FilterRemoveManyGeoLocationsAnnotationsMercatorInput;
  FilterRemoveManyGeoLocationsAnnotationsNUTSInput: FilterRemoveManyGeoLocationsAnnotationsNutsInput;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterRemoveManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterRemoveManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterRemoveManyGeoLocationsAnnotationsOSMInput: FilterRemoveManyGeoLocationsAnnotationsOsmInput;
  FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput: FilterRemoveManyGeoLocationsAnnotationsRoadinfoInput;
  FilterRemoveManyGeoLocationsAnnotationsSunInput: FilterRemoveManyGeoLocationsAnnotationsSunInput;
  FilterRemoveManyGeoLocationsAnnotationsSunRiseInput: FilterRemoveManyGeoLocationsAnnotationsSunRiseInput;
  FilterRemoveManyGeoLocationsAnnotationsSunSetInput: FilterRemoveManyGeoLocationsAnnotationsSunSetInput;
  FilterRemoveManyGeoLocationsAnnotationsTimezoneInput: FilterRemoveManyGeoLocationsAnnotationsTimezoneInput;
  FilterRemoveManyGeoLocationsAnnotationsUN_M49Input: FilterRemoveManyGeoLocationsAnnotationsUn_M49Input;
  FilterRemoveManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterRemoveManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput: FilterRemoveManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterRemoveManyGeoLocationsBoundsInput: FilterRemoveManyGeoLocationsBoundsInput;
  FilterRemoveManyGeoLocationsBoundsNortheastInput: FilterRemoveManyGeoLocationsBoundsNortheastInput;
  FilterRemoveManyGeoLocationsBoundsSouthwestInput: FilterRemoveManyGeoLocationsBoundsSouthwestInput;
  FilterRemoveManyGeoLocationsComponentsISOInput: FilterRemoveManyGeoLocationsComponentsIsoInput;
  FilterRemoveManyGeoLocationsComponentsISOV1Input: FilterRemoveManyGeoLocationsComponentsIsov1Input;
  FilterRemoveManyGeoLocationsComponentsInput: FilterRemoveManyGeoLocationsComponentsInput;
  FilterRemoveManyGeoLocationsGeometryInput: FilterRemoveManyGeoLocationsGeometryInput;
  FilterRemoveManyGeoLocationsInput: FilterRemoveManyGeoLocationsInput;
  FilterRemoveManyGeoLocationsOperatorsInput: FilterRemoveManyGeoLocationsOperatorsInput;
  FilterRemoveManyGeoLocations_idOperatorsInput: FilterRemoveManyGeoLocations_IdOperatorsInput;
  FilterRemoveManyGuidesGuideGUIDOperatorsInput: FilterRemoveManyGuidesGuideGuidOperatorsInput;
  FilterRemoveManyGuidesInput: FilterRemoveManyGuidesInput;
  FilterRemoveManyGuidesOperatorsInput: FilterRemoveManyGuidesOperatorsInput;
  FilterRemoveManyGuidesUserIDOperatorsInput: FilterRemoveManyGuidesUserIdOperatorsInput;
  FilterRemoveManyGuides_idOperatorsInput: FilterRemoveManyGuides_IdOperatorsInput;
  FilterRemoveManyPhonesCallingCodeOperatorsInput: FilterRemoveManyPhonesCallingCodeOperatorsInput;
  FilterRemoveManyPhonesCountryCodeOperatorsInput: FilterRemoveManyPhonesCountryCodeOperatorsInput;
  FilterRemoveManyPhonesInput: FilterRemoveManyPhonesInput;
  FilterRemoveManyPhonesNumberOperatorsInput: FilterRemoveManyPhonesNumberOperatorsInput;
  FilterRemoveManyPhonesOperatorsInput: FilterRemoveManyPhonesOperatorsInput;
  FilterRemoveManyPhonesPhoneGUIDOperatorsInput: FilterRemoveManyPhonesPhoneGuidOperatorsInput;
  FilterRemoveManyPhones_idOperatorsInput: FilterRemoveManyPhones_IdOperatorsInput;
  FilterRemoveManySchedulesInput: FilterRemoveManySchedulesInput;
  FilterRemoveManySchedulesOperatorsInput: FilterRemoveManySchedulesOperatorsInput;
  FilterRemoveManySchedulesRangesInput: FilterRemoveManySchedulesRangesInput;
  FilterRemoveManySchedules_idOperatorsInput: FilterRemoveManySchedules_IdOperatorsInput;
  FilterRemoveManyUsersActiveOperatorsInput: FilterRemoveManyUsersActiveOperatorsInput;
  FilterRemoveManyUsersBirthdayOperatorsInput: FilterRemoveManyUsersBirthdayOperatorsInput;
  FilterRemoveManyUsersEmailOperatorsInput: FilterRemoveManyUsersEmailOperatorsInput;
  FilterRemoveManyUsersFirstNameOperatorsInput: FilterRemoveManyUsersFirstNameOperatorsInput;
  FilterRemoveManyUsersGenderOperatorsInput: FilterRemoveManyUsersGenderOperatorsInput;
  FilterRemoveManyUsersInput: FilterRemoveManyUsersInput;
  FilterRemoveManyUsersLastNameOperatorsInput: FilterRemoveManyUsersLastNameOperatorsInput;
  FilterRemoveManyUsersOperatorsInput: FilterRemoveManyUsersOperatorsInput;
  FilterRemoveManyUsersPhoneIDOperatorsInput: FilterRemoveManyUsersPhoneIdOperatorsInput;
  FilterRemoveManyUsersSocialProvidersOperatorsInput: FilterRemoveManyUsersSocialProvidersOperatorsInput;
  FilterRemoveManyUsersUserGUIDOperatorsInput: FilterRemoveManyUsersUserGuidOperatorsInput;
  FilterRemoveManyUsersUserNameOperatorsInput: FilterRemoveManyUsersUserNameOperatorsInput;
  FilterRemoveManyUsers_idOperatorsInput: FilterRemoveManyUsers_IdOperatorsInput;
  FilterRemoveOneActivitiesActivityGUIDOperatorsInput: FilterRemoveOneActivitiesActivityGuidOperatorsInput;
  FilterRemoveOneActivitiesDescriptionInput: FilterRemoveOneActivitiesDescriptionInput;
  FilterRemoveOneActivitiesInput: FilterRemoveOneActivitiesInput;
  FilterRemoveOneActivitiesOperatorsInput: FilterRemoveOneActivitiesOperatorsInput;
  FilterRemoveOneActivitiesTitleOperatorsInput: FilterRemoveOneActivitiesTitleOperatorsInput;
  FilterRemoveOneActivities_idOperatorsInput: FilterRemoveOneActivities_IdOperatorsInput;
  FilterRemoveOneAssetsAssetGUIDOperatorsInput: FilterRemoveOneAssetsAssetGuidOperatorsInput;
  FilterRemoveOneAssetsInput: FilterRemoveOneAssetsInput;
  FilterRemoveOneAssetsOperatorsInput: FilterRemoveOneAssetsOperatorsInput;
  FilterRemoveOneAssets_idOperatorsInput: FilterRemoveOneAssets_IdOperatorsInput;
  FilterRemoveOneBookingsActivityIDOperatorsInput: FilterRemoveOneBookingsActivityIdOperatorsInput;
  FilterRemoveOneBookingsBookingGUIDOperatorsInput: FilterRemoveOneBookingsBookingGuidOperatorsInput;
  FilterRemoveOneBookingsInput: FilterRemoveOneBookingsInput;
  FilterRemoveOneBookingsOperatorsInput: FilterRemoveOneBookingsOperatorsInput;
  FilterRemoveOneBookings_idOperatorsInput: FilterRemoveOneBookings_IdOperatorsInput;
  FilterRemoveOneGeoLocationsAnnotationsCurrencyInput: FilterRemoveOneGeoLocationsAnnotationsCurrencyInput;
  FilterRemoveOneGeoLocationsAnnotationsDMSInput: FilterRemoveOneGeoLocationsAnnotationsDmsInput;
  FilterRemoveOneGeoLocationsAnnotationsInput: FilterRemoveOneGeoLocationsAnnotationsInput;
  FilterRemoveOneGeoLocationsAnnotationsMercatorInput: FilterRemoveOneGeoLocationsAnnotationsMercatorInput;
  FilterRemoveOneGeoLocationsAnnotationsNUTSInput: FilterRemoveOneGeoLocationsAnnotationsNutsInput;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterRemoveOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterRemoveOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterRemoveOneGeoLocationsAnnotationsOSMInput: FilterRemoveOneGeoLocationsAnnotationsOsmInput;
  FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput: FilterRemoveOneGeoLocationsAnnotationsRoadinfoInput;
  FilterRemoveOneGeoLocationsAnnotationsSunInput: FilterRemoveOneGeoLocationsAnnotationsSunInput;
  FilterRemoveOneGeoLocationsAnnotationsSunRiseInput: FilterRemoveOneGeoLocationsAnnotationsSunRiseInput;
  FilterRemoveOneGeoLocationsAnnotationsSunSetInput: FilterRemoveOneGeoLocationsAnnotationsSunSetInput;
  FilterRemoveOneGeoLocationsAnnotationsTimezoneInput: FilterRemoveOneGeoLocationsAnnotationsTimezoneInput;
  FilterRemoveOneGeoLocationsAnnotationsUN_M49Input: FilterRemoveOneGeoLocationsAnnotationsUn_M49Input;
  FilterRemoveOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterRemoveOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput: FilterRemoveOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterRemoveOneGeoLocationsBoundsInput: FilterRemoveOneGeoLocationsBoundsInput;
  FilterRemoveOneGeoLocationsBoundsNortheastInput: FilterRemoveOneGeoLocationsBoundsNortheastInput;
  FilterRemoveOneGeoLocationsBoundsSouthwestInput: FilterRemoveOneGeoLocationsBoundsSouthwestInput;
  FilterRemoveOneGeoLocationsComponentsISOInput: FilterRemoveOneGeoLocationsComponentsIsoInput;
  FilterRemoveOneGeoLocationsComponentsISOV1Input: FilterRemoveOneGeoLocationsComponentsIsov1Input;
  FilterRemoveOneGeoLocationsComponentsInput: FilterRemoveOneGeoLocationsComponentsInput;
  FilterRemoveOneGeoLocationsGeometryInput: FilterRemoveOneGeoLocationsGeometryInput;
  FilterRemoveOneGeoLocationsInput: FilterRemoveOneGeoLocationsInput;
  FilterRemoveOneGeoLocationsOperatorsInput: FilterRemoveOneGeoLocationsOperatorsInput;
  FilterRemoveOneGeoLocations_idOperatorsInput: FilterRemoveOneGeoLocations_IdOperatorsInput;
  FilterRemoveOneGuidesGuideGUIDOperatorsInput: FilterRemoveOneGuidesGuideGuidOperatorsInput;
  FilterRemoveOneGuidesInput: FilterRemoveOneGuidesInput;
  FilterRemoveOneGuidesOperatorsInput: FilterRemoveOneGuidesOperatorsInput;
  FilterRemoveOneGuidesUserIDOperatorsInput: FilterRemoveOneGuidesUserIdOperatorsInput;
  FilterRemoveOneGuides_idOperatorsInput: FilterRemoveOneGuides_IdOperatorsInput;
  FilterRemoveOnePhonesCallingCodeOperatorsInput: FilterRemoveOnePhonesCallingCodeOperatorsInput;
  FilterRemoveOnePhonesCountryCodeOperatorsInput: FilterRemoveOnePhonesCountryCodeOperatorsInput;
  FilterRemoveOnePhonesInput: FilterRemoveOnePhonesInput;
  FilterRemoveOnePhonesNumberOperatorsInput: FilterRemoveOnePhonesNumberOperatorsInput;
  FilterRemoveOnePhonesOperatorsInput: FilterRemoveOnePhonesOperatorsInput;
  FilterRemoveOnePhonesPhoneGUIDOperatorsInput: FilterRemoveOnePhonesPhoneGuidOperatorsInput;
  FilterRemoveOnePhones_idOperatorsInput: FilterRemoveOnePhones_IdOperatorsInput;
  FilterRemoveOneSchedulesInput: FilterRemoveOneSchedulesInput;
  FilterRemoveOneSchedulesOperatorsInput: FilterRemoveOneSchedulesOperatorsInput;
  FilterRemoveOneSchedulesRangesInput: FilterRemoveOneSchedulesRangesInput;
  FilterRemoveOneSchedules_idOperatorsInput: FilterRemoveOneSchedules_IdOperatorsInput;
  FilterRemoveOneUsersActiveOperatorsInput: FilterRemoveOneUsersActiveOperatorsInput;
  FilterRemoveOneUsersBirthdayOperatorsInput: FilterRemoveOneUsersBirthdayOperatorsInput;
  FilterRemoveOneUsersEmailOperatorsInput: FilterRemoveOneUsersEmailOperatorsInput;
  FilterRemoveOneUsersFirstNameOperatorsInput: FilterRemoveOneUsersFirstNameOperatorsInput;
  FilterRemoveOneUsersGenderOperatorsInput: FilterRemoveOneUsersGenderOperatorsInput;
  FilterRemoveOneUsersInput: FilterRemoveOneUsersInput;
  FilterRemoveOneUsersLastNameOperatorsInput: FilterRemoveOneUsersLastNameOperatorsInput;
  FilterRemoveOneUsersOperatorsInput: FilterRemoveOneUsersOperatorsInput;
  FilterRemoveOneUsersPhoneIDOperatorsInput: FilterRemoveOneUsersPhoneIdOperatorsInput;
  FilterRemoveOneUsersSocialProvidersOperatorsInput: FilterRemoveOneUsersSocialProvidersOperatorsInput;
  FilterRemoveOneUsersUserGUIDOperatorsInput: FilterRemoveOneUsersUserGuidOperatorsInput;
  FilterRemoveOneUsersUserNameOperatorsInput: FilterRemoveOneUsersUserNameOperatorsInput;
  FilterRemoveOneUsers_idOperatorsInput: FilterRemoveOneUsers_IdOperatorsInput;
  FilterUpdateManyActivitiesActivityGUIDOperatorsInput: FilterUpdateManyActivitiesActivityGuidOperatorsInput;
  FilterUpdateManyActivitiesDescriptionInput: FilterUpdateManyActivitiesDescriptionInput;
  FilterUpdateManyActivitiesInput: FilterUpdateManyActivitiesInput;
  FilterUpdateManyActivitiesOperatorsInput: FilterUpdateManyActivitiesOperatorsInput;
  FilterUpdateManyActivitiesTitleOperatorsInput: FilterUpdateManyActivitiesTitleOperatorsInput;
  FilterUpdateManyActivities_idOperatorsInput: FilterUpdateManyActivities_IdOperatorsInput;
  FilterUpdateManyAssetsAssetGUIDOperatorsInput: FilterUpdateManyAssetsAssetGuidOperatorsInput;
  FilterUpdateManyAssetsInput: FilterUpdateManyAssetsInput;
  FilterUpdateManyAssetsOperatorsInput: FilterUpdateManyAssetsOperatorsInput;
  FilterUpdateManyAssets_idOperatorsInput: FilterUpdateManyAssets_IdOperatorsInput;
  FilterUpdateManyBookingsActivityIDOperatorsInput: FilterUpdateManyBookingsActivityIdOperatorsInput;
  FilterUpdateManyBookingsBookingGUIDOperatorsInput: FilterUpdateManyBookingsBookingGuidOperatorsInput;
  FilterUpdateManyBookingsInput: FilterUpdateManyBookingsInput;
  FilterUpdateManyBookingsOperatorsInput: FilterUpdateManyBookingsOperatorsInput;
  FilterUpdateManyBookings_idOperatorsInput: FilterUpdateManyBookings_IdOperatorsInput;
  FilterUpdateManyGeoLocationsAnnotationsCurrencyInput: FilterUpdateManyGeoLocationsAnnotationsCurrencyInput;
  FilterUpdateManyGeoLocationsAnnotationsDMSInput: FilterUpdateManyGeoLocationsAnnotationsDmsInput;
  FilterUpdateManyGeoLocationsAnnotationsInput: FilterUpdateManyGeoLocationsAnnotationsInput;
  FilterUpdateManyGeoLocationsAnnotationsMercatorInput: FilterUpdateManyGeoLocationsAnnotationsMercatorInput;
  FilterUpdateManyGeoLocationsAnnotationsNUTSInput: FilterUpdateManyGeoLocationsAnnotationsNutsInput;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS0Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts0Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS1Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts1Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS2Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts2Input;
  FilterUpdateManyGeoLocationsAnnotationsNUTSNUTS3Input: FilterUpdateManyGeoLocationsAnnotationsNutsnuts3Input;
  FilterUpdateManyGeoLocationsAnnotationsOSMInput: FilterUpdateManyGeoLocationsAnnotationsOsmInput;
  FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput: FilterUpdateManyGeoLocationsAnnotationsRoadinfoInput;
  FilterUpdateManyGeoLocationsAnnotationsSunInput: FilterUpdateManyGeoLocationsAnnotationsSunInput;
  FilterUpdateManyGeoLocationsAnnotationsSunRiseInput: FilterUpdateManyGeoLocationsAnnotationsSunRiseInput;
  FilterUpdateManyGeoLocationsAnnotationsSunSetInput: FilterUpdateManyGeoLocationsAnnotationsSunSetInput;
  FilterUpdateManyGeoLocationsAnnotationsTimezoneInput: FilterUpdateManyGeoLocationsAnnotationsTimezoneInput;
  FilterUpdateManyGeoLocationsAnnotationsUN_M49Input: FilterUpdateManyGeoLocationsAnnotationsUn_M49Input;
  FilterUpdateManyGeoLocationsAnnotationsUN_M49RegionsInput: FilterUpdateManyGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput: FilterUpdateManyGeoLocationsAnnotationsWhat3wordsInput;
  FilterUpdateManyGeoLocationsBoundsInput: FilterUpdateManyGeoLocationsBoundsInput;
  FilterUpdateManyGeoLocationsBoundsNortheastInput: FilterUpdateManyGeoLocationsBoundsNortheastInput;
  FilterUpdateManyGeoLocationsBoundsSouthwestInput: FilterUpdateManyGeoLocationsBoundsSouthwestInput;
  FilterUpdateManyGeoLocationsComponentsISOInput: FilterUpdateManyGeoLocationsComponentsIsoInput;
  FilterUpdateManyGeoLocationsComponentsISOV1Input: FilterUpdateManyGeoLocationsComponentsIsov1Input;
  FilterUpdateManyGeoLocationsComponentsInput: FilterUpdateManyGeoLocationsComponentsInput;
  FilterUpdateManyGeoLocationsGeometryInput: FilterUpdateManyGeoLocationsGeometryInput;
  FilterUpdateManyGeoLocationsInput: FilterUpdateManyGeoLocationsInput;
  FilterUpdateManyGeoLocationsOperatorsInput: FilterUpdateManyGeoLocationsOperatorsInput;
  FilterUpdateManyGeoLocations_idOperatorsInput: FilterUpdateManyGeoLocations_IdOperatorsInput;
  FilterUpdateManyGuidesGuideGUIDOperatorsInput: FilterUpdateManyGuidesGuideGuidOperatorsInput;
  FilterUpdateManyGuidesInput: FilterUpdateManyGuidesInput;
  FilterUpdateManyGuidesOperatorsInput: FilterUpdateManyGuidesOperatorsInput;
  FilterUpdateManyGuidesUserIDOperatorsInput: FilterUpdateManyGuidesUserIdOperatorsInput;
  FilterUpdateManyGuides_idOperatorsInput: FilterUpdateManyGuides_IdOperatorsInput;
  FilterUpdateManyPhonesCallingCodeOperatorsInput: FilterUpdateManyPhonesCallingCodeOperatorsInput;
  FilterUpdateManyPhonesCountryCodeOperatorsInput: FilterUpdateManyPhonesCountryCodeOperatorsInput;
  FilterUpdateManyPhonesInput: FilterUpdateManyPhonesInput;
  FilterUpdateManyPhonesNumberOperatorsInput: FilterUpdateManyPhonesNumberOperatorsInput;
  FilterUpdateManyPhonesOperatorsInput: FilterUpdateManyPhonesOperatorsInput;
  FilterUpdateManyPhonesPhoneGUIDOperatorsInput: FilterUpdateManyPhonesPhoneGuidOperatorsInput;
  FilterUpdateManyPhones_idOperatorsInput: FilterUpdateManyPhones_IdOperatorsInput;
  FilterUpdateManySchedulesInput: FilterUpdateManySchedulesInput;
  FilterUpdateManySchedulesOperatorsInput: FilterUpdateManySchedulesOperatorsInput;
  FilterUpdateManySchedulesRangesInput: FilterUpdateManySchedulesRangesInput;
  FilterUpdateManySchedules_idOperatorsInput: FilterUpdateManySchedules_IdOperatorsInput;
  FilterUpdateManyUsersActiveOperatorsInput: FilterUpdateManyUsersActiveOperatorsInput;
  FilterUpdateManyUsersBirthdayOperatorsInput: FilterUpdateManyUsersBirthdayOperatorsInput;
  FilterUpdateManyUsersEmailOperatorsInput: FilterUpdateManyUsersEmailOperatorsInput;
  FilterUpdateManyUsersFirstNameOperatorsInput: FilterUpdateManyUsersFirstNameOperatorsInput;
  FilterUpdateManyUsersGenderOperatorsInput: FilterUpdateManyUsersGenderOperatorsInput;
  FilterUpdateManyUsersInput: FilterUpdateManyUsersInput;
  FilterUpdateManyUsersLastNameOperatorsInput: FilterUpdateManyUsersLastNameOperatorsInput;
  FilterUpdateManyUsersOperatorsInput: FilterUpdateManyUsersOperatorsInput;
  FilterUpdateManyUsersPhoneIDOperatorsInput: FilterUpdateManyUsersPhoneIdOperatorsInput;
  FilterUpdateManyUsersSocialProvidersOperatorsInput: FilterUpdateManyUsersSocialProvidersOperatorsInput;
  FilterUpdateManyUsersUserGUIDOperatorsInput: FilterUpdateManyUsersUserGuidOperatorsInput;
  FilterUpdateManyUsersUserNameOperatorsInput: FilterUpdateManyUsersUserNameOperatorsInput;
  FilterUpdateManyUsers_idOperatorsInput: FilterUpdateManyUsers_IdOperatorsInput;
  FilterUpdateOneActivitiesActivityGUIDOperatorsInput: FilterUpdateOneActivitiesActivityGuidOperatorsInput;
  FilterUpdateOneActivitiesDescriptionInput: FilterUpdateOneActivitiesDescriptionInput;
  FilterUpdateOneActivitiesInput: FilterUpdateOneActivitiesInput;
  FilterUpdateOneActivitiesOperatorsInput: FilterUpdateOneActivitiesOperatorsInput;
  FilterUpdateOneActivitiesTitleOperatorsInput: FilterUpdateOneActivitiesTitleOperatorsInput;
  FilterUpdateOneActivities_idOperatorsInput: FilterUpdateOneActivities_IdOperatorsInput;
  FilterUpdateOneAssetsAssetGUIDOperatorsInput: FilterUpdateOneAssetsAssetGuidOperatorsInput;
  FilterUpdateOneAssetsInput: FilterUpdateOneAssetsInput;
  FilterUpdateOneAssetsOperatorsInput: FilterUpdateOneAssetsOperatorsInput;
  FilterUpdateOneAssets_idOperatorsInput: FilterUpdateOneAssets_IdOperatorsInput;
  FilterUpdateOneBookingsActivityIDOperatorsInput: FilterUpdateOneBookingsActivityIdOperatorsInput;
  FilterUpdateOneBookingsBookingGUIDOperatorsInput: FilterUpdateOneBookingsBookingGuidOperatorsInput;
  FilterUpdateOneBookingsInput: FilterUpdateOneBookingsInput;
  FilterUpdateOneBookingsOperatorsInput: FilterUpdateOneBookingsOperatorsInput;
  FilterUpdateOneBookings_idOperatorsInput: FilterUpdateOneBookings_IdOperatorsInput;
  FilterUpdateOneGeoLocationsAnnotationsCurrencyInput: FilterUpdateOneGeoLocationsAnnotationsCurrencyInput;
  FilterUpdateOneGeoLocationsAnnotationsDMSInput: FilterUpdateOneGeoLocationsAnnotationsDmsInput;
  FilterUpdateOneGeoLocationsAnnotationsInput: FilterUpdateOneGeoLocationsAnnotationsInput;
  FilterUpdateOneGeoLocationsAnnotationsMercatorInput: FilterUpdateOneGeoLocationsAnnotationsMercatorInput;
  FilterUpdateOneGeoLocationsAnnotationsNUTSInput: FilterUpdateOneGeoLocationsAnnotationsNutsInput;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS0Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts0Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS1Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts1Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS2Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts2Input;
  FilterUpdateOneGeoLocationsAnnotationsNUTSNUTS3Input: FilterUpdateOneGeoLocationsAnnotationsNutsnuts3Input;
  FilterUpdateOneGeoLocationsAnnotationsOSMInput: FilterUpdateOneGeoLocationsAnnotationsOsmInput;
  FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput: FilterUpdateOneGeoLocationsAnnotationsRoadinfoInput;
  FilterUpdateOneGeoLocationsAnnotationsSunInput: FilterUpdateOneGeoLocationsAnnotationsSunInput;
  FilterUpdateOneGeoLocationsAnnotationsSunRiseInput: FilterUpdateOneGeoLocationsAnnotationsSunRiseInput;
  FilterUpdateOneGeoLocationsAnnotationsSunSetInput: FilterUpdateOneGeoLocationsAnnotationsSunSetInput;
  FilterUpdateOneGeoLocationsAnnotationsTimezoneInput: FilterUpdateOneGeoLocationsAnnotationsTimezoneInput;
  FilterUpdateOneGeoLocationsAnnotationsUN_M49Input: FilterUpdateOneGeoLocationsAnnotationsUn_M49Input;
  FilterUpdateOneGeoLocationsAnnotationsUN_M49RegionsInput: FilterUpdateOneGeoLocationsAnnotationsUn_M49RegionsInput;
  FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput: FilterUpdateOneGeoLocationsAnnotationsWhat3wordsInput;
  FilterUpdateOneGeoLocationsBoundsInput: FilterUpdateOneGeoLocationsBoundsInput;
  FilterUpdateOneGeoLocationsBoundsNortheastInput: FilterUpdateOneGeoLocationsBoundsNortheastInput;
  FilterUpdateOneGeoLocationsBoundsSouthwestInput: FilterUpdateOneGeoLocationsBoundsSouthwestInput;
  FilterUpdateOneGeoLocationsComponentsISOInput: FilterUpdateOneGeoLocationsComponentsIsoInput;
  FilterUpdateOneGeoLocationsComponentsISOV1Input: FilterUpdateOneGeoLocationsComponentsIsov1Input;
  FilterUpdateOneGeoLocationsComponentsInput: FilterUpdateOneGeoLocationsComponentsInput;
  FilterUpdateOneGeoLocationsGeometryInput: FilterUpdateOneGeoLocationsGeometryInput;
  FilterUpdateOneGeoLocationsInput: FilterUpdateOneGeoLocationsInput;
  FilterUpdateOneGeoLocationsOperatorsInput: FilterUpdateOneGeoLocationsOperatorsInput;
  FilterUpdateOneGeoLocations_idOperatorsInput: FilterUpdateOneGeoLocations_IdOperatorsInput;
  FilterUpdateOneGuidesGuideGUIDOperatorsInput: FilterUpdateOneGuidesGuideGuidOperatorsInput;
  FilterUpdateOneGuidesInput: FilterUpdateOneGuidesInput;
  FilterUpdateOneGuidesOperatorsInput: FilterUpdateOneGuidesOperatorsInput;
  FilterUpdateOneGuidesUserIDOperatorsInput: FilterUpdateOneGuidesUserIdOperatorsInput;
  FilterUpdateOneGuides_idOperatorsInput: FilterUpdateOneGuides_IdOperatorsInput;
  FilterUpdateOnePhonesCallingCodeOperatorsInput: FilterUpdateOnePhonesCallingCodeOperatorsInput;
  FilterUpdateOnePhonesCountryCodeOperatorsInput: FilterUpdateOnePhonesCountryCodeOperatorsInput;
  FilterUpdateOnePhonesInput: FilterUpdateOnePhonesInput;
  FilterUpdateOnePhonesNumberOperatorsInput: FilterUpdateOnePhonesNumberOperatorsInput;
  FilterUpdateOnePhonesOperatorsInput: FilterUpdateOnePhonesOperatorsInput;
  FilterUpdateOnePhonesPhoneGUIDOperatorsInput: FilterUpdateOnePhonesPhoneGuidOperatorsInput;
  FilterUpdateOnePhones_idOperatorsInput: FilterUpdateOnePhones_IdOperatorsInput;
  FilterUpdateOneSchedulesInput: FilterUpdateOneSchedulesInput;
  FilterUpdateOneSchedulesOperatorsInput: FilterUpdateOneSchedulesOperatorsInput;
  FilterUpdateOneSchedulesRangesInput: FilterUpdateOneSchedulesRangesInput;
  FilterUpdateOneSchedules_idOperatorsInput: FilterUpdateOneSchedules_IdOperatorsInput;
  FilterUpdateOneUsersActiveOperatorsInput: FilterUpdateOneUsersActiveOperatorsInput;
  FilterUpdateOneUsersBirthdayOperatorsInput: FilterUpdateOneUsersBirthdayOperatorsInput;
  FilterUpdateOneUsersEmailOperatorsInput: FilterUpdateOneUsersEmailOperatorsInput;
  FilterUpdateOneUsersFirstNameOperatorsInput: FilterUpdateOneUsersFirstNameOperatorsInput;
  FilterUpdateOneUsersGenderOperatorsInput: FilterUpdateOneUsersGenderOperatorsInput;
  FilterUpdateOneUsersInput: FilterUpdateOneUsersInput;
  FilterUpdateOneUsersLastNameOperatorsInput: FilterUpdateOneUsersLastNameOperatorsInput;
  FilterUpdateOneUsersOperatorsInput: FilterUpdateOneUsersOperatorsInput;
  FilterUpdateOneUsersPhoneIDOperatorsInput: FilterUpdateOneUsersPhoneIdOperatorsInput;
  FilterUpdateOneUsersSocialProvidersOperatorsInput: FilterUpdateOneUsersSocialProvidersOperatorsInput;
  FilterUpdateOneUsersUserGUIDOperatorsInput: FilterUpdateOneUsersUserGuidOperatorsInput;
  FilterUpdateOneUsersUserNameOperatorsInput: FilterUpdateOneUsersUserNameOperatorsInput;
  FilterUpdateOneUsers_idOperatorsInput: FilterUpdateOneUsers_IdOperatorsInput;
  FunctionCall: FunctionCall;
  GUID: Scalars['GUID']['output'];
  GeoLocationSearchInput: GeoLocationSearchInput;
  GeoLocations: GeoLocations;
  GeoLocationsAnnotations: GeoLocationsAnnotations;
  GeoLocationsAnnotationsCurrency: GeoLocationsAnnotationsCurrency;
  GeoLocationsAnnotationsCurrencyInput: GeoLocationsAnnotationsCurrencyInput;
  GeoLocationsAnnotationsDMS: GeoLocationsAnnotationsDms;
  GeoLocationsAnnotationsDMSInput: GeoLocationsAnnotationsDmsInput;
  GeoLocationsAnnotationsInput: GeoLocationsAnnotationsInput;
  GeoLocationsAnnotationsMercator: GeoLocationsAnnotationsMercator;
  GeoLocationsAnnotationsMercatorInput: GeoLocationsAnnotationsMercatorInput;
  GeoLocationsAnnotationsNUTS: GeoLocationsAnnotationsNuts;
  GeoLocationsAnnotationsNUTSInput: GeoLocationsAnnotationsNutsInput;
  GeoLocationsAnnotationsNUTSNUTS0: GeoLocationsAnnotationsNutsnuts0;
  GeoLocationsAnnotationsNUTSNUTS0Input: GeoLocationsAnnotationsNutsnuts0Input;
  GeoLocationsAnnotationsNUTSNUTS1: GeoLocationsAnnotationsNutsnuts1;
  GeoLocationsAnnotationsNUTSNUTS1Input: GeoLocationsAnnotationsNutsnuts1Input;
  GeoLocationsAnnotationsNUTSNUTS2: GeoLocationsAnnotationsNutsnuts2;
  GeoLocationsAnnotationsNUTSNUTS2Input: GeoLocationsAnnotationsNutsnuts2Input;
  GeoLocationsAnnotationsNUTSNUTS3: GeoLocationsAnnotationsNutsnuts3;
  GeoLocationsAnnotationsNUTSNUTS3Input: GeoLocationsAnnotationsNutsnuts3Input;
  GeoLocationsAnnotationsOSM: GeoLocationsAnnotationsOsm;
  GeoLocationsAnnotationsOSMInput: GeoLocationsAnnotationsOsmInput;
  GeoLocationsAnnotationsRoadinfo: GeoLocationsAnnotationsRoadinfo;
  GeoLocationsAnnotationsRoadinfoInput: GeoLocationsAnnotationsRoadinfoInput;
  GeoLocationsAnnotationsSun: GeoLocationsAnnotationsSun;
  GeoLocationsAnnotationsSunInput: GeoLocationsAnnotationsSunInput;
  GeoLocationsAnnotationsSunRise: GeoLocationsAnnotationsSunRise;
  GeoLocationsAnnotationsSunRiseInput: GeoLocationsAnnotationsSunRiseInput;
  GeoLocationsAnnotationsSunSet: GeoLocationsAnnotationsSunSet;
  GeoLocationsAnnotationsSunSetInput: GeoLocationsAnnotationsSunSetInput;
  GeoLocationsAnnotationsTimezone: GeoLocationsAnnotationsTimezone;
  GeoLocationsAnnotationsTimezoneInput: GeoLocationsAnnotationsTimezoneInput;
  GeoLocationsAnnotationsUN_M49: GeoLocationsAnnotationsUn_M49;
  GeoLocationsAnnotationsUN_M49Input: GeoLocationsAnnotationsUn_M49Input;
  GeoLocationsAnnotationsUN_M49Regions: GeoLocationsAnnotationsUn_M49Regions;
  GeoLocationsAnnotationsUN_M49RegionsInput: GeoLocationsAnnotationsUn_M49RegionsInput;
  GeoLocationsAnnotationsWhat3words: GeoLocationsAnnotationsWhat3words;
  GeoLocationsAnnotationsWhat3wordsInput: GeoLocationsAnnotationsWhat3wordsInput;
  GeoLocationsBounds: GeoLocationsBounds;
  GeoLocationsBoundsInput: GeoLocationsBoundsInput;
  GeoLocationsBoundsNortheast: GeoLocationsBoundsNortheast;
  GeoLocationsBoundsNortheastInput: GeoLocationsBoundsNortheastInput;
  GeoLocationsBoundsSouthwest: GeoLocationsBoundsSouthwest;
  GeoLocationsBoundsSouthwestInput: GeoLocationsBoundsSouthwestInput;
  GeoLocationsComponents: GeoLocationsComponents;
  GeoLocationsComponentsISO: GeoLocationsComponentsIso;
  GeoLocationsComponentsISOInput: GeoLocationsComponentsIsoInput;
  GeoLocationsComponentsISOV1: GeoLocationsComponentsIsov1;
  GeoLocationsComponentsISOV1Input: GeoLocationsComponentsIsov1Input;
  GeoLocationsComponentsInput: GeoLocationsComponentsInput;
  GeoLocationsGeometry: GeoLocationsGeometry;
  GeoLocationsGeometryInput: GeoLocationsGeometryInput;
  GeoLocationsPagination: GeoLocationsPagination;
  Guides: Guides;
  GuidesPagination: GuidesPagination;
  HSL: Scalars['HSL']['output'];
  HSLA: Scalars['HSLA']['output'];
  HexColorCode: Scalars['HexColorCode']['output'];
  Hexadecimal: Scalars['Hexadecimal']['output'];
  IBAN: Scalars['IBAN']['output'];
  IP: Scalars['IP']['output'];
  IPCPatent: Scalars['IPCPatent']['output'];
  IPv4: Scalars['IPv4']['output'];
  IPv6: Scalars['IPv6']['output'];
  ISBN: Scalars['ISBN']['output'];
  ISO8601Duration: Scalars['ISO8601Duration']['output'];
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  JWT: Scalars['JWT']['output'];
  LCCSubclass: Scalars['LCCSubclass']['output'];
  Latitude: Scalars['Latitude']['output'];
  LocalDate: Scalars['LocalDate']['output'];
  LocalDateTime: Scalars['LocalDateTime']['output'];
  LocalEndTime: Scalars['LocalEndTime']['output'];
  LocalTime: Scalars['LocalTime']['output'];
  Locale: Scalars['Locale']['output'];
  Logprobs: Logprobs;
  Long: Scalars['Long']['output'];
  Longitude: Scalars['Longitude']['output'];
  MAC: Scalars['MAC']['output'];
  Message: Message;
  MongoID: Scalars['MongoID']['output'];
  Mutation: {};
  NegativeFloat: Scalars['NegativeFloat']['output'];
  NegativeInt: Scalars['NegativeInt']['output'];
  NonEmptyString: Scalars['NonEmptyString']['output'];
  NonNegativeFloat: Scalars['NonNegativeFloat']['output'];
  NonNegativeInt: Scalars['NonNegativeInt']['output'];
  NonPositiveFloat: Scalars['NonPositiveFloat']['output'];
  NonPositiveInt: Scalars['NonPositiveInt']['output'];
  ObjectID: Scalars['ObjectID']['output'];
  PaginationInfo: PaginationInfo;
  PhoneNumber: Scalars['PhoneNumber']['output'];
  Phones: Phones;
  PhonesPagination: PhonesPagination;
  Port: Scalars['Port']['output'];
  PositiveFloat: Scalars['PositiveFloat']['output'];
  PositiveInt: Scalars['PositiveInt']['output'];
  PostalCode: Scalars['PostalCode']['output'];
  Profile: Profile;
  Query: {};
  RGB: Scalars['RGB']['output'];
  RGBA: Scalars['RGBA']['output'];
  RefreshToken: RefreshToken;
  RefreshTokenInput: RefreshTokenInput;
  RegExpAsString: Scalars['RegExpAsString']['output'];
  Register: Register;
  RegisterInput: RegisterInput;
  RemoveManyActivitiesPayload: RemoveManyActivitiesPayload;
  RemoveManyAssetsPayload: RemoveManyAssetsPayload;
  RemoveManyBookingsPayload: RemoveManyBookingsPayload;
  RemoveManyGeoLocationsPayload: RemoveManyGeoLocationsPayload;
  RemoveManyGuidesPayload: RemoveManyGuidesPayload;
  RemoveManyPhonesPayload: RemoveManyPhonesPayload;
  RemoveManySchedulesPayload: RemoveManySchedulesPayload;
  RemoveManyUsersPayload: RemoveManyUsersPayload;
  RemoveOneActivitiesPayload: RemoveOneActivitiesPayload;
  RemoveOneAssetsPayload: RemoveOneAssetsPayload;
  RemoveOneBookingsPayload: RemoveOneBookingsPayload;
  RemoveOneGeoLocationsPayload: RemoveOneGeoLocationsPayload;
  RemoveOneGuidesPayload: RemoveOneGuidesPayload;
  RemoveOnePhonesPayload: RemoveOnePhonesPayload;
  RemoveOneSchedulesPayload: RemoveOneSchedulesPayload;
  RemoveOneUsersPayload: RemoveOneUsersPayload;
  RoutingNumber: Scalars['RoutingNumber']['output'];
  SESSN: Scalars['SESSN']['output'];
  SafeInt: Scalars['SafeInt']['output'];
  Schedules: Schedules;
  SchedulesPagination: SchedulesPagination;
  SchedulesRanges: SchedulesRanges;
  SchedulesRangesInput: SchedulesRangesInput;
  SemVer: Scalars['SemVer']['output'];
  SignIn: SignIn;
  SignInInput: SignInInput;
  SignOut: SignOut;
  SignOutInput: SignOutInput;
  SocialSignIn: SocialSignIn;
  SocialSignInInput: SocialSignInInput;
  Subscription: {};
  Suggestion: Suggestion;
  SuggestionsInput: SuggestionsInput;
  Time: Scalars['Time']['output'];
  TimeZone: Scalars['TimeZone']['output'];
  Timestamp: Scalars['Timestamp']['output'];
  TopLogprob: TopLogprob;
  URL: Scalars['URL']['output'];
  USCurrency: Scalars['USCurrency']['output'];
  UUID: Scalars['UUID']['output'];
  UnsignedFloat: Scalars['UnsignedFloat']['output'];
  UnsignedInt: Scalars['UnsignedInt']['output'];
  UpdateManyActivitiesDescriptionInput: UpdateManyActivitiesDescriptionInput;
  UpdateManyActivitiesInput: UpdateManyActivitiesInput;
  UpdateManyActivitiesPayload: UpdateManyActivitiesPayload;
  UpdateManyAssetsInput: UpdateManyAssetsInput;
  UpdateManyAssetsPayload: UpdateManyAssetsPayload;
  UpdateManyBookingsInput: UpdateManyBookingsInput;
  UpdateManyBookingsPayload: UpdateManyBookingsPayload;
  UpdateManyGeoLocationsAnnotationsCurrencyInput: UpdateManyGeoLocationsAnnotationsCurrencyInput;
  UpdateManyGeoLocationsAnnotationsDMSInput: UpdateManyGeoLocationsAnnotationsDmsInput;
  UpdateManyGeoLocationsAnnotationsInput: UpdateManyGeoLocationsAnnotationsInput;
  UpdateManyGeoLocationsAnnotationsMercatorInput: UpdateManyGeoLocationsAnnotationsMercatorInput;
  UpdateManyGeoLocationsAnnotationsNUTSInput: UpdateManyGeoLocationsAnnotationsNutsInput;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS0Input: UpdateManyGeoLocationsAnnotationsNutsnuts0Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS1Input: UpdateManyGeoLocationsAnnotationsNutsnuts1Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS2Input: UpdateManyGeoLocationsAnnotationsNutsnuts2Input;
  UpdateManyGeoLocationsAnnotationsNUTSNUTS3Input: UpdateManyGeoLocationsAnnotationsNutsnuts3Input;
  UpdateManyGeoLocationsAnnotationsOSMInput: UpdateManyGeoLocationsAnnotationsOsmInput;
  UpdateManyGeoLocationsAnnotationsRoadinfoInput: UpdateManyGeoLocationsAnnotationsRoadinfoInput;
  UpdateManyGeoLocationsAnnotationsSunInput: UpdateManyGeoLocationsAnnotationsSunInput;
  UpdateManyGeoLocationsAnnotationsSunRiseInput: UpdateManyGeoLocationsAnnotationsSunRiseInput;
  UpdateManyGeoLocationsAnnotationsSunSetInput: UpdateManyGeoLocationsAnnotationsSunSetInput;
  UpdateManyGeoLocationsAnnotationsTimezoneInput: UpdateManyGeoLocationsAnnotationsTimezoneInput;
  UpdateManyGeoLocationsAnnotationsUN_M49Input: UpdateManyGeoLocationsAnnotationsUn_M49Input;
  UpdateManyGeoLocationsAnnotationsUN_M49RegionsInput: UpdateManyGeoLocationsAnnotationsUn_M49RegionsInput;
  UpdateManyGeoLocationsAnnotationsWhat3wordsInput: UpdateManyGeoLocationsAnnotationsWhat3wordsInput;
  UpdateManyGeoLocationsBoundsInput: UpdateManyGeoLocationsBoundsInput;
  UpdateManyGeoLocationsBoundsNortheastInput: UpdateManyGeoLocationsBoundsNortheastInput;
  UpdateManyGeoLocationsBoundsSouthwestInput: UpdateManyGeoLocationsBoundsSouthwestInput;
  UpdateManyGeoLocationsComponentsISOInput: UpdateManyGeoLocationsComponentsIsoInput;
  UpdateManyGeoLocationsComponentsISOV1Input: UpdateManyGeoLocationsComponentsIsov1Input;
  UpdateManyGeoLocationsComponentsInput: UpdateManyGeoLocationsComponentsInput;
  UpdateManyGeoLocationsGeometryInput: UpdateManyGeoLocationsGeometryInput;
  UpdateManyGeoLocationsInput: UpdateManyGeoLocationsInput;
  UpdateManyGeoLocationsPayload: UpdateManyGeoLocationsPayload;
  UpdateManyGuidesInput: UpdateManyGuidesInput;
  UpdateManyGuidesPayload: UpdateManyGuidesPayload;
  UpdateManyPhonesInput: UpdateManyPhonesInput;
  UpdateManyPhonesPayload: UpdateManyPhonesPayload;
  UpdateManySchedulesInput: UpdateManySchedulesInput;
  UpdateManySchedulesPayload: UpdateManySchedulesPayload;
  UpdateManySchedulesRangesInput: UpdateManySchedulesRangesInput;
  UpdateManyUsersInput: UpdateManyUsersInput;
  UpdateManyUsersPayload: UpdateManyUsersPayload;
  UpdateOneActivitiesDescriptionInput: UpdateOneActivitiesDescriptionInput;
  UpdateOneActivitiesInput: UpdateOneActivitiesInput;
  UpdateOneActivitiesPayload: UpdateOneActivitiesPayload;
  UpdateOneAssetsInput: UpdateOneAssetsInput;
  UpdateOneAssetsPayload: UpdateOneAssetsPayload;
  UpdateOneBookingsInput: UpdateOneBookingsInput;
  UpdateOneBookingsPayload: UpdateOneBookingsPayload;
  UpdateOneGeoLocationsAnnotationsCurrencyInput: UpdateOneGeoLocationsAnnotationsCurrencyInput;
  UpdateOneGeoLocationsAnnotationsDMSInput: UpdateOneGeoLocationsAnnotationsDmsInput;
  UpdateOneGeoLocationsAnnotationsInput: UpdateOneGeoLocationsAnnotationsInput;
  UpdateOneGeoLocationsAnnotationsMercatorInput: UpdateOneGeoLocationsAnnotationsMercatorInput;
  UpdateOneGeoLocationsAnnotationsNUTSInput: UpdateOneGeoLocationsAnnotationsNutsInput;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS0Input: UpdateOneGeoLocationsAnnotationsNutsnuts0Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS1Input: UpdateOneGeoLocationsAnnotationsNutsnuts1Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS2Input: UpdateOneGeoLocationsAnnotationsNutsnuts2Input;
  UpdateOneGeoLocationsAnnotationsNUTSNUTS3Input: UpdateOneGeoLocationsAnnotationsNutsnuts3Input;
  UpdateOneGeoLocationsAnnotationsOSMInput: UpdateOneGeoLocationsAnnotationsOsmInput;
  UpdateOneGeoLocationsAnnotationsRoadinfoInput: UpdateOneGeoLocationsAnnotationsRoadinfoInput;
  UpdateOneGeoLocationsAnnotationsSunInput: UpdateOneGeoLocationsAnnotationsSunInput;
  UpdateOneGeoLocationsAnnotationsSunRiseInput: UpdateOneGeoLocationsAnnotationsSunRiseInput;
  UpdateOneGeoLocationsAnnotationsSunSetInput: UpdateOneGeoLocationsAnnotationsSunSetInput;
  UpdateOneGeoLocationsAnnotationsTimezoneInput: UpdateOneGeoLocationsAnnotationsTimezoneInput;
  UpdateOneGeoLocationsAnnotationsUN_M49Input: UpdateOneGeoLocationsAnnotationsUn_M49Input;
  UpdateOneGeoLocationsAnnotationsUN_M49RegionsInput: UpdateOneGeoLocationsAnnotationsUn_M49RegionsInput;
  UpdateOneGeoLocationsAnnotationsWhat3wordsInput: UpdateOneGeoLocationsAnnotationsWhat3wordsInput;
  UpdateOneGeoLocationsBoundsInput: UpdateOneGeoLocationsBoundsInput;
  UpdateOneGeoLocationsBoundsNortheastInput: UpdateOneGeoLocationsBoundsNortheastInput;
  UpdateOneGeoLocationsBoundsSouthwestInput: UpdateOneGeoLocationsBoundsSouthwestInput;
  UpdateOneGeoLocationsComponentsISOInput: UpdateOneGeoLocationsComponentsIsoInput;
  UpdateOneGeoLocationsComponentsISOV1Input: UpdateOneGeoLocationsComponentsIsov1Input;
  UpdateOneGeoLocationsComponentsInput: UpdateOneGeoLocationsComponentsInput;
  UpdateOneGeoLocationsGeometryInput: UpdateOneGeoLocationsGeometryInput;
  UpdateOneGeoLocationsInput: UpdateOneGeoLocationsInput;
  UpdateOneGeoLocationsPayload: UpdateOneGeoLocationsPayload;
  UpdateOneGuidesInput: UpdateOneGuidesInput;
  UpdateOneGuidesPayload: UpdateOneGuidesPayload;
  UpdateOnePhonesInput: UpdateOnePhonesInput;
  UpdateOnePhonesPayload: UpdateOnePhonesPayload;
  UpdateOneSchedulesInput: UpdateOneSchedulesInput;
  UpdateOneSchedulesPayload: UpdateOneSchedulesPayload;
  UpdateOneSchedulesRangesInput: UpdateOneSchedulesRangesInput;
  UpdateOneUsersInput: UpdateOneUsersInput;
  UpdateOneUsersPayload: UpdateOneUsersPayload;
  Upload: Scalars['Upload']['output'];
  UploadAsset: UploadAsset;
  UploadAssetInput: UploadAssetInput;
  UserLocationLookup: UserLocationLookup;
  UserLocationLookupCarrier: UserLocationLookupCarrier;
  UserLocationLookupCompany: UserLocationLookupCompany;
  UserLocationLookupConnection: UserLocationLookupConnection;
  UserLocationLookupCurrency: UserLocationLookupCurrency;
  UserLocationLookupCurrencyFormat: UserLocationLookupCurrencyFormat;
  UserLocationLookupCurrencyFormatPrefixSuffix: UserLocationLookupCurrencyFormatPrefixSuffix;
  UserLocationLookupInput: UserLocationLookupInput;
  UserLocationLookupLocation: UserLocationLookupLocation;
  UserLocationLookupLocationContinent: UserLocationLookupLocationContinent;
  UserLocationLookupLocationCountry: UserLocationLookupLocationCountry;
  UserLocationLookupLocationCountryFlag: UserLocationLookupLocationCountryFlag;
  UserLocationLookupLocationLanguage: UserLocationLookupLocationLanguage;
  UserLocationLookupLocationRegion: UserLocationLookupLocationRegion;
  UserLocationLookupSecurity: UserLocationLookupSecurity;
  UserLocationLookupTimeZone: UserLocationLookupTimeZone;
  Users: Users;
  UsersPagination: UsersPagination;
  UtcOffset: Scalars['UtcOffset']['output'];
  Void: Scalars['Void']['output'];
};

export interface AccountNumberScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AccountNumber'], any> {
  name: 'AccountNumber';
}

export type ActivateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Activate'] = ResolversParentTypes['Activate'],
> = {
  AccessToken?: Resolver<ResolversTypes['JWT'], ParentType, ContextType>;
  CreatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  ExpiresIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  LastSignedInAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  RefetchIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  RefreshToken?: Resolver<ResolversTypes['JWT'], ParentType, ContextType>;
  UserGUID?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivitiesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Activities'] = ResolversParentTypes['Activities'],
> = {
  ActivityGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  AssetID?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  Assets?: Resolver<
    Array<Maybe<ResolversTypes['Assets']>>,
    ParentType,
    ContextType
  >;
  Description?: Resolver<
    Maybe<ResolversTypes['ActivitiesDescription']>,
    ParentType,
    ContextType
  >;
  Details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FavoredBy?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  GeoLocation?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType
  >;
  GeoLocationID?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  GuideID?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  Guides?: Resolver<
    Array<Maybe<ResolversTypes['Guides']>>,
    ParentType,
    ContextType
  >;
  ScheduleID?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  Schedules?: Resolver<
    Array<Maybe<ResolversTypes['Schedules']>>,
    ParentType,
    ContextType
  >;
  Title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivitiesDescriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivitiesDescription'] = ResolversParentTypes['ActivitiesDescription'],
> = {
  Conclusion?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Introduction?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Main?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivitiesPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivitiesPagination'] = ResolversParentTypes['ActivitiesPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Activities']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AssetDataSourceValueScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['AssetDataSourceValue'], any> {
  name: 'AssetDataSourceValue';
}

export type AssetsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Assets'] = ResolversParentTypes['Assets'],
> = {
  AssetGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Size?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetsPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssetsPagination'] = ResolversParentTypes['AssetsPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Assets']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BookingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Bookings'] = ResolversParentTypes['Bookings'],
> = {
  Activity?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType
  >;
  ActivityID?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  BookingGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  CheckInDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  CheckOutDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  Guide?: Resolver<Maybe<ResolversTypes['Guides']>, ParentType, ContextType>;
  GuideID?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  UserID?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingsPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BookingsPagination'] = ResolversParentTypes['BookingsPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Bookings']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ByteScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Byte'], any> {
  name: 'Byte';
}

export type ChatCompletionTokenLogprobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChatCompletionTokenLogprob'] = ResolversParentTypes['ChatCompletionTokenLogprob'],
> = {
  bytes?: Resolver<
    Maybe<Array<ResolversTypes['Int']>>,
    ParentType,
    ContextType
  >;
  logprob?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  top_logprobs?: Resolver<
    Array<ResolversTypes['TopLogprob']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Countries'] = ResolversParentTypes['Countries'],
> = {
  CountryGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Data?: Resolver<ResolversTypes['CountriesData'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CountriesData'] = ResolversParentTypes['CountriesData'],
> = {
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  countryCallingCode?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  countryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  countryNameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryNameLocal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currencyCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currencyNameEn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  flag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  officialLanguageCode?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  officialLanguageNameEn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  officialLanguageNameLocal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tinName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tinType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CountryCodeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['CountryCode'], any> {
  name: 'CountryCode';
}

export type CreateManyActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyActivitiesPayload'] = ResolversParentTypes['CreateManyActivitiesPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Activities']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyAssetsPayload'] = ResolversParentTypes['CreateManyAssetsPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Assets']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyBookingsPayload'] = ResolversParentTypes['CreateManyBookingsPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Bookings']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyGeoLocationsPayload'] = ResolversParentTypes['CreateManyGeoLocationsPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['GeoLocations']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyGuidesPayload'] = ResolversParentTypes['CreateManyGuidesPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Guides']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyPhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyPhonesPayload'] = ResolversParentTypes['CreateManyPhonesPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Phones']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManySchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManySchedulesPayload'] = ResolversParentTypes['CreateManySchedulesPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Schedules']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateManyUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateManyUsersPayload'] = ResolversParentTypes['CreateManyUsersPayload'],
> = {
  createdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recordIds?: Resolver<
    Array<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  records?: Resolver<
    Maybe<Array<ResolversTypes['Users']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneActivitiesPayload'] = ResolversParentTypes['CreateOneActivitiesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneAssetsPayload'] = ResolversParentTypes['CreateOneAssetsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Assets']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneBookingsPayload'] = ResolversParentTypes['CreateOneBookingsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Bookings']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneGeoLocationsPayload'] = ResolversParentTypes['CreateOneGeoLocationsPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneGuidesPayload'] = ResolversParentTypes['CreateOneGuidesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Guides']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOnePhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOnePhonesPayload'] = ResolversParentTypes['CreateOnePhonesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Phones']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneSchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneSchedulesPayload'] = ResolversParentTypes['CreateOneSchedulesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Schedules']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOneUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateOneUsersPayload'] = ResolversParentTypes['CreateOneUsersPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Cuid'], any> {
  name: 'Cuid';
}

export interface CurrencyScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Currency'], any> {
  name: 'Currency';
}

export interface DidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DID'], any> {
  name: 'DID';
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DateTimeIsoScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeISO'], any> {
  name: 'DateTimeISO';
}

export interface DeweyDecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DeweyDecimal'], any> {
  name: 'DeweyDecimal';
}

export interface DurationScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Duration'], any> {
  name: 'Duration';
}

export interface EmailAddressScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type FunctionCallResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FunctionCall'] = ResolversParentTypes['FunctionCall'],
> = {
  arguments?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['GUID'], any> {
  name: 'GUID';
}

export type GeoLocationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocations'] = ResolversParentTypes['GeoLocations'],
> = {
  Annotations?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotations']>,
    ParentType,
    ContextType
  >;
  Bounds?: Resolver<
    Maybe<ResolversTypes['GeoLocationsBounds']>,
    ParentType,
    ContextType
  >;
  Components?: Resolver<
    Maybe<ResolversTypes['GeoLocationsComponents']>,
    ParentType,
    ContextType
  >;
  Confidence?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  Distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Formatted?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  GeoLocationGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Geometry?: Resolver<
    Maybe<ResolversTypes['GeoLocationsGeometry']>,
    ParentType,
    ContextType
  >;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotations'] = ResolversParentTypes['GeoLocationsAnnotations'],
> = {
  DMS?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsDMS']>,
    ParentType,
    ContextType
  >;
  MGRS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Maidenhead?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Mercator?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsMercator']>,
    ParentType,
    ContextType
  >;
  NUTS?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsNUTS']>,
    ParentType,
    ContextType
  >;
  OSM?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsOSM']>,
    ParentType,
    ContextType
  >;
  UN_M49?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsUN_M49']>,
    ParentType,
    ContextType
  >;
  callingcode?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsCurrency']>,
    ParentType,
    ContextType
  >;
  flag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geohash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qibla?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  roadinfo?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsRoadinfo']>,
    ParentType,
    ContextType
  >;
  sun?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsSun']>,
    ParentType,
    ContextType
  >;
  timezone?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsTimezone']>,
    ParentType,
    ContextType
  >;
  what3words?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsWhat3words']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsCurrencyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsCurrency'] = ResolversParentTypes['GeoLocationsAnnotationsCurrency'],
> = {
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  alternate_symbols?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  decimal_mark?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  html_entity?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  iso_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iso_numeric?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smallest_denomination?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  subunit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subunit_to_unit?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol_first?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  thousands_separator?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsDmsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsDMS'] = ResolversParentTypes['GeoLocationsAnnotationsDMS'],
> = {
  lat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsMercatorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsMercator'] = ResolversParentTypes['GeoLocationsAnnotationsMercator'],
> = {
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsNutsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsNUTS'] = ResolversParentTypes['GeoLocationsAnnotationsNUTS'],
> = {
  NUTS0?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsNUTSNUTS0']>,
    ParentType,
    ContextType
  >;
  NUTS1?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsNUTSNUTS1']>,
    ParentType,
    ContextType
  >;
  NUTS2?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsNUTSNUTS2']>,
    ParentType,
    ContextType
  >;
  NUTS3?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsNUTSNUTS3']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsNutsnuts0Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS0'] = ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS0'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsNutsnuts1Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS1'] = ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS1'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsNutsnuts2Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS2'] = ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS2'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsNutsnuts3Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS3'] = ResolversParentTypes['GeoLocationsAnnotationsNUTSNUTS3'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsOsmResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsOSM'] = ResolversParentTypes['GeoLocationsAnnotationsOSM'],
> = {
  edit_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsRoadinfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsRoadinfo'] = ResolversParentTypes['GeoLocationsAnnotationsRoadinfo'],
> = {
  drive_on?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  road?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  speed_in?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsSunResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsSun'] = ResolversParentTypes['GeoLocationsAnnotationsSun'],
> = {
  rise?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsSunRise']>,
    ParentType,
    ContextType
  >;
  set?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsSunSet']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsSunRiseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsSunRise'] = ResolversParentTypes['GeoLocationsAnnotationsSunRise'],
> = {
  apparent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  astronomical?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  civil?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nautical?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsSunSetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsSunSet'] = ResolversParentTypes['GeoLocationsAnnotationsSunSet'],
> = {
  apparent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  astronomical?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  civil?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  nautical?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsTimezoneResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsTimezone'] = ResolversParentTypes['GeoLocationsAnnotationsTimezone'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  now_in_dst?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  offset_sec?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  offset_string?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  short_name?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsUn_M49Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsUN_M49'] = ResolversParentTypes['GeoLocationsAnnotationsUN_M49'],
> = {
  regions?: Resolver<
    Maybe<ResolversTypes['GeoLocationsAnnotationsUN_M49Regions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsUn_M49RegionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsUN_M49Regions'] = ResolversParentTypes['GeoLocationsAnnotationsUN_M49Regions'],
> = {
  DE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EUROPE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WESTERN_EUROPE?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  WORLD?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsAnnotationsWhat3wordsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsAnnotationsWhat3words'] = ResolversParentTypes['GeoLocationsAnnotationsWhat3words'],
> = {
  words?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsBoundsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsBounds'] = ResolversParentTypes['GeoLocationsBounds'],
> = {
  northeast?: Resolver<
    Maybe<ResolversTypes['GeoLocationsBoundsNortheast']>,
    ParentType,
    ContextType
  >;
  southwest?: Resolver<
    Maybe<ResolversTypes['GeoLocationsBoundsSouthwest']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsBoundsNortheastResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsBoundsNortheast'] = ResolversParentTypes['GeoLocationsBoundsNortheast'],
> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsBoundsSouthwestResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsBoundsSouthwest'] = ResolversParentTypes['GeoLocationsBoundsSouthwest'],
> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsComponentsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsComponents'] = ResolversParentTypes['GeoLocationsComponents'],
> = {
  ISO?: Resolver<
    Maybe<ResolversTypes['GeoLocationsComponentsISO']>,
    ParentType,
    ContextType
  >;
  _category?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  _normalized_city?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  _type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city_district?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  continent?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_code?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  house_number?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  office?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  political_union?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  road?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state_code?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  suburb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsComponentsIsoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsComponentsISO'] = ResolversParentTypes['GeoLocationsComponentsISO'],
> = {
  v1?: Resolver<
    Maybe<ResolversTypes['GeoLocationsComponentsISOV1']>,
    ParentType,
    ContextType
  >;
  v2?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsComponentsIsov1Resolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsComponentsISOV1'] = ResolversParentTypes['GeoLocationsComponentsISOV1'],
> = {
  alpha2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alpha3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsGeometryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsGeometry'] = ResolversParentTypes['GeoLocationsGeometry'],
> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoLocationsPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeoLocationsPagination'] = ResolversParentTypes['GeoLocationsPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['GeoLocations']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GuidesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Guides'] = ResolversParentTypes['Guides'],
> = {
  Biography?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  FavoredBy?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  GuideGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ScheduleID?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MongoID']>>>,
    ParentType,
    ContextType
  >;
  Schedules?: Resolver<
    Array<Maybe<ResolversTypes['Schedules']>>,
    ParentType,
    ContextType
  >;
  User?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  UserID?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GuidesPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GuidesPagination'] = ResolversParentTypes['GuidesPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Guides']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface HslScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['HSL'], any> {
  name: 'HSL';
}

export interface HslaScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['HSLA'], any> {
  name: 'HSLA';
}

export interface HexColorCodeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['HexColorCode'], any> {
  name: 'HexColorCode';
}

export interface HexadecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Hexadecimal'], any> {
  name: 'Hexadecimal';
}

export interface IbanScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IBAN'], any> {
  name: 'IBAN';
}

export interface IpScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IP'], any> {
  name: 'IP';
}

export interface IpcPatentScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IPCPatent'], any> {
  name: 'IPCPatent';
}

export interface IPv4ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IPv4'], any> {
  name: 'IPv4';
}

export interface IPv6ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['IPv6'], any> {
  name: 'IPv6';
}

export interface IsbnScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ISBN'], any> {
  name: 'ISBN';
}

export interface Iso8601DurationScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ISO8601Duration'], any> {
  name: 'ISO8601Duration';
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export interface JwtScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JWT'], any> {
  name: 'JWT';
}

export interface LccSubclassScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LCCSubclass'], any> {
  name: 'LCCSubclass';
}

export interface LatitudeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Latitude'], any> {
  name: 'Latitude';
}

export interface LocalDateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalDate'], any> {
  name: 'LocalDate';
}

export interface LocalDateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalDateTime'], any> {
  name: 'LocalDateTime';
}

export interface LocalEndTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalEndTime'], any> {
  name: 'LocalEndTime';
}

export interface LocalTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LocalTime'], any> {
  name: 'LocalTime';
}

export interface LocaleScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Locale'], any> {
  name: 'Locale';
}

export type LogprobsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Logprobs'] = ResolversParentTypes['Logprobs'],
> = {
  content?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ChatCompletionTokenLogprob']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export interface LongitudeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Longitude'], any> {
  name: 'Longitude';
}

export interface MacScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['MAC'], any> {
  name: 'MAC';
}

export type MessageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Message'] = ResolversParentTypes['Message'],
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  function_call?: Resolver<
    Maybe<ResolversTypes['FunctionCall']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  tool_calls?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FunctionCall']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MongoIdScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['MongoID'], any> {
  name: 'MongoID';
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  Activate?: Resolver<
    Maybe<ResolversTypes['Activate']>,
    ParentType,
    ContextType,
    RequireFields<MutationActivateArgs, 'Activate'>
  >;
  AddActivities?: Resolver<
    Maybe<ResolversTypes['CreateManyActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddActivitiesArgs, 'records'>
  >;
  AddActivity?: Resolver<
    Maybe<ResolversTypes['CreateOneActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddActivityArgs, 'record'>
  >;
  AddAsset?: Resolver<
    Maybe<ResolversTypes['CreateOneAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddAssetArgs, 'record'>
  >;
  AddAssets?: Resolver<
    Maybe<ResolversTypes['CreateManyAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddAssetsArgs, 'records'>
  >;
  AddBooking?: Resolver<
    Maybe<ResolversTypes['CreateOneBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddBookingArgs, 'record'>
  >;
  AddBookings?: Resolver<
    Maybe<ResolversTypes['CreateManyBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddBookingsArgs, 'records'>
  >;
  AddGeoLocation?: Resolver<
    Maybe<ResolversTypes['CreateOneGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddGeoLocationArgs, 'record'>
  >;
  AddGeoLocations?: Resolver<
    Maybe<ResolversTypes['CreateManyGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddGeoLocationsArgs, 'records'>
  >;
  AddGuide?: Resolver<
    Maybe<ResolversTypes['CreateOneGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddGuideArgs, 'record'>
  >;
  AddGuides?: Resolver<
    Maybe<ResolversTypes['CreateManyGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddGuidesArgs, 'records'>
  >;
  AddPhone?: Resolver<
    Maybe<ResolversTypes['CreateOnePhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddPhoneArgs, 'record'>
  >;
  AddPhones?: Resolver<
    Maybe<ResolversTypes['CreateManyPhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddPhonesArgs, 'records'>
  >;
  AddSchedule?: Resolver<
    Maybe<ResolversTypes['CreateOneSchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddScheduleArgs, 'record'>
  >;
  AddSchedules?: Resolver<
    Maybe<ResolversTypes['CreateManySchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddSchedulesArgs, 'records'>
  >;
  AddUser?: Resolver<
    Maybe<ResolversTypes['CreateOneUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUserArgs, 'record'>
  >;
  AddUsers?: Resolver<
    Maybe<ResolversTypes['CreateManyUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUsersArgs, 'records'>
  >;
  DeleteActivities?: Resolver<
    Maybe<ResolversTypes['RemoveManyActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteActivitiesArgs, 'filter' | 'limit'>
  >;
  DeleteActivity?: Resolver<
    Maybe<ResolversTypes['RemoveOneActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteActivityArgs, 'filter'>
  >;
  DeleteAsset?: Resolver<
    Maybe<ResolversTypes['RemoveOneAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAssetArgs, 'filter'>
  >;
  DeleteAssets?: Resolver<
    Maybe<ResolversTypes['RemoveManyAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAssetsArgs, 'filter' | 'limit'>
  >;
  DeleteBooking?: Resolver<
    Maybe<ResolversTypes['RemoveOneBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookingArgs, 'filter'>
  >;
  DeleteBookings?: Resolver<
    Maybe<ResolversTypes['RemoveManyBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookingsArgs, 'filter' | 'limit'>
  >;
  DeleteGeoLocation?: Resolver<
    Maybe<ResolversTypes['RemoveOneGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGeoLocationArgs, 'filter'>
  >;
  DeleteGeoLocations?: Resolver<
    Maybe<ResolversTypes['RemoveManyGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGeoLocationsArgs, 'filter' | 'limit'>
  >;
  DeleteGuide?: Resolver<
    Maybe<ResolversTypes['RemoveOneGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGuideArgs, 'filter'>
  >;
  DeleteGuides?: Resolver<
    Maybe<ResolversTypes['RemoveManyGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGuidesArgs, 'filter' | 'limit'>
  >;
  DeletePhone?: Resolver<
    Maybe<ResolversTypes['RemoveOnePhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePhoneArgs, 'filter'>
  >;
  DeletePhones?: Resolver<
    Maybe<ResolversTypes['RemoveManyPhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePhonesArgs, 'filter' | 'limit'>
  >;
  DeleteSchedule?: Resolver<
    Maybe<ResolversTypes['RemoveOneSchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteScheduleArgs, 'filter'>
  >;
  DeleteSchedules?: Resolver<
    Maybe<ResolversTypes['RemoveManySchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSchedulesArgs, 'filter' | 'limit'>
  >;
  DeleteUser?: Resolver<
    Maybe<ResolversTypes['RemoveOneUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserArgs, 'filter'>
  >;
  DeleteUsers?: Resolver<
    Maybe<ResolversTypes['RemoveManyUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUsersArgs, 'filter' | 'limit'>
  >;
  PredictActivity?: Resolver<
    Maybe<ResolversTypes['CreateOneActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationPredictActivityArgs, 'Activity'>
  >;
  RefreshToken?: Resolver<
    Maybe<ResolversTypes['RefreshToken']>,
    ParentType,
    ContextType,
    RequireFields<MutationRefreshTokenArgs, 'RefreshToken'>
  >;
  Register?: Resolver<
    Maybe<ResolversTypes['Register']>,
    ParentType,
    ContextType,
    RequireFields<MutationRegisterArgs, 'Register'>
  >;
  SignIn?: Resolver<
    Maybe<ResolversTypes['SignIn']>,
    ParentType,
    ContextType,
    RequireFields<MutationSignInArgs, 'SignIn'>
  >;
  SignOut?: Resolver<
    Maybe<ResolversTypes['SignOut']>,
    ParentType,
    ContextType,
    RequireFields<MutationSignOutArgs, 'SignOut'>
  >;
  SocialSignIn?: Resolver<
    Maybe<ResolversTypes['SocialSignIn']>,
    ParentType,
    ContextType,
    RequireFields<MutationSocialSignInArgs, 'SocialSignIn'>
  >;
  UpdateActivities?: Resolver<
    Maybe<ResolversTypes['UpdateManyActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateActivitiesArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateActivity?: Resolver<
    Maybe<ResolversTypes['UpdateOneActivitiesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateActivityArgs, 'filter' | 'record'>
  >;
  UpdateAsset?: Resolver<
    Maybe<ResolversTypes['UpdateOneAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAssetArgs, 'filter' | 'record'>
  >;
  UpdateAssets?: Resolver<
    Maybe<ResolversTypes['UpdateManyAssetsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAssetsArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateBooking?: Resolver<
    Maybe<ResolversTypes['UpdateOneBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBookingArgs, 'filter' | 'record'>
  >;
  UpdateBookings?: Resolver<
    Maybe<ResolversTypes['UpdateManyBookingsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBookingsArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateGeoLocation?: Resolver<
    Maybe<ResolversTypes['UpdateOneGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGeoLocationArgs, 'filter' | 'record'>
  >;
  UpdateGeoLocations?: Resolver<
    Maybe<ResolversTypes['UpdateManyGeoLocationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGeoLocationsArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateGuide?: Resolver<
    Maybe<ResolversTypes['UpdateOneGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGuideArgs, 'filter' | 'record'>
  >;
  UpdateGuides?: Resolver<
    Maybe<ResolversTypes['UpdateManyGuidesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGuidesArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdatePhone?: Resolver<
    Maybe<ResolversTypes['UpdateOnePhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePhoneArgs, 'filter' | 'record'>
  >;
  UpdatePhones?: Resolver<
    Maybe<ResolversTypes['UpdateManyPhonesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePhonesArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateSchedule?: Resolver<
    Maybe<ResolversTypes['UpdateOneSchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateScheduleArgs, 'filter' | 'record'>
  >;
  UpdateSchedules?: Resolver<
    Maybe<ResolversTypes['UpdateManySchedulesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSchedulesArgs, 'filter' | 'limit' | 'record'>
  >;
  UpdateUser?: Resolver<
    Maybe<ResolversTypes['UpdateOneUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'filter' | 'record'>
  >;
  UpdateUsers?: Resolver<
    Maybe<ResolversTypes['UpdateManyUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUsersArgs, 'filter' | 'limit' | 'record'>
  >;
  UploadAsset?: Resolver<
    Maybe<ResolversTypes['UploadAsset']>,
    ParentType,
    ContextType,
    RequireFields<MutationUploadAssetArgs, 'UploadAsset'>
  >;
  UploadAssets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UploadAsset']>>>,
    ParentType,
    ContextType,
    RequireFields<MutationUploadAssetsArgs, 'UploadAssets'>
  >;
};

export interface NegativeFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NegativeFloat'], any> {
  name: 'NegativeFloat';
}

export interface NegativeIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NegativeInt'], any> {
  name: 'NegativeInt';
}

export interface NonEmptyStringScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonEmptyString'], any> {
  name: 'NonEmptyString';
}

export interface NonNegativeFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeFloat'], any> {
  name: 'NonNegativeFloat';
}

export interface NonNegativeIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeInt'], any> {
  name: 'NonNegativeInt';
}

export interface NonPositiveFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonPositiveFloat'], any> {
  name: 'NonPositiveFloat';
}

export interface NonPositiveIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonPositiveInt'], any> {
  name: 'NonPositiveInt';
}

export interface ObjectIdScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ObjectID'], any> {
  name: 'ObjectID';
}

export type PaginationInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaginationInfo'] = ResolversParentTypes['PaginationInfo'],
> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasNextPage?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hasPreviousPage?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  itemCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhoneNumberScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber';
}

export type PhonesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Phones'] = ResolversParentTypes['Phones'],
> = {
  CallingCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CountryCode?: Resolver<
    ResolversTypes['EnumPhonesCountryCode'],
    ParentType,
    ContextType
  >;
  Number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  PhoneGUID?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhonesPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PhonesPagination'] = ResolversParentTypes['PhonesPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Phones']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PortScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Port'], any> {
  name: 'Port';
}

export interface PositiveFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PositiveFloat'], any> {
  name: 'PositiveFloat';
}

export interface PositiveIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PositiveInt'], any> {
  name: 'PositiveInt';
}

export interface PostalCodeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PostalCode'], any> {
  name: 'PostalCode';
}

export type ProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Profile'] = ResolversParentTypes['Profile'],
> = {
  Active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Asset?: Resolver<Maybe<ResolversTypes['Assets']>, ParentType, ContextType>;
  AssetID?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  Birthday?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  Country?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType
  >;
  CountryID?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  Email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Gender?: Resolver<
    Maybe<ResolversTypes['EnumUsersGender']>,
    ParentType,
    ContextType
  >;
  GeoLocation?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType
  >;
  GeoLocationID?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Phone?: Resolver<Maybe<ResolversTypes['Phones']>, ParentType, ContextType>;
  PhoneID?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  SocialProviders?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EnumUsersSocialProviders']>>>,
    ParentType,
    ContextType
  >;
  UserGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  Activities?: Resolver<
    Maybe<ResolversTypes['ActivitiesPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryActivitiesArgs, 'filter' | 'perPage'>
  >;
  Activity?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType,
    RequireFields<QueryActivityArgs, 'filter'>
  >;
  Asset?: Resolver<
    Maybe<ResolversTypes['Assets']>,
    ParentType,
    ContextType,
    RequireFields<QueryAssetArgs, 'filter'>
  >;
  Assets?: Resolver<
    Maybe<ResolversTypes['AssetsPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryAssetsArgs, 'filter' | 'perPage'>
  >;
  Booking?: Resolver<
    Maybe<ResolversTypes['Bookings']>,
    ParentType,
    ContextType,
    RequireFields<QueryBookingArgs, 'filter'>
  >;
  Bookings?: Resolver<
    Maybe<ResolversTypes['BookingsPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryBookingsArgs, 'perPage'>
  >;
  Countries?: Resolver<
    Array<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<QueryCountriesArgs, 'limit'>
  >;
  Country?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<QueryCountryArgs, 'filter'>
  >;
  GeoLocation?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeoLocationArgs, 'filter'>
  >;
  GeoLocationSearch?: Resolver<
    Array<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType,
    Partial<QueryGeoLocationSearchArgs>
  >;
  GeoLocations?: Resolver<
    Maybe<ResolversTypes['GeoLocationsPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeoLocationsArgs, 'filter' | 'perPage'>
  >;
  Guide?: Resolver<
    Maybe<ResolversTypes['Guides']>,
    ParentType,
    ContextType,
    RequireFields<QueryGuideArgs, 'filter'>
  >;
  Guides?: Resolver<
    Maybe<ResolversTypes['GuidesPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryGuidesArgs, 'filter' | 'perPage'>
  >;
  Phone?: Resolver<
    Maybe<ResolversTypes['Phones']>,
    ParentType,
    ContextType,
    RequireFields<QueryPhoneArgs, 'filter'>
  >;
  Phones?: Resolver<
    Maybe<ResolversTypes['PhonesPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryPhonesArgs, 'filter' | 'perPage'>
  >;
  Profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  Schedule?: Resolver<
    Maybe<ResolversTypes['Schedules']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduleArgs, 'filter'>
  >;
  Schedules?: Resolver<
    Maybe<ResolversTypes['SchedulesPagination']>,
    ParentType,
    ContextType,
    RequireFields<QuerySchedulesArgs, 'filter' | 'perPage'>
  >;
  Suggestions?: Resolver<
    Maybe<Array<ResolversTypes['Suggestion']>>,
    ParentType,
    ContextType,
    RequireFields<QuerySuggestionsArgs, 'Suggestions'>
  >;
  User?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, 'filter'>
  >;
  UserLocationLookup?: Resolver<
    Maybe<ResolversTypes['UserLocationLookup']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserLocationLookupArgs, 'UserLocationLookup'>
  >;
  Users?: Resolver<
    Maybe<ResolversTypes['UsersPagination']>,
    ParentType,
    ContextType,
    RequireFields<QueryUsersArgs, 'filter' | 'perPage'>
  >;
};

export interface RgbScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RGB'], any> {
  name: 'RGB';
}

export interface RgbaScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RGBA'], any> {
  name: 'RGBA';
}

export type RefreshTokenResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RefreshToken'] = ResolversParentTypes['RefreshToken'],
> = {
  AccessToken?: Resolver<Maybe<ResolversTypes['JWT']>, ParentType, ContextType>;
  ExpiresIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RegExpAsStringScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RegExpAsString'], any> {
  name: 'RegExpAsString';
}

export type RegisterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Register'] = ResolversParentTypes['Register'],
> = {
  RegistrationGUID?: Resolver<
    Maybe<ResolversTypes['UUID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyActivitiesPayload'] = ResolversParentTypes['RemoveManyActivitiesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyAssetsPayload'] = ResolversParentTypes['RemoveManyAssetsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyBookingsPayload'] = ResolversParentTypes['RemoveManyBookingsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyGeoLocationsPayload'] = ResolversParentTypes['RemoveManyGeoLocationsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyGuidesPayload'] = ResolversParentTypes['RemoveManyGuidesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyPhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyPhonesPayload'] = ResolversParentTypes['RemoveManyPhonesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManySchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManySchedulesPayload'] = ResolversParentTypes['RemoveManySchedulesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveManyUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveManyUsersPayload'] = ResolversParentTypes['RemoveManyUsersPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneActivitiesPayload'] = ResolversParentTypes['RemoveOneActivitiesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneAssetsPayload'] = ResolversParentTypes['RemoveOneAssetsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Assets']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneBookingsPayload'] = ResolversParentTypes['RemoveOneBookingsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Bookings']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneGeoLocationsPayload'] = ResolversParentTypes['RemoveOneGeoLocationsPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneGuidesPayload'] = ResolversParentTypes['RemoveOneGuidesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Guides']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOnePhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOnePhonesPayload'] = ResolversParentTypes['RemoveOnePhonesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Phones']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneSchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneSchedulesPayload'] = ResolversParentTypes['RemoveOneSchedulesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Schedules']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveOneUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveOneUsersPayload'] = ResolversParentTypes['RemoveOneUsersPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RoutingNumberScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RoutingNumber'], any> {
  name: 'RoutingNumber';
}

export interface SessnScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['SESSN'], any> {
  name: 'SESSN';
}

export interface SafeIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['SafeInt'], any> {
  name: 'SafeInt';
}

export type SchedulesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Schedules'] = ResolversParentTypes['Schedules'],
> = {
  Activity?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType
  >;
  ActivityID?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  Guide?: Resolver<Maybe<ResolversTypes['Guides']>, ParentType, ContextType>;
  GuideID?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  Intervals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  Ranges?: Resolver<
    Maybe<Array<Maybe<Array<Maybe<ResolversTypes['SchedulesRanges']>>>>>,
    ParentType,
    ContextType
  >;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchedulesPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SchedulesPagination'] = ResolversParentTypes['SchedulesPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Schedules']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchedulesRangesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SchedulesRanges'] = ResolversParentTypes['SchedulesRanges'],
> = {
  From?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  To?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  _id?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SemVerScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['SemVer'], any> {
  name: 'SemVer';
}

export type SignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SignIn'] = ResolversParentTypes['SignIn'],
> = {
  AccessToken?: Resolver<Maybe<ResolversTypes['JWT']>, ParentType, ContextType>;
  CreatedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  ExpiresIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LastSignedInAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  RefetchIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RefreshToken?: Resolver<
    Maybe<ResolversTypes['JWT']>,
    ParentType,
    ContextType
  >;
  UserGUID?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignOutResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SignOut'] = ResolversParentTypes['SignOut'],
> = {
  SignOut?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialSignInResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SocialSignIn'] = ResolversParentTypes['SocialSignIn'],
> = {
  AccessToken?: Resolver<Maybe<ResolversTypes['JWT']>, ParentType, ContextType>;
  CreatedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  Existing?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  ExpiresIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  LastSignedInAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  RefetchIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  RefreshToken?: Resolver<
    Maybe<ResolversTypes['JWT']>,
    ParentType,
    ContextType
  >;
  UserGUID?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  Suggestions?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'Suggestions',
    ParentType,
    ContextType,
    RequireFields<SubscriptionSuggestionsArgs, 'Suggestions'>
  >;
};

export type SuggestionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Suggestion'] = ResolversParentTypes['Suggestion'],
> = {
  Activities?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  Address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  City?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Commute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Introduction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export interface TimeZoneScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['TimeZone'], any> {
  name: 'TimeZone';
}

export interface TimestampScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TopLogprobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TopLogprob'] = ResolversParentTypes['TopLogprob'],
> = {
  bytes?: Resolver<
    Maybe<Array<ResolversTypes['Int']>>,
    ParentType,
    ContextType
  >;
  logprob?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface UsCurrencyScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['USCurrency'], any> {
  name: 'USCurrency';
}

export interface UuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export interface UnsignedFloatScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedFloat'], any> {
  name: 'UnsignedFloat';
}

export interface UnsignedIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedInt'], any> {
  name: 'UnsignedInt';
}

export type UpdateManyActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyActivitiesPayload'] = ResolversParentTypes['UpdateManyActivitiesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyAssetsPayload'] = ResolversParentTypes['UpdateManyAssetsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyBookingsPayload'] = ResolversParentTypes['UpdateManyBookingsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyGeoLocationsPayload'] = ResolversParentTypes['UpdateManyGeoLocationsPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyGuidesPayload'] = ResolversParentTypes['UpdateManyGuidesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyPhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyPhonesPayload'] = ResolversParentTypes['UpdateManyPhonesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManySchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManySchedulesPayload'] = ResolversParentTypes['UpdateManySchedulesPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateManyUsersPayload'] = ResolversParentTypes['UpdateManyUsersPayload'],
> = {
  numAffected?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneActivitiesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneActivitiesPayload'] = ResolversParentTypes['UpdateOneActivitiesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Activities']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneAssetsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneAssetsPayload'] = ResolversParentTypes['UpdateOneAssetsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Assets']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneBookingsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneBookingsPayload'] = ResolversParentTypes['UpdateOneBookingsPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Bookings']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneGeoLocationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneGeoLocationsPayload'] = ResolversParentTypes['UpdateOneGeoLocationsPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['GeoLocations']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneGuidesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneGuidesPayload'] = ResolversParentTypes['UpdateOneGuidesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Guides']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOnePhonesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOnePhonesPayload'] = ResolversParentTypes['UpdateOnePhonesPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Phones']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneSchedulesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneSchedulesPayload'] = ResolversParentTypes['UpdateOneSchedulesPayload'],
> = {
  record?: Resolver<
    Maybe<ResolversTypes['Schedules']>,
    ParentType,
    ContextType
  >;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOneUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateOneUsersPayload'] = ResolversParentTypes['UpdateOneUsersPayload'],
> = {
  record?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  recordId?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UploadAssetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UploadAsset'] = ResolversParentTypes['UploadAsset'],
> = {
  AssetGUID?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  Path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['ObjectID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookup'] = ResolversParentTypes['UserLocationLookup'],
> = {
  carrier?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCarrier']>,
    ParentType,
    ContextType
  >;
  company?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCompany']>,
    ParentType,
    ContextType
  >;
  connection?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupConnection']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCurrency']>,
    ParentType,
    ContextType
  >;
  hostname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocation']>,
    ParentType,
    ContextType
  >;
  security?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupSecurity']>,
    ParentType,
    ContextType
  >;
  time_zone?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupTimeZone']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupCarrierResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupCarrier'] = ResolversParentTypes['UserLocationLookupCarrier'],
> = {
  mcc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mnc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupCompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupCompany'] = ResolversParentTypes['UserLocationLookupCompany'],
> = {
  domain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCompanyType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupConnection'] = ResolversParentTypes['UserLocationLookupConnection'],
> = {
  asn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  route?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupConnectionType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupCurrencyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupCurrency'] = ResolversParentTypes['UserLocationLookupCurrency'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCurrencyFormat']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name_native?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  plural?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plural_native?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol_native?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupCurrencyFormatResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupCurrencyFormat'] = ResolversParentTypes['UserLocationLookupCurrencyFormat'],
> = {
  negative?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCurrencyFormatPrefixSuffix']>,
    ParentType,
    ContextType
  >;
  positive?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupCurrencyFormatPrefixSuffix']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupCurrencyFormatPrefixSuffixResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupCurrencyFormatPrefixSuffix'] = ResolversParentTypes['UserLocationLookupCurrencyFormatPrefixSuffix'],
> = {
  prefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocation'] = ResolversParentTypes['UserLocationLookupLocation'],
> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  continent?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocationContinent']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocationCountry']>,
    ParentType,
    ContextType
  >;
  in_eu?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocationLanguage']>,
    ParentType,
    ContextType
  >;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  postal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocationRegion']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationContinentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocationContinent'] = ResolversParentTypes['UserLocationLookupLocationContinent'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationCountryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocationCountry'] = ResolversParentTypes['UserLocationLookupLocationCountry'],
> = {
  area?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  borders?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  calling_code?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  capital?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flag?: Resolver<
    Maybe<ResolversTypes['UserLocationLookupLocationCountryFlag']>,
    ParentType,
    ContextType
  >;
  languages?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserLocationLookupLocationLanguage']>>>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  population?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  population_density?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tld?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationCountryFlagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocationCountryFlag'] = ResolversParentTypes['UserLocationLookupLocationCountryFlag'],
> = {
  emoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emoji_unicode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  emojitwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twemoji?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wikimedia?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationLanguageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocationLanguage'] = ResolversParentTypes['UserLocationLookupLocationLanguage'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  native?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupLocationRegionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupLocationRegion'] = ResolversParentTypes['UserLocationLookupLocationRegion'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupSecurityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupSecurity'] = ResolversParentTypes['UserLocationLookupSecurity'],
> = {
  is_abuser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_anonymous?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_attacker?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_bogon?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_cloud_provider?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_proxy?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_relay?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_threat?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_tor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_tor_exit?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  is_vpn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocationLookupTimeZoneResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserLocationLookupTimeZone'] = ResolversParentTypes['UserLocationLookupTimeZone'],
> = {
  abbreviation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  current_time?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  in_daylight_saving?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Users'] = ResolversParentTypes['Users'],
> = {
  Active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  Asset?: Resolver<Maybe<ResolversTypes['Assets']>, ParentType, ContextType>;
  AssetID?: Resolver<Maybe<ResolversTypes['MongoID']>, ParentType, ContextType>;
  Country?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType
  >;
  CountryID?: Resolver<
    Maybe<ResolversTypes['MongoID']>,
    ParentType,
    ContextType
  >;
  FirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  LastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserGUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  UserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  _id?: Resolver<ResolversTypes['MongoID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersPaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UsersPagination'] = ResolversParentTypes['UsersPagination'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['Users']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<
    ResolversTypes['PaginationInfo'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UtcOffsetScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UtcOffset'], any> {
  name: 'UtcOffset';
}

export interface VoidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type Resolvers<ContextType = any> = {
  AccountNumber?: GraphQLScalarType;
  Activate?: ActivateResolvers<ContextType>;
  Activities?: ActivitiesResolvers<ContextType>;
  ActivitiesDescription?: ActivitiesDescriptionResolvers<ContextType>;
  ActivitiesPagination?: ActivitiesPaginationResolvers<ContextType>;
  AssetDataSourceValue?: GraphQLScalarType;
  Assets?: AssetsResolvers<ContextType>;
  AssetsPagination?: AssetsPaginationResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  Bookings?: BookingsResolvers<ContextType>;
  BookingsPagination?: BookingsPaginationResolvers<ContextType>;
  Byte?: GraphQLScalarType;
  ChatCompletionTokenLogprob?: ChatCompletionTokenLogprobResolvers<ContextType>;
  Countries?: CountriesResolvers<ContextType>;
  CountriesData?: CountriesDataResolvers<ContextType>;
  CountryCode?: GraphQLScalarType;
  CreateManyActivitiesPayload?: CreateManyActivitiesPayloadResolvers<ContextType>;
  CreateManyAssetsPayload?: CreateManyAssetsPayloadResolvers<ContextType>;
  CreateManyBookingsPayload?: CreateManyBookingsPayloadResolvers<ContextType>;
  CreateManyGeoLocationsPayload?: CreateManyGeoLocationsPayloadResolvers<ContextType>;
  CreateManyGuidesPayload?: CreateManyGuidesPayloadResolvers<ContextType>;
  CreateManyPhonesPayload?: CreateManyPhonesPayloadResolvers<ContextType>;
  CreateManySchedulesPayload?: CreateManySchedulesPayloadResolvers<ContextType>;
  CreateManyUsersPayload?: CreateManyUsersPayloadResolvers<ContextType>;
  CreateOneActivitiesPayload?: CreateOneActivitiesPayloadResolvers<ContextType>;
  CreateOneAssetsPayload?: CreateOneAssetsPayloadResolvers<ContextType>;
  CreateOneBookingsPayload?: CreateOneBookingsPayloadResolvers<ContextType>;
  CreateOneGeoLocationsPayload?: CreateOneGeoLocationsPayloadResolvers<ContextType>;
  CreateOneGuidesPayload?: CreateOneGuidesPayloadResolvers<ContextType>;
  CreateOnePhonesPayload?: CreateOnePhonesPayloadResolvers<ContextType>;
  CreateOneSchedulesPayload?: CreateOneSchedulesPayloadResolvers<ContextType>;
  CreateOneUsersPayload?: CreateOneUsersPayloadResolvers<ContextType>;
  Cuid?: GraphQLScalarType;
  Currency?: GraphQLScalarType;
  DID?: GraphQLScalarType;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DateTimeISO?: GraphQLScalarType;
  DeweyDecimal?: GraphQLScalarType;
  Duration?: GraphQLScalarType;
  EmailAddress?: GraphQLScalarType;
  FunctionCall?: FunctionCallResolvers<ContextType>;
  GUID?: GraphQLScalarType;
  GeoLocations?: GeoLocationsResolvers<ContextType>;
  GeoLocationsAnnotations?: GeoLocationsAnnotationsResolvers<ContextType>;
  GeoLocationsAnnotationsCurrency?: GeoLocationsAnnotationsCurrencyResolvers<ContextType>;
  GeoLocationsAnnotationsDMS?: GeoLocationsAnnotationsDmsResolvers<ContextType>;
  GeoLocationsAnnotationsMercator?: GeoLocationsAnnotationsMercatorResolvers<ContextType>;
  GeoLocationsAnnotationsNUTS?: GeoLocationsAnnotationsNutsResolvers<ContextType>;
  GeoLocationsAnnotationsNUTSNUTS0?: GeoLocationsAnnotationsNutsnuts0Resolvers<ContextType>;
  GeoLocationsAnnotationsNUTSNUTS1?: GeoLocationsAnnotationsNutsnuts1Resolvers<ContextType>;
  GeoLocationsAnnotationsNUTSNUTS2?: GeoLocationsAnnotationsNutsnuts2Resolvers<ContextType>;
  GeoLocationsAnnotationsNUTSNUTS3?: GeoLocationsAnnotationsNutsnuts3Resolvers<ContextType>;
  GeoLocationsAnnotationsOSM?: GeoLocationsAnnotationsOsmResolvers<ContextType>;
  GeoLocationsAnnotationsRoadinfo?: GeoLocationsAnnotationsRoadinfoResolvers<ContextType>;
  GeoLocationsAnnotationsSun?: GeoLocationsAnnotationsSunResolvers<ContextType>;
  GeoLocationsAnnotationsSunRise?: GeoLocationsAnnotationsSunRiseResolvers<ContextType>;
  GeoLocationsAnnotationsSunSet?: GeoLocationsAnnotationsSunSetResolvers<ContextType>;
  GeoLocationsAnnotationsTimezone?: GeoLocationsAnnotationsTimezoneResolvers<ContextType>;
  GeoLocationsAnnotationsUN_M49?: GeoLocationsAnnotationsUn_M49Resolvers<ContextType>;
  GeoLocationsAnnotationsUN_M49Regions?: GeoLocationsAnnotationsUn_M49RegionsResolvers<ContextType>;
  GeoLocationsAnnotationsWhat3words?: GeoLocationsAnnotationsWhat3wordsResolvers<ContextType>;
  GeoLocationsBounds?: GeoLocationsBoundsResolvers<ContextType>;
  GeoLocationsBoundsNortheast?: GeoLocationsBoundsNortheastResolvers<ContextType>;
  GeoLocationsBoundsSouthwest?: GeoLocationsBoundsSouthwestResolvers<ContextType>;
  GeoLocationsComponents?: GeoLocationsComponentsResolvers<ContextType>;
  GeoLocationsComponentsISO?: GeoLocationsComponentsIsoResolvers<ContextType>;
  GeoLocationsComponentsISOV1?: GeoLocationsComponentsIsov1Resolvers<ContextType>;
  GeoLocationsGeometry?: GeoLocationsGeometryResolvers<ContextType>;
  GeoLocationsPagination?: GeoLocationsPaginationResolvers<ContextType>;
  Guides?: GuidesResolvers<ContextType>;
  GuidesPagination?: GuidesPaginationResolvers<ContextType>;
  HSL?: GraphQLScalarType;
  HSLA?: GraphQLScalarType;
  HexColorCode?: GraphQLScalarType;
  Hexadecimal?: GraphQLScalarType;
  IBAN?: GraphQLScalarType;
  IP?: GraphQLScalarType;
  IPCPatent?: GraphQLScalarType;
  IPv4?: GraphQLScalarType;
  IPv6?: GraphQLScalarType;
  ISBN?: GraphQLScalarType;
  ISO8601Duration?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  JWT?: GraphQLScalarType;
  LCCSubclass?: GraphQLScalarType;
  Latitude?: GraphQLScalarType;
  LocalDate?: GraphQLScalarType;
  LocalDateTime?: GraphQLScalarType;
  LocalEndTime?: GraphQLScalarType;
  LocalTime?: GraphQLScalarType;
  Locale?: GraphQLScalarType;
  Logprobs?: LogprobsResolvers<ContextType>;
  Long?: GraphQLScalarType;
  Longitude?: GraphQLScalarType;
  MAC?: GraphQLScalarType;
  Message?: MessageResolvers<ContextType>;
  MongoID?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  NegativeFloat?: GraphQLScalarType;
  NegativeInt?: GraphQLScalarType;
  NonEmptyString?: GraphQLScalarType;
  NonNegativeFloat?: GraphQLScalarType;
  NonNegativeInt?: GraphQLScalarType;
  NonPositiveFloat?: GraphQLScalarType;
  NonPositiveInt?: GraphQLScalarType;
  ObjectID?: GraphQLScalarType;
  PaginationInfo?: PaginationInfoResolvers<ContextType>;
  PhoneNumber?: GraphQLScalarType;
  Phones?: PhonesResolvers<ContextType>;
  PhonesPagination?: PhonesPaginationResolvers<ContextType>;
  Port?: GraphQLScalarType;
  PositiveFloat?: GraphQLScalarType;
  PositiveInt?: GraphQLScalarType;
  PostalCode?: GraphQLScalarType;
  Profile?: ProfileResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RGB?: GraphQLScalarType;
  RGBA?: GraphQLScalarType;
  RefreshToken?: RefreshTokenResolvers<ContextType>;
  RegExpAsString?: GraphQLScalarType;
  Register?: RegisterResolvers<ContextType>;
  RemoveManyActivitiesPayload?: RemoveManyActivitiesPayloadResolvers<ContextType>;
  RemoveManyAssetsPayload?: RemoveManyAssetsPayloadResolvers<ContextType>;
  RemoveManyBookingsPayload?: RemoveManyBookingsPayloadResolvers<ContextType>;
  RemoveManyGeoLocationsPayload?: RemoveManyGeoLocationsPayloadResolvers<ContextType>;
  RemoveManyGuidesPayload?: RemoveManyGuidesPayloadResolvers<ContextType>;
  RemoveManyPhonesPayload?: RemoveManyPhonesPayloadResolvers<ContextType>;
  RemoveManySchedulesPayload?: RemoveManySchedulesPayloadResolvers<ContextType>;
  RemoveManyUsersPayload?: RemoveManyUsersPayloadResolvers<ContextType>;
  RemoveOneActivitiesPayload?: RemoveOneActivitiesPayloadResolvers<ContextType>;
  RemoveOneAssetsPayload?: RemoveOneAssetsPayloadResolvers<ContextType>;
  RemoveOneBookingsPayload?: RemoveOneBookingsPayloadResolvers<ContextType>;
  RemoveOneGeoLocationsPayload?: RemoveOneGeoLocationsPayloadResolvers<ContextType>;
  RemoveOneGuidesPayload?: RemoveOneGuidesPayloadResolvers<ContextType>;
  RemoveOnePhonesPayload?: RemoveOnePhonesPayloadResolvers<ContextType>;
  RemoveOneSchedulesPayload?: RemoveOneSchedulesPayloadResolvers<ContextType>;
  RemoveOneUsersPayload?: RemoveOneUsersPayloadResolvers<ContextType>;
  RoutingNumber?: GraphQLScalarType;
  SESSN?: GraphQLScalarType;
  SafeInt?: GraphQLScalarType;
  Schedules?: SchedulesResolvers<ContextType>;
  SchedulesPagination?: SchedulesPaginationResolvers<ContextType>;
  SchedulesRanges?: SchedulesRangesResolvers<ContextType>;
  SemVer?: GraphQLScalarType;
  SignIn?: SignInResolvers<ContextType>;
  SignOut?: SignOutResolvers<ContextType>;
  SocialSignIn?: SocialSignInResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Suggestion?: SuggestionResolvers<ContextType>;
  Time?: GraphQLScalarType;
  TimeZone?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  TopLogprob?: TopLogprobResolvers<ContextType>;
  URL?: GraphQLScalarType;
  USCurrency?: GraphQLScalarType;
  UUID?: GraphQLScalarType;
  UnsignedFloat?: GraphQLScalarType;
  UnsignedInt?: GraphQLScalarType;
  UpdateManyActivitiesPayload?: UpdateManyActivitiesPayloadResolvers<ContextType>;
  UpdateManyAssetsPayload?: UpdateManyAssetsPayloadResolvers<ContextType>;
  UpdateManyBookingsPayload?: UpdateManyBookingsPayloadResolvers<ContextType>;
  UpdateManyGeoLocationsPayload?: UpdateManyGeoLocationsPayloadResolvers<ContextType>;
  UpdateManyGuidesPayload?: UpdateManyGuidesPayloadResolvers<ContextType>;
  UpdateManyPhonesPayload?: UpdateManyPhonesPayloadResolvers<ContextType>;
  UpdateManySchedulesPayload?: UpdateManySchedulesPayloadResolvers<ContextType>;
  UpdateManyUsersPayload?: UpdateManyUsersPayloadResolvers<ContextType>;
  UpdateOneActivitiesPayload?: UpdateOneActivitiesPayloadResolvers<ContextType>;
  UpdateOneAssetsPayload?: UpdateOneAssetsPayloadResolvers<ContextType>;
  UpdateOneBookingsPayload?: UpdateOneBookingsPayloadResolvers<ContextType>;
  UpdateOneGeoLocationsPayload?: UpdateOneGeoLocationsPayloadResolvers<ContextType>;
  UpdateOneGuidesPayload?: UpdateOneGuidesPayloadResolvers<ContextType>;
  UpdateOnePhonesPayload?: UpdateOnePhonesPayloadResolvers<ContextType>;
  UpdateOneSchedulesPayload?: UpdateOneSchedulesPayloadResolvers<ContextType>;
  UpdateOneUsersPayload?: UpdateOneUsersPayloadResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UploadAsset?: UploadAssetResolvers<ContextType>;
  UserLocationLookup?: UserLocationLookupResolvers<ContextType>;
  UserLocationLookupCarrier?: UserLocationLookupCarrierResolvers<ContextType>;
  UserLocationLookupCompany?: UserLocationLookupCompanyResolvers<ContextType>;
  UserLocationLookupConnection?: UserLocationLookupConnectionResolvers<ContextType>;
  UserLocationLookupCurrency?: UserLocationLookupCurrencyResolvers<ContextType>;
  UserLocationLookupCurrencyFormat?: UserLocationLookupCurrencyFormatResolvers<ContextType>;
  UserLocationLookupCurrencyFormatPrefixSuffix?: UserLocationLookupCurrencyFormatPrefixSuffixResolvers<ContextType>;
  UserLocationLookupLocation?: UserLocationLookupLocationResolvers<ContextType>;
  UserLocationLookupLocationContinent?: UserLocationLookupLocationContinentResolvers<ContextType>;
  UserLocationLookupLocationCountry?: UserLocationLookupLocationCountryResolvers<ContextType>;
  UserLocationLookupLocationCountryFlag?: UserLocationLookupLocationCountryFlagResolvers<ContextType>;
  UserLocationLookupLocationLanguage?: UserLocationLookupLocationLanguageResolvers<ContextType>;
  UserLocationLookupLocationRegion?: UserLocationLookupLocationRegionResolvers<ContextType>;
  UserLocationLookupSecurity?: UserLocationLookupSecurityResolvers<ContextType>;
  UserLocationLookupTimeZone?: UserLocationLookupTimeZoneResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  UsersPagination?: UsersPaginationResolvers<ContextType>;
  UtcOffset?: GraphQLScalarType;
  Void?: GraphQLScalarType;
};
