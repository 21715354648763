import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import {
  Query,
  QueryCountriesArgs,
  SortFindManyCountriesInput,
} from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Countries'>;

type Props = Parameters<typeof useQuery<Type, QueryCountriesArgs>>[1];

const { Countries } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useCountries = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<
    Type,
    QueryCountriesArgs
  >(Countries, {
    ...DEFAULT_PROPS,
    ...props,
  });

  return { data, previousData, loading, error };
};

export { useCountries, type Type, type Props, SortFindManyCountriesInput };
export default Countries;
