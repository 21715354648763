import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationRegisterArgs } from '@/API/Client/Types/graphql';

import useMutation from './useMutation';

type Type = Pick<Mutation, 'Register'>;

type Props = Parameters<typeof useMutation<Type, MutationRegisterArgs>>[1];

const { Register } = Schema;

const useRegister = (props?: Props) => {
  const mutation = useMutation<Type, MutationRegisterArgs>(Register, props);

  return mutation;
};

export { useRegister, type Type, type Props };
export default Register;
