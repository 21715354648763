import React from 'react';

// Components
import { Text } from '@/Components';

const CLASS_NAME = 'loci--widgets--global-footer--copy-right';

const GlobalFooter: React.FunctionComponent = () => {
  return (
    <Text className={CLASS_NAME}>{`© ${new Date().getFullYear()} Loci`}</Text>
  );
};

export default GlobalFooter;
