import React, { useEffect } from 'react';

// Routes
import Router, {
  ErrorElement,
  HttpStatus,
  Outlet,
  Route,
  ScrollRestoration,
  useLocation,
} from '@/Router';

// Widgets
import {
  GlobalHeader,
  GlobalHeaderProvider,
  GlobalFooter,
  GlobalFooterProvider,
} from '@/Widgets';

// Components
import { Layout } from '@/Components';

// Hooks
import { useResponsive } from '@/Hooks';

// Views
import Activities from './Activities';
import Authentication from './Authentication';
import Home from './Home';
import Privacy from './Privacy';
import TermsOfService from './TermsOfService';
import User from './User';

// Styles
import './Styles.scss';

const Contents: React.FunctionComponent = () => {
  return (
    <>
      <ScrollRestoration />
      <GlobalHeader />
      <Layout
        alignContent='start'
        alignItems='start'
        gap='none'
        justifyContent='stretch'
        justifyItems='center'
      >
        <main className='loci--view'>
          <Outlet />
        </main>
      </Layout>
      <GlobalFooter />
    </>
  );
};

const Element: React.FunctionComponent = () => {
  useResponsive();

  const location = useLocation();

  useEffect(() => {
    const root = document.querySelector('body');

    if (!root) {
      return;
    }

    const routes = (location.pathname.replace('/', '') || 'home').split('/');

    root.dataset.routes = routes.join('.');

    document.title = `Loci - Your Local Tour Guide | ${routes.join(' | ')}`;
  });

  return (
    <GlobalHeaderProvider>
      <GlobalFooterProvider>
        <Contents />
      </GlobalFooterProvider>
    </GlobalHeaderProvider>
  );
};

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Route path='*' errorElement={<ErrorElement />} element={<Element />}>
        <Route path='*' element={<HttpStatus.Status404 />} />
        {Activities}
        {Authentication}
        {Home}
        {Privacy}
        {TermsOfService}
        {User}
      </Route>
    </Router>
  );
};

export default App;
