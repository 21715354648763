import React, { useEffect, useState } from 'react';

// Libraries
import classNames from 'classnames';

// Analytic
import * as Analytic from '@/Analytic';

// Hooks
import { useResponsive } from '@/Hooks';

// Constants
import { CLASS_NAME } from '@/Components/Form/Input';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const Only = React.forwardRef<HTMLInputElement, Spec.Props>(
  (
    {
      className: _className,
      disabled,
      id,
      name,
      onFocus: focusHandler,
      type: _type = 'text',
      unstyled = false,
      ...rest
    },
    ref
  ) => {
    const { isTablet } = useResponsive();

    const [type, setType] = useState(_type);

    useEffect(() => {
      if (_type === type) {
        return;
      }

      setType(_type);
    }, [_type]);

    const className = classNames(
      {
        'loci-font-size-small': isTablet,
      },
      CLASS_NAME,
      {
        [`${CLASS_NAME}--${type}`]: type,
        [`${CLASS_NAME}--unstyled`]: unstyled,
        [`${CLASS_NAME}--disabled`]: disabled,
      },
      _className
    );

    const onFocus: Spec.Props['onFocus'] = (event) => {
      Analytic.onFocus(event);

      focusHandler?.(event);
    };

    return (
      <input
        {...rest}
        className={className}
        disabled={disabled}
        id={id || name}
        name={name}
        onFocus={onFocus}
        ref={ref}
        type={type}
      />
    );
  }
);

type OnlyProps = Spec.Props;

Only.displayName = 'Input.Only';

export { type OnlyProps };
export default Only;
