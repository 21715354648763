import React, { Suspense } from 'react';

// Router
import { Route as Origin } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Constants
import { ACTIVATE_PATH } from '@/Views/Authentication/constants';

const Contents = React.lazy(() => import('./Contents'));

export default (
  <Origin
    path={ACTIVATE_PATH}
    element={
      <Suspense fallback={<Spinner position='inline' />}>
        <Contents />
      </Suspense>
    }
  />
);
