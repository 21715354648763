import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// Libraries
import classNames from 'classnames';

// Router
import { useLocation } from '@/Router';

// Views
import { PATH as HOME_PATH } from '@/Views/Home';

// Widgets
import { SiteLogo } from '@/Widgets';

// Hooks
import { useResponsive } from '@/Hooks';

// Components
import { Animation, Layout } from '@/Components';

// Partials
import Navigation from './Navigation';

// Context
import GlobalHeaderProvider, { useGlobalHeaderContext } from './Context';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--widgets--global-header';

const HIDDEN_PATHS = [`/${HOME_PATH}`];

const Contents: React.FunctionComponent = () => {
  const { node, showHeader } = useGlobalHeaderContext();

  const location = useLocation();

  const { isTablet } = useResponsive();

  const shouldHide = HIDDEN_PATHS.includes(location.pathname);

  useEffect(() => {
    showHeader(!shouldHide);
  });

  const className = classNames('loci-font-size-small', CLASS_NAME);

  return (
    <Layout
      alignItems='center'
      autoFlow='column'
      gap={isTablet ? 'wider' : 'normal'}
      justifyContent='space-between'
      ref={node}
    >
      <header className={className} role='banner'>
        <SiteLogo className='loci-font-size' withIcon withName />
        <Navigation />
      </header>
    </Layout>
  );
};

const GlobalHeader: React.FunctionComponent = () => {
  const { rect, show, showHeader } = useGlobalHeaderContext();

  const location = useLocation();

  const shouldHide = HIDDEN_PATHS.includes(location.pathname);

  useEffect(() => {
    showHeader(!shouldHide);
  });

  const animationDuration = shouldHide ? 'instant' : undefined;

  return (
    <>
      {ReactDOM.createPortal(
        <style data-widget-global-header-uuid={`${CLASS_NAME}--css-variables`}>
          {`:root {
              --${CLASS_NAME}--block-size: ${rect?.height || 0}px;
            }`}
        </style>,
        window.document.body
      )}
      <Animation
        animationStyle='slide-from-top'
        in={show}
        animationDuration={animationDuration}
      >
        <Contents />
      </Animation>
    </>
  );
};

export { GlobalHeaderProvider, useGlobalHeaderContext };
export default GlobalHeader;
