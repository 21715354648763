import React, { PropsWithChildren, Suspense } from 'react';

// API
import { useUserInfo } from '@/API/Client';

// Router
import { Navigate, NavigateProps, useLocation } from '@/Router';

// Components
import { Spinner } from '@/Components';

type Props = {
  secured?: boolean;
};

const PATHNAME = '/authentication';

const ProtectedRoute: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  secured = true,
}) => {
  const { userInfo, isContinuance } = useUserInfo();

  const location = useLocation();

  const url = new URL(location.pathname, window.location.origin);

  url.hash = location.hash;
  url.search = location.search;

  const to: NavigateProps['to'] = {
    pathname: PATHNAME,
  };

  const state = {
    ...location.state,
    view: `${url.pathname}${url.search || url.hash}`,
  };

  if (secured && (!userInfo || !isContinuance)) {
    return (
      <Suspense fallback={<Spinner />}>
        {children}
        <Navigate to={to} state={state} replace />
      </Suspense>
    );
  }

  return <>{children}</>;
};

export { type Props };

export default ProtectedRoute;
