import { Mutation as Schema } from '@/API/Client/Schema';
import {
  Mutation,
  MutationPredictActivityArgs,
} from '@/API/Client/Types/graphql';

import useMutation from './useMutation';

type Type = Pick<Mutation, 'PredictActivity'>;

type Props = Parameters<
  typeof useMutation<Type, MutationPredictActivityArgs>
>[1];

const { PredictActivity } = Schema;

const usePredictActivity = (props?: Props) => {
  const mutation = useMutation<Type, MutationPredictActivityArgs>(
    PredictActivity,
    props
  );

  return mutation;
};

export { usePredictActivity, type Type, type Props };
export default PredictActivity;
