import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import {
  Query,
  QueryActivitiesArgs,
  SortFindManyActivitiesInput,
} from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Activities'>;

type Props = Parameters<typeof useQuery<Type, QueryActivitiesArgs>>[1];

const { Activities } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useActivities = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<
    Type,
    QueryActivitiesArgs
  >(Activities, {
    ...DEFAULT_PROPS,
    ...props,
  });

  return { data, previousData, loading, error };
};

export { useActivities, type Type, type Props, SortFindManyActivitiesInput };
export default Activities;
