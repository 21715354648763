import React, { PropsWithChildren } from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--label';

const Label: React.FunctionComponent<PropsWithChildren<Spec.Props>> = ({
  children,
  className: _className,
  ...props
}) => {
  const className = classNames('loci-font-size-small', CLASS_NAME, _className);

  return (
    <label {...props} className={className}>
      {children}
    </label>
  );
};

type LabelProps = Spec.Props;

export { type LabelProps };
export default Label;
