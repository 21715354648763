import React, { Suspense } from 'react';

// Routes
import {
  Route as Origin,
  Navigate,
  NavigateProps,
  useLocation,
  useSearchParams,
} from '@/Router';

// Views
import Activity from './Activity';
import Generate from './Generate';

// Components
import { Spinner } from '@/Components';

const PATH = 'activities/*';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  const location = useLocation();
  const [search] = useSearchParams();

  if (location.state?.page || location.state?.search) {
    const searchParams = new URLSearchParams();

    if (location.state?.page && search.get('page') !== location.state?.page) {
      searchParams.set('page', location.state.page);
    }

    if (
      location.state?.search &&
      search.get('search') !== location.state?.search
    ) {
      searchParams.set('search', location.state.search);
    }

    const to: NavigateProps['to'] = {
      pathname: location.pathname,
      search: searchParams.toString(),
    };

    const state: NavigateProps['state'] = {};

    if (location.state?.ActivityGUID) {
      state.ActivityGUID = location.state.ActivityGUID;
    }

    return <Navigate replace to={to} state={state} />;
  }

  return (
    <Suspense fallback={<Spinner animationDuration='instant' />}>
      <Contents />
    </Suspense>
  );
};

export { PATH };
export default (
  <Origin path={PATH}>
    {Activity}
    {Generate}
    <Origin element={<Lazy />} index />
  </Origin>
);
