import React, { Suspense } from 'react';

// Router
import { Route as Origin } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Constants
import { SOCIAL_PATH } from '@/Views/Authentication/constants';

// Partials
import Callback from './Callback';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner position='inline' />}>
      <Contents />
    </Suspense>
  );
};

export { SOCIAL_PATH };
export default (
  <Origin path={SOCIAL_PATH}>
    {Callback}
    <Origin path='' element={<Lazy />} />
  </Origin>
);
