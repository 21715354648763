import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationSignInArgs } from '@/API/Client/Types/graphql';

import useMutation from './useMutation';

type Type = Pick<Mutation, 'SignIn'>;

type Props = Parameters<typeof useMutation<Type, MutationSignInArgs>>[1];

const { SignIn } = Schema;

const useSignIn = (props?: Props) => {
  const mutation = useMutation<Type, MutationSignInArgs>(SignIn, props);

  return mutation;
};

export { useSignIn, type Type, type Props };
export default SignIn;
