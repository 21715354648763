import React, { PropsWithChildren, useContext, useState } from 'react';

// Hooks
import { useResizeObserver } from '@/Hooks';

// Spec
import * as Spec from './Spec';

const DEFAULT: Spec.Context &
  Spec.Props &
  ReturnType<typeof useResizeObserver> = {
  isFullScreenMode: false,
  isStickyMode: false,
  isThemeDark: false,
  setFullScreenMode(_) {
    return _;
  },
  setStickyMode(_) {
    return _;
  },
  setThemeDark(_) {
    return _;
  },
  node: { current: null },
  rect: undefined,
};

const Context = React.createContext(DEFAULT);

const GlobalFooterProvider: React.FunctionComponent<
  PropsWithChildren<Spec.Props>
> = ({ children, isFullScreenMode: _isFullScreenMode = false }) => {
  const { node, rect } = useResizeObserver<HTMLElement>();

  const [isFullScreenMode, setFullScreenMode] =
    useState<boolean>(_isFullScreenMode);

  const [isStickyMode, setStickyMode] = useState<boolean>(false);

  const [isThemeDark, setThemeDark] = useState<boolean>(false);

  const value = {
    isFullScreenMode,
    isStickyMode,
    isThemeDark,
    setFullScreenMode,
    setStickyMode,
    setThemeDark,
    node,
    rect,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useGlobalFooterContext = () => {
  const Contexts = useContext(Context);

  return Contexts;
};

export { useGlobalFooterContext };
export default GlobalFooterProvider;
