(() => {
  // rename document.cookie to document._cookie, and redefine document.cookie
  const CACHE = '_cookie';

  let lastCookie = document.cookie;

  const nativeCookieDesc = Object.getOwnPropertyDescriptor(
    Document.prototype,
    'cookie'
  );

  Object.defineProperty(Document.prototype, CACHE, nativeCookieDesc || {});

  Object.defineProperty(Document.prototype, 'cookie', {
    enumerable: true,
    configurable: true,
    get() {
      return this[CACHE];
    },
    set(value) {
      this[CACHE] = value;
      // check cookie change
      const cookie = this[CACHE];

      if (cookie === lastCookie) {
        return;
      }

      try {
        // dispatch cookie-change messages to other same-origin tabs/frames
        const detail = { lastCookie, cookie };

        this.dispatchEvent(
          new CustomEvent('cookie-change', {
            detail,
          })
        );

        channel.postMessage(detail);
      } finally {
        lastCookie = cookie;
      }
    },
  });

  // listen cookie-change messages from other same-origin tabs/frames
  const channel = new BroadcastChannel('cookie-channel');

  channel.onmessage = (event) => {
    lastCookie = event.data.newValue;
    document.dispatchEvent(
      new CustomEvent('cookie-change', {
        detail: event.data,
      })
    );
  };
})();

export default {};
