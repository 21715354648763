import React, { PropsWithChildren, useContext } from 'react';

// Router
import { useSearchParams, useLocation } from '@/Router';

// API
import { Query, Mutation } from '@/API/Client';

// LocalStorage
import { useLocalStorageContext, getItem } from '@/LocalStorage';

// Components
import { FormProps } from '@/Components';

const ITEMS_PER_PAGE = 12;

const SEARCH_PARAMS = 'Activity';

const LOCAL_STORAGE = 'FlavoredActivities';

type Variables = Exclude<Query.ActivitiesProps, undefined>['variables'];

type Props = ReturnType<typeof Query.useActivities> &
  ReturnType<typeof Query.useProfile> &
  Omit<ReturnType<typeof Mutation.usePredictActivity>, 'client'> &
  Pick<FormProps, 'onChange' | 'onReset' | 'onSubmit'> & {
    location: ReturnType<typeof useLocation>;
    predicting: ReturnType<typeof Mutation.usePredictActivity>['loading'];
    variables: Variables;
    PredictActivity?: Exclude<
      | ReturnType<typeof Mutation.usePredictActivity>['data']
      | ReturnType<typeof Mutation.usePredictActivity>['previousData'],
      null | undefined
    >['PredictActivity'];
    [SEARCH_PARAMS]?: string | null;
    [LOCAL_STORAGE]: ReturnType<typeof getItem<string[]>>;
  };

const searchParams = new window.URLSearchParams();

const DEFAULT: Props = {
  data: {},
  error: undefined,
  loading: false,
  location: {
    ...window.location,
    state: {},
    key: '',
  },
  predicting: false,
  previousData: {},
  called: false,
  mutate() {
    return Promise.resolve({});
  },
  reset() {},
  variables: undefined,
  PredictActivity: undefined,
  [SEARCH_PARAMS]: searchParams.get(SEARCH_PARAMS),
  [LOCAL_STORAGE]: JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE) || '[]'
  ),
};

const Context = React.createContext(DEFAULT);

const GenerateProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const LocalStorageContext = useLocalStorageContext();

  const FlavoredActivities =
    LocalStorageContext.getItem<string[] | undefined>(LOCAL_STORAGE) || [];

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get(SEARCH_PARAMS);

  const variables: Variables = {
    filter: search ? { Search: search.trim() } : {},
    sort: Query.ENUM.SortFindManyActivitiesInput.TITLE_ASC,
    perPage: ITEMS_PER_PAGE,
  };

  const Activities = Query.useActivities({
    variables,
  });

  const Profile = Query.useProfile();

  const { client, mutate, ...rest } = Mutation.usePredictActivity();

  const data = {
    Activities: Activities.data?.Activities,
    Profile: Profile.data?.Profile,
    PredictActivity: rest.data?.PredictActivity,
  };

  const error = Activities.error || Profile.error || rest.error || undefined;

  const loading = Activities.loading || Profile.loading || rest.loading;

  const predicting = rest.loading;

  const previousData = {
    Activities: Activities.previousData?.Activities,
    Profile: Profile.previousData?.Profile,
    PredictActivity: rest.previousData?.PredictActivity,
  };

  const location = useLocation();

  const onChange: FormProps['onChange'] = (event) => {
    const data = new FormData(event.currentTarget);

    const search = data.get(SEARCH_PARAMS);

    setSearchParams({
      [SEARCH_PARAMS]: String(search || ''),
    });
  };

  const onReset: FormProps['onReset'] = () => {
    setSearchParams((searchParams) => {
      searchParams.delete(SEARCH_PARAMS);

      return searchParams;
    });
  };

  const onSubmit: FormProps['onSubmit'] = async () => {
    if (!search) {
      return;
    }

    await mutate({
      variables: {
        [SEARCH_PARAMS]: search,
      },
    });
  };

  const PredictActivity =
    data?.PredictActivity || previousData?.PredictActivity;

  const value = {
    ...rest,
    variables,
    previousData,
    predicting,
    onSubmit,
    onReset,
    onChange,
    mutate,
    location,
    loading,
    error,
    data,
    PredictActivity,
    [SEARCH_PARAMS]: search,
    [LOCAL_STORAGE]: FlavoredActivities,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useGenerateContext = () => {
  const Contexts = useContext(Context);

  return Contexts;
};

export {
  type Props,
  DEFAULT,
  ITEMS_PER_PAGE,
  SEARCH_PARAMS,
  useGenerateContext,
};
export default GenerateProvider;
