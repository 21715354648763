import { instance, useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationRefreshTokenArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'RefreshToken'>;

type Props = Parameters<typeof useMutation<Type, MutationRefreshTokenArgs>>[1];

const { RefreshToken } = Schema;

const useRefreshToken = (props?: Props) => {
  const mutation = useMutation<Type, MutationRefreshTokenArgs>(
    RefreshToken,
    props
  );

  return mutation;
};

const getRefreshToken = async (props?: Props) => {
  const result = (await instance.mutate({
    ...props,
    mutation: RefreshToken,
  })) as ReturnType<typeof useRefreshToken>;

  return result;
};

export { getRefreshToken, useRefreshToken, type Type, type Props };
export default RefreshToken;
