import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationActivateArgs } from '@/API/Client/Types/graphql';

import useMutation from './useMutation';

type Type = Pick<Mutation, 'Activate'>;

type Props = Parameters<typeof useMutation<Type, MutationActivateArgs>>[1];

const { Activate } = Schema;

const useActivate = (props?: Props) => {
  const mutation = useMutation<Type, MutationActivateArgs>(Activate, props);

  return mutation;
};

export { useActivate, type Type, type Props };
export default Activate;
