import React, { useState } from 'react';

// CloudFlare
import * as CloudFlare from '@/API/CloudFlare';

// GraphQL
import { Query } from '@/API/Client';
import { EnumPhonesCountryCode } from '@/API/Client/Types/graphql';

// Components
import { FormElements } from '@/Components';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--phone--country-calling-code';

const { ip } = await CloudFlare.CGI.trace();

const CountryCallingCode: React.FunctionComponent<Spec.Props> = ({
  after,
  before,
  disabled,
  loading,
  CallingCode,
  CountryCode,
  ...rest
}) => {
  const Countries = Query.useCountries({
    variables: {
      sort: Query.ENUM.SortFindManyCountriesInput.DATA_ASC,
      limit: -1,
    },
  });

  const UserLocationLookup = Query.useUserLocationLookup({
    variables: {
      UserLocationLookup: { ip },
    },
  });

  const options: FormElements.SelectProps['options'] =
    Countries.data?.Countries.map((Country) => ({
      'data-country-calling-code': Country.Data.countryCallingCode,
      label: `${Country.Data.flag} +${Country.Data.countryCallingCode}`,
      value: Country.Data.countryCode || undefined,
    })).sort((first, last) => {
      if (!first.value || !last.value) {
        return 0;
      }

      if (first.value < last.value) {
        return -1;
      }

      if (first.value > last.value) {
        return 1;
      }

      return 0;
    });

  const [changedCountryCode, setCountryCode] = useState<string>();

  const selected: FormElements.SelectProps['selected'] = ({
    option,
    value,
  }) => {
    const selected =
      option.value ===
      (changedCountryCode ||
        CountryCode ||
        UserLocationLookup.data?.UserLocationLookup?.location?.country?.code ||
        value);

    return selected;
  };

  const option = options?.find((option) =>
    selected({ option, value: CountryCode || option.value })
  );

  const onChange: FormElements.SelectProps['onChange'] = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <>
      <FormElements.Select.Only
        {...rest}
        className={CLASS_NAME}
        defaultValue={option?.value}
        disabled={disabled}
        name='Phone.CountryCode'
        onChange={onChange}
        options={options}
        selected={selected}
        unstyled
      />
      <FormElements.Input.Only
        defaultValue={option?.['data-country-calling-code']}
        disabled={disabled}
        name='Phone.CallingCode'
        type='hidden'
        unstyled
      />
    </>
  );
};

type CountryCallingCodeProps = Spec.Props;

export { type CountryCallingCodeProps, EnumPhonesCountryCode };
export default CountryCallingCode;
