const PATH = 'authentication';

const ACTIVATE_PATH = 'activate';
const CALLBACK_PATH = 'callback';
const PROVIDER_PATH = ':Provider';
const SIGN_OUT_PATH = 'sign-out';
const SOCIAL_PATH = 'social';

export {
  PATH,
  ACTIVATE_PATH,
  CALLBACK_PATH,
  PROVIDER_PATH,
  SIGN_OUT_PATH,
  SOCIAL_PATH,
};
