import React from 'react';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--widgets--autocomplete';

const Custom = React.lazy(() => import('./Custom'));
const Default = React.lazy(() => import('./Default'));

const Autocomplete: React.FunctionComponent<Spec.Props> = ({
  placeholder = 'Search...',
  resettable = true,
  ...props
}: Spec.Props) => {
  const { component } = props;

  let Component = Default;

  if (component) {
    Component = Custom;
  }

  return (
    <div className={CLASS_NAME}>
      <Component
        {...props}
        component={component}
        placeholder={placeholder}
        resettable={resettable}
      />
    </div>
  );
};

type AutocompleteProps<Extended extends object = object> = Spec.Props<Extended>;
type AutocompleteData = Spec.Data;

export { type AutocompleteProps, type AutocompleteData };
export default Autocomplete;
