import React, { Suspense } from 'react';

// Router
import { Route as Origin } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Constants
import { CALLBACK_PATH } from '@/Views/Authentication/constants';

// Partials
import Provider from './Provider';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner position='inline' />}>
      <Contents />
    </Suspense>
  );
};

export { CALLBACK_PATH };
export default (
  <Origin path={CALLBACK_PATH}>
    {Provider}
    <Origin path='' element={<Lazy />} />
  </Origin>
);
