import React, { useState } from 'react';

// Libraries
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

// Components
import { Layout, Text } from '@/Components';

// Constants
import { CLASS_NAME } from '@/Components/Form/Select';

// Icons
import { Ri } from '@/Icons';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const Only = React.forwardRef<HTMLSelectElement, Spec.Props>(
  (
    {
      className: _className,
      defaultValue,
      value: _value,
      disabled,
      id,
      name,
      onChange: changeHandler,
      options: _options,
      selected,
      unstyled,
      ...rest
    },
    ref
  ) => {
    const [changedValue, setValue] = useState<typeof _value>();

    const options = _options?.map((option) => {
      const value = changedValue || defaultValue || _value;

      return {
        ...option,
        selected:
          typeof selected === 'function'
            ? selected?.({
                option,
                value,
              })
            : option.value === value,
      };
    });

    const option = options?.find((option) => option.selected);

    const List = options?.map(({ selected, ...props }) => {
      return <option {...props} key={uuid()} />;
    });

    List?.unshift(
      <option key={uuid()} value=''>
        -- Select --
      </option>
    );

    const onChange: Spec.Props['onChange'] = (event) => {
      setValue(event.currentTarget.value);

      changeHandler?.(event);
    };

    const className = classNames(
      'loci-font-size-small',
      CLASS_NAME,
      {
        [`${CLASS_NAME}--disabled`]: disabled,
        [`${CLASS_NAME}--unstyled`]: unstyled,
      },
      _className
    );

    const Contents = (
      <select
        {...rest}
        disabled={disabled}
        id={id || name}
        name={name}
        onChange={onChange}
        ref={ref}
        value={option?.value}
      >
        {List}
      </select>
    );

    return (
      <Layout>
        <div className={className}>
          <Layout
            autoFlow='column'
            alignItems='center'
            frames='1fr--max-content'
            gap='narrowest'
            justifyContent='start'
          >
            <Text is='span' aria-hidden>
              <Text is='span'>{option?.label || '-- Select --'}</Text>
              <Ri.RiArrowDropDownLine className='loci-font-size-medium' />
            </Text>
          </Layout>
          {Contents}
        </div>
      </Layout>
    );
  }
);

type OnlyProps = Spec.Props;

Only.displayName = 'Select.Only';

export { type OnlyProps };
export default Only;
