import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { FormElements, Layout, LayoutProps } from '@/Components';

// Hooks
import { useResponsive } from '@/Hooks';

// Partial
import Only, { OnlyProps } from './Only';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--select';

const Instance = React.forwardRef<HTMLSelectElement, Spec.Props>(
  (
    {
      after,
      autoFlow,
      before,
      disabled,
      label = '\u00A0',
      name,
      options,
      unstyled = false,
      ...rest
    },
    ref
  ) => {
    const { isTablet } = useResponsive();

    const heading = (
      <FormElements.Label className={`${CLASS_NAME}--label`} htmlFor={name}>
        {label}
      </FormElements.Label>
    );

    let Content = (
      <Only
        {...rest}
        disabled={disabled}
        name={name}
        options={options}
        ref={ref}
        unstyled={unstyled || !!after || !!before}
      />
    );

    if (after || before) {
      let frames: LayoutProps['frames'] = 'min-content--1fr';

      if (after && !before) {
        frames = '1fr--min-content';
      }

      if (after && before) {
        frames = 'max-content--auto--max-content';
      }

      Content = (
        <Layout
          alignItems='center'
          autoFlow='column'
          frames={frames}
          justifyContent='stretch'
        >
          <div className={classNames(`${CLASS_NAME}--wrapper`)}>
            {before}
            {Content}
            {after}
          </div>
        </Layout>
      );
    }

    const className = classNames(
      {
        'loci-font-size-small': isTablet,
      },
      {
        [`${CLASS_NAME}--disabled`]: disabled,
        [`${CLASS_NAME}--unstyled`]: unstyled,
      }
    );

    return (
      <Layout autoFlow={autoFlow} gap='narrower' justifyContent='stretch'>
        <div className={className}>
          {heading}
          {Content}
        </div>
      </Layout>
    );
  }
);

type SelectProps = Spec.Props;

Instance.displayName = 'Select';

const Select = Object.assign(Instance, { Only });

export { type SelectProps, type OnlyProps, Only, CLASS_NAME };
export default Select;
