import React, { Suspense } from 'react';

// Routes
import { Navigate, Route, ProtectedRoute } from '@/Router';

// Components
import { Spinner } from '@/Components';

// View
import Profile from './Profile';

// Constants
import { PATH } from './constants';
import { PATH as PROFILE_PATH } from './Profile/constants';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--views--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

export default (
  <Route
    path={PATH}
    element={
      <ProtectedRoute>
        <Lazy />
      </ProtectedRoute>
    }
  >
    <Route index element={<Navigate replace to={PROFILE_PATH} />} />
    {Profile}
  </Route>
);
