import { ApolloLink } from '@apollo/client';

import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

// Env
import { Value } from '@/Env/Client';

// LocalStorage
import { getItem } from '@/LocalStorage';

const COOKIE = 'Authorization';

const Authorization = getItem(COOKIE);

const headers = {
  'apollo-require-preflight': 'true',
  authorization: '',
};

if (Authorization?.AccessToken) {
  headers.authorization = `bearer ${Authorization.AccessToken}`;
}

const upload = (config: Value) =>
  createUploadLink({
    credentials: 'include',
    headers,
    includeExtensions: true,
    uri: config.env?.LOCI_API_URL,
  }) as unknown as ApolloLink;

export default upload;
