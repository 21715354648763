const url = new URL('https://www.cloudflare.com/cdn-cgi/trace');

type Trace = {
  [name in string]: string;
};

const trace = async (): Promise<Trace> => {
  const trace = await fetch(url);

  const text = await trace.text();

  const cgi = text.split(/\n/g).reduce((accumulated, line) => {
    const [name, value] = line.split('=');

    return {
      ...accumulated,
      [name]: value,
    };
  }, {});

  return cgi;
};

export default trace;
