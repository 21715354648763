import React, { useEffect, useState } from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { FormElements, Layout, HyperLink, HyperLinkProps } from '@/Components';

// Hooks
import { useResponsive } from '@/Hooks';

// Icons
import { Ri } from '@/Icons';

// Partial
import Only, { OnlyProps } from './Only';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--input';

const Instance = React.forwardRef<HTMLInputElement, Spec.Props>(
  (
    {
      after,
      autoFlow,
      before,
      className: _className,
      disabled,
      label,
      name,
      type: _type = 'text',
      unstyled = false,
      ...rest
    },
    ref
  ) => {
    const { isTablet } = useResponsive();

    const [type, setType] = useState(_type);

    useEffect(() => {
      if (_type === type) {
        return;
      }

      setType(_type);
    }, [_type]);

    const heading = label ? (
      <FormElements.Label className={`${CLASS_NAME}--label`} htmlFor={name}>
        {label}
      </FormElements.Label>
    ) : null;

    const onToggle: HyperLinkProps['onClick'] =
      _type === 'password'
        ? (event) => {
            event.preventDefault();

            setType((type) => {
              if (type === 'password') {
                return 'text';
              }

              return 'password';
            });
          }
        : undefined;

    let ToggleIcon = _type === 'password' ? Ri.RiEyeCloseLine : React.Fragment;

    let ToggleTitle = _type === 'password' ? 'show password' : undefined;

    if (_type === 'password' && type === 'text') {
      ToggleIcon = Ri.RiEyeLine;
      ToggleTitle = 'hide password';
    }

    const Toggle =
      _type === 'password' && !unstyled ? (
        <Layout
          alignContent='center'
          alignItems='center'
          justifyContent='center'
          justifyItems='center'
        >
          <HyperLink
            className={`${CLASS_NAME}--toggle`}
            disabled={disabled}
            onClick={onToggle}
            title={ToggleTitle}
            to={`#${ToggleTitle?.split(' ').join('-')}`}
            unstyled
          >
            <ToggleIcon />
          </HyperLink>
        </Layout>
      ) : null;

    let Content = (
      <Only
        {...rest}
        disabled={disabled}
        name={name}
        ref={ref}
        type={type}
        unstyled
      />
    );

    if (after || before) {
      Content = (
        <Layout alignItems='center' autoFlow='column' gap='narrower'>
          <div className={`${CLASS_NAME}--wrapper`}>
            {before}
            {Content}
            {after}
          </div>
        </Layout>
      );
    }

    const className = classNames(
      {
        'loci-font-size-small': isTablet,
      },
      CLASS_NAME,
      {
        [`${CLASS_NAME}--type--${type}`]: type,
        [`${CLASS_NAME}--unstyled`]: unstyled,
        [`${CLASS_NAME}--disabled`]: disabled,
        [`${CLASS_NAME}--without-label`]: !label,
      },
      _className
    );

    return (
      <Layout autoFlow={autoFlow} gap='narrower' justifyContent='stretch'>
        <div className={className}>
          {heading}
          {Content}
          {Toggle}
        </div>
      </Layout>
    );
  }
);

type InputProps = Spec.Props;

Instance.displayName = 'Input';

const Input = Object.assign(Instance, { Only });

export { type InputProps, type OnlyProps, Only, CLASS_NAME };
export default Input;
