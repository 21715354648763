import { DocumentNode, Kind } from 'graphql';

const getOperationName = (document: DocumentNode) => {
  const definition = document.definitions.find((definition) => {
    if (definition.kind !== Kind.OPERATION_DEFINITION) {
      return false;
    }
    return definition.name?.kind === Kind.NAME;
  });

  if (definition?.kind !== Kind.OPERATION_DEFINITION) {
    return undefined;
  }

  return definition.name?.value;
};

const getOperationNames = (...documents: DocumentNode[]) => {
  return documents.map(getOperationName).filter(Boolean);
};

export { getOperationNames };
export default getOperationName;
