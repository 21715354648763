import { ApolloLink } from '@apollo/client';

// LocalStorage
import { getItem } from '@/LocalStorage';

const COOKIE = 'recentActivity';

const recentActivityLink = new ApolloLink((operation, forward) => {
  const recentActivity = getItem(COOKIE) || new Date().toISOString();

  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'recent-activity': recentActivity,
    },
  }));

  return forward(operation);
});

export default recentActivityLink;
