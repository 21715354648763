import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import {
  Query,
  QueryGuidesArgs,
  SortFindManyGuidesInput,
} from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Guides'>;

type Props = Parameters<typeof useQuery<Type, QueryGuidesArgs>>[1];

const { Guides } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useGuides = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<
    Type,
    QueryGuidesArgs
  >(Guides, {
    ...DEFAULT_PROPS,
    ...props,
  });

  return { data, previousData, loading, error };
};

export { useGuides, type Type, type Props, SortFindManyGuidesInput };
export default Guides;
