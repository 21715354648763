import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

// LocalStorage
import { getItem, removeItem, setItem } from '@/LocalStorage';

import { getRefreshToken } from '../Mutation';

const COOKIE = 'Authorization';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(
  ({
    forward,
    graphQLErrors,
    networkError,
    operation,
    response: _response,
  }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const Authorization = getItem(COOKIE);

        switch (error.extensions.code) {
          case 'FORBIDDEN':
            return fromPromise(
              getRefreshToken({
                variables: {
                  RefreshToken: {
                    RefreshToken: Authorization?.RefreshToken,
                  },
                },
              }).catch((error) => {
                console.error(error);
                removeItem(COOKIE);
              })
            )
              .filter(Boolean)
              .flatMap((RefreshToken) => {
                setItem(COOKIE, {
                  ...getItem(COOKIE),
                  ...RefreshToken?.['data']?.RefreshToken,
                });

                const { headers } = operation.getContext();

                operation.setContext({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${RefreshToken?.['data']?.RefreshToken?.AccessToken}`,
                  },
                });

                return forward(operation);
              });
          default:
            console.error(
              `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
            );
        }
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }

    return forward(operation);
  }
);

export default errorLink;
