import { ApolloLink } from '@apollo/client';

// Env
import { Value } from '@/Env/Client';

import authLink from './authLink';
import httpLink, { http, ws, upload } from './httpLink';
import errorLink from './errorLink';
// import httpLink from './httpLink';
import recentActivityLink from './recentActivityLink';
import retryLink from './retryLink';

/**
 * The order below is ordered in purpose.
 * Please refer to the official documentations for more details
 *
 * https://www.apollographql.com/docs/react/api/link/introduction/#additive-composition
 */
const Links = (config: Value) =>
  ApolloLink.from([
    retryLink,
    errorLink,
    authLink,
    recentActivityLink,
    httpLink(config),
  ]);

export { http, ws, upload };
export default Links;
