import React, { PropsWithChildren } from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--legend';

const Legend: React.FunctionComponent<PropsWithChildren<Spec.Props>> = ({
  children,
  className: _className,
  ...props
}) => {
  const className = classNames('loci-font-size-small', CLASS_NAME, _className);

  return (
    <legend {...props} className={className}>
      <div>{children}</div>
    </legend>
  );
};

type LegendProps = Spec.Props;

export { type LegendProps };
export default Legend;
