import React from 'react';
// import * as Ri from 'react-icons/ri';

// Components
import { HyperLink, Layout, List } from '@/Components';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--widgets--global-footer--media';

const MEDIAS: Spec.Medias = [
  // {
  //   title: 'YouTube',
  //   icon: Ri.RiYoutubeLine,
  //   href: 'https://www.youtube.com/channel/UCBDReAjsLaiHtBsmbKCKm1A',
  // },
  // {
  //   title: 'Instagram',
  //   icon: Ri.RiInstagramLine,
  //   href: 'https://www.instagram.com/lawrencebugg/?hl=en',
  // },
  // {
  //   title: 'Instagram',
  //   icon: Ri.RiTwitterLine,
  //   href: 'https://twitter.com/bugglawrence?lang=en',
  // },
  // {
  //   title: 'LinkedIn',
  //   icon: Ri.RiLinkedinLine,
  //   href: 'https://www.linkedin.com/in/lawrencebugg/',
  // },
  // {
  //   title: 'Quora',
  //   icon: Ri.RiQuestionLine,
  //   href: 'https://www.quora.com/profile/Lawrence-Bugg-2',
  // },
];

const Media: React.FunctionComponent = () => {
  if (!MEDIAS?.length) {
    return null;
  }

  return (
    <Layout
      fullScreen
      alignContent='center'
      alignItems='center'
      autoFlow='column'
      justifyContent='center'
      justifyItems='center'
      gap='wide'
    >
      <List className={CLASS_NAME} is='ul'>
        {MEDIAS?.map((media) => {
          if (!media) {
            return null;
          }

          const { href, icon: Icon, title } = media;

          return (
            <HyperLink
              className='loci-color-white'
              to={href}
              key={href}
              target={title}
              title={title}
            >
              <Icon className='loci-font-size-medium' />
            </HyperLink>
          );
        })}
      </List>
    </Layout>
  );
};

export default Media;
