// Env
import { getEnv } from '@/Env/Client';

// LocalStorage
import { getItem, removeItem, setItem } from '@/LocalStorage';

// Hooks
import { getUserInfo } from '@/API/Client/Hooks/useUserInfo';

// Mutation
import * as Mutation from '@/API/Client/Mutation';

// Query
import * as Query from '@/API/Client/Query';

// Utilities
import * as Utilities from '@/API/Client/Utilities';

const COOKIE = 'Authorization';

const INVALID_MESSAGES = 'Operation is not allowed';

const NO_READY_MESSAGES = 'Operation is not ready';

const WHITE_LISTED = Utilities.getOperationNames(
  Mutation.Activate,
  Mutation.RefreshToken,
  Mutation.Register,
  Mutation.SignIn,
  Mutation.SocialSignIn,
  Query.Activity,
  Query.Activities,
  Query.Guide,
  Query.Guides
);

const validateAccess = async (init: globalThis.RequestInit) => {
  try {
    const {
      AccessToken,
      Authorization,
      RefreshToken,
      isContinuance,
      isValidSession,
    } = getUserInfo(getItem(COOKIE));

    if (!AccessToken || !RefreshToken) {
      return undefined;
    }

    if (!isValidSession) {
      removeItem(COOKIE);
      return undefined;
    }

    if (isContinuance) {
      return Authorization;
    }

    const env = await getEnv();

    if (!env?.LOCI_API_URL) {
      return undefined;
    }

    if (!Mutation.RefreshToken?.loc?.source?.body) {
      return undefined;
    }

    const operationName = Utilities.getOperationName(Mutation.RefreshToken);

    const response = await window.fetch(env.LOCI_API_URL, {
      ...init,
      body: JSON.stringify({
        operationName,
        query: Mutation.RefreshToken.loc.source.body,
        variables: {
          RefreshToken: {
            RefreshToken,
          },
        },
      }),
    });

    const json = await response.json();

    if (!json) {
      return undefined;
    }

    const [error] = json.errors || [];

    if (error) {
      removeItem(COOKIE);

      window.location.reload();
    }

    if (!Authorization && !json.data?.RefreshToken?.AccessToken) {
      return undefined;
    }

    setItem(COOKIE, {
      ...Authorization,
      ...json.data.RefreshToken,
    });

    return getItem(COOKIE);
  } catch (_) {
    return undefined;
  }
};

export { INVALID_MESSAGES, WHITE_LISTED, NO_READY_MESSAGES };
export default validateAccess;
