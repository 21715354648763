import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Layout } from '@/Components';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const PATH = 'authentication';

const CLASS_NAME = 'loci--components--form--fieldset';

const Fieldset = React.forwardRef<HTMLFieldSetElement, Spec.Props>(
  (
    {
      alignContent = 'center',
      alignItems = 'center',
      autoFlow = 'column',
      frames,
      fullScreen = true,
      gap = 'normal',
      justifyContent = 'stretch',
      justifyItems = 'stretch',
      wrap,
      children,
      className: _className,
      ...rest
    },
    ref
  ) => {
    const className = classNames(CLASS_NAME, _className);

    return (
      <fieldset {...rest} ref={ref}>
        <Layout
          alignContent={alignContent}
          alignItems={alignItems}
          autoFlow={autoFlow}
          frames={frames}
          fullScreen={fullScreen}
          gap={gap}
          justifyContent={justifyContent}
          justifyItems={justifyItems}
          wrap={wrap}
        >
          <div className={className}>{children}</div>
        </Layout>
      </fieldset>
    );
  }
);

Fieldset.displayName = 'Fieldset';

type FieldsetProps = Spec.Props;

export { type FieldsetProps, PATH };
export default Fieldset;
