import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import { Query, QueryActivityArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Activity'>;

type Props = Parameters<typeof useQuery<Type, QueryActivityArgs>>[1];

const { Activity } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useActivity = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<
    Type,
    QueryActivityArgs
  >(Activity, {
    ...DEFAULT_PROPS,
    ...props,
  });

  return { data, previousData, loading, error };
};

export { useActivity, type Type, type Props };
export default Activity;
