import React from 'react';
import ReactDOM from 'react-dom';

// Libraries
import classNames from 'classnames';

// Components
import { Layout, HyperLink, Menu } from '@/Components';

// Views
import { PATH as PRIVACY_PATH } from '@/Views/Privacy';
import { PATH as TERMS_OF_SERVICE_PATH } from '@/Views/TermsOfService';

// Contexts
import GlobalFooterProvider, { useGlobalFooterContext } from './Context';

// Partials
import CopyRight from './CopyRight';
import Media from './Media';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--widgets--global-footer';

const GlobalFooter: React.FunctionComponent = () => {
  const { isFullScreenMode, isStickyMode, isThemeDark, node, rect } =
    useGlobalFooterContext();

  const className = classNames('loci-font-size-small', CLASS_NAME, {
    [`${CLASS_NAME}--is-fullscreen-mode`]: !isStickyMode && isFullScreenMode,
    [`${CLASS_NAME}--is-sticky-mode`]: isStickyMode && !isFullScreenMode,
    'loci--theme--dark': isThemeDark,
  });

  return (
    <>
      {ReactDOM.createPortal(
        <style data-widget-global-footer-uuid={`${CLASS_NAME}--css-variables`}>
          {`:root {
              --loci--widgets--global-footer--block-size: ${
                rect?.height || 0
              }px;
            }`}
        </style>,
        window.document.body
      )}
      <Layout fullScreen autoFlow='row' justifyItems='center' ref={node}>
        <footer className={className} role='contentinfo'>
          <Media />
          <CopyRight />
          <Menu gap='narrow'>
            <HyperLink to={TERMS_OF_SERVICE_PATH}>Terms of Service</HyperLink>
            <HyperLink to={PRIVACY_PATH}>Privacy Policy</HyperLink>
          </Menu>
        </footer>
      </Layout>
    </>
  );
};

export { GlobalFooterProvider, useGlobalFooterContext };
export default GlobalFooter;
