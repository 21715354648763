import React from 'react';

// Icons
import * as Icons from '@/Icons';

// Components
import { Button, Heading, Layout } from '@/Components';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker/dist/calendar';

const CLASS_NAME = 'loci--widgets--schedular--header';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  const previous = () => {
    decreaseMonth();
  };

  const next = () => {
    increaseMonth();
  };

  const Month = Months[date.getMonth()];

  return (
    <Layout
      alignItems='center'
      autoFlow='column'
      frames='max-content--auto--max-content'
      gap='narrow'
    >
      <header className={CLASS_NAME}>
        <Button
          disabled={prevMonthButtonDisabled}
          unstyled
          title='Previous Month'
          onClick={previous}
        >
          <Icons.Ri.RiArrowLeftSLine />
        </Button>
        <Layout
          alignContent='center'
          alignItems='center'
          autoFlow='column'
          gap='none'
          justifyContent='center'
          justifyItems='center'
        >
          <Heading
            aria-live='polite'
            className='loci-text-align-center'
            dense
            is='h4'
            role='alert'
          >
            {Month}
            <Icons.Tb.TbSlash />
            {date.getFullYear()}
          </Heading>
        </Layout>
        <Button
          disabled={nextMonthButtonDisabled}
          unstyled
          title='Next Month'
          onClick={next}
        >
          <Icons.Ri.RiArrowRightSLine />
        </Button>
      </header>
    </Layout>
  );
};

export default Header;
