import React from 'react';

// Libraries
import classNames from 'classnames';

// Icons
import * as Icons from '@/Icons';

// Components
import { Text } from '@/Components';

const CLASS_NAME = 'loci--widgets--schedular--day';

const Numbers = [
  Icons.Ri.RiNumber0,
  Icons.Ri.RiNumber1,
  Icons.Ri.RiNumber2,
  Icons.Ri.RiNumber3,
  Icons.Ri.RiNumber4,
  Icons.Ri.RiNumber5,
  Icons.Ri.RiNumber6,
  Icons.Ri.RiNumber7,
  Icons.Ri.RiNumber8,
  Icons.Ri.RiNumber9,
];

const now = new Date();

const Day = (day: number, date: Date) => {
  const className = classNames(CLASS_NAME, 'loci-font-size-small', {
    'loci-color-confirm':
      date?.getMonth() === now.getMonth() && day === now.getDate(),
  });

  const padded = String(day).padStart(2, '0');

  return (
    <Text className={className} is='span'>
      {String(padded)
        .split('')
        .map((number, index) => {
          const Icon = Numbers[Number(number)];

          const key = `${number}_${index}`;

          return <Icon key={key} />;
        })}
    </Text>
  );
};

export default Day;
