import React from 'react';

// Components
import { Animation, FormElements } from '@/Components';

// Constants
import { CLASS_NAME as BASE_CLASS_NAME } from '@/Components/Form/Radio/constants';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = `${BASE_CLASS_NAME}--group`;

const Group = React.forwardRef<HTMLFieldSetElement, Spec.Props>(
  ({ autoFlow = 'row', legend, name, options, ...rest }, ref) => {
    return (
      <Animation in={!!options?.length}>
        <FormElements.Fieldset
          {...rest}
          autoFlow={autoFlow}
          className={CLASS_NAME}
          ref={ref}
        >
          <FormElements.Legend>{legend}</FormElements.Legend>
          {options?.map((option) => {
            return (
              <FormElements.Radio
                {...option}
                key={String(option.label)}
                name={name}
              />
            );
          })}
        </FormElements.Fieldset>
      </Animation>
    );
  }
);

type GroupProps = Spec.Props;

Group.displayName = 'Radio.Group';

export { type GroupProps };
export default Group;
