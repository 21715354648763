import React, { Suspense } from 'react';

// Routes
import { Route as Origin, ProtectedRoute } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Constants
import { PATH } from './constants';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--views--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

const Element = (
  <ProtectedRoute>
    <Lazy />
  </ProtectedRoute>
);

const Route = <Origin path={PATH} />;

export { Route };
export default <Origin path={`${PATH}/*`} element={Element} />;
