// Libraries
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';

// Env
import { Value } from '@/Env/Client';

// Links
import Links, { http, ws, upload } from './Links';

// Cache
import Cache from './Cache';

// Schema
import * as Schema from './Schema';

const { default: _, ...Fragments } = Schema.Fragment;

const Fragment = Object.values(Fragments);

let instance;

const Client = (config: Value) => {
  instance = new ApolloClient({
    link: Links(config),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(...Fragment),
      typePolicies: {
        ...Cache,
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
  });

  return instance;
};

export { http, instance, ws, upload };
export default Client;
