import React from 'react';

// Routes
import {
  Route as Origin,
  ProtectedRoute,
  Navigate,
  NavigateProps,
  MatchedRoute,
  useLocation,
} from '@/Router';

const PATTERN = [
  '/activities/:ActivityGUID([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/booking/:GuideGUID([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
];
const PATH = ':GuideGUID';

const Lazy = React.lazy(() => import('./Contents'));

const ConditionalRedirect: React.FunctionComponent = () => {
  const location = useLocation();

  const to: NavigateProps['to'] = {
    pathname: '..',
    search: location.search,
  };

  const Fallback = <Navigate to={to} replace />;

  return (
    <MatchedRoute fallback={Fallback} pattern={PATTERN}>
      <Lazy />
    </MatchedRoute>
  );
};

export { PATH, PATTERN };
export default (
  <Origin path={PATH}>
    <Origin
      index
      element={
        <ProtectedRoute>
          <ConditionalRedirect />
        </ProtectedRoute>
      }
    />
  </Origin>
);
