import React, { PropsWithChildren } from 'react';

// Libraries
import classNames from 'classnames';
import DatePicker, { CalendarContainer } from 'react-datepicker';

// Icons
import * as Icons from '@/Icons';

// Components
import { Animation } from '@/Components';

// Partials
import Day from './Day';
import Header from './Header';
import Month from './Month';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

type Props = Spec.Props;

const CLASS_NAME = 'loci--widgets--schedular';

const maxDate = new Date();
const minDate = new Date();

maxDate.setFullYear(maxDate.getFullYear() + 1);

const DEFAULT = {
  maxDate,
  minDate,
};

const Scheduler: React.FunctionComponent<Props> = ({
  children,
  className: _className,
  footer,
  inline = true,
  maxDate = DEFAULT.maxDate,
  minDate = DEFAULT.minDate,
  selectsRange = true,
  selectsMultiple = undefined,
  ...rest
}) => {
  const className = classNames(CLASS_NAME, _className);

  const Container: React.FunctionComponent<PropsWithChildren> = ({
    children,
  }) => {
    return (
      <CalendarContainer className={className}>
        {children}
        <Animation in={!!footer}>{footer}</Animation>
      </CalendarContainer>
    );
  };

  return (
    <DatePicker
      {...rest}
      calendarContainer={Container}
      renderCustomHeader={Header}
      renderDayContents={Day}
      renderMonthContent={Month}
      inline
      nextMonthButtonLabel={<Icons.Ri.RiArrowRightSLine />}
      previousMonthButtonLabel={<Icons.Ri.RiArrowLeftSLine />}
      minDate={minDate}
      maxDate={maxDate}
      selectsRange={selectsRange}
      selectsMultiple={selectsMultiple}
    >
      {children}
    </DatePicker>
  );
};

export { type Props };
export default Scheduler;
