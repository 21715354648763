import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import { Query, QueryGuideArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Guide'>;

type Props = Parameters<typeof useQuery<Type, QueryGuideArgs>>[1];

const { Guide } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useGuide = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<Type, QueryGuideArgs>(
    Guide,
    {
      ...DEFAULT_PROPS,
      ...props,
    }
  );

  return { data, previousData, loading, error };
};

export { useGuide, type Type, type Props };
export default Guide;
