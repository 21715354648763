import { useQuery } from '@/API/Client';

// API
import { useUserInfo } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import { Query } from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Profile'>;

type Props = Parameters<typeof useQuery<Type>>[1];

const { Profile } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useProfile = (props?: Props) => {
  const UserInfo = useUserInfo();

  const { data, previousData, loading, error } = useQuery<Type>(Profile, {
    ...DEFAULT_PROPS,
    ...props,
    skip: props?.skip || !UserInfo.isValidSession,
  });

  return { data, previousData, loading, error };
};

export { useProfile, type Type, type Props };
export default Profile;
