import Activities from './Activities.graphql';
import Activity from './Activity.graphql';
import Asset from './Asset.graphql';
import Assets from './Assets.graphql';
import Booking from './Booking.graphql';
import Bookings from './Bookings.graphql';
import Countries from './Countries.graphql';
import Country from './Country.graphql';
import GeoLocation from './GeoLocation.graphql';
import GeoLocationSearch from './GeoLocationSearch.graphql';
import GeoLocations from './GeoLocations.graphql';
import Guide from './Guide.graphql';
import Guides from './Guides.graphql';
import Phone from './Phone.graphql';
import Phones from './Phones.graphql';
import Profile from './Profile.graphql';
import Schedule from './Schedule.graphql';
import Schedules from './Schedules.graphql';
import User from './User.graphql';
import UserLocationLookup from './UserLocationLookup.graphql';
import Users from './Users.graphql';

export {
  Activities,
  Activity,
  Asset,
  Assets,
  Booking,
  Bookings,
  Countries,
  Country,
  GeoLocation,
  GeoLocationSearch,
  GeoLocations,
  Guide,
  Guides,
  Phone,
  Phones,
  Profile,
  Schedule,
  Schedules,
  User,
  UserLocationLookup,
  Users,
};
export default {};
