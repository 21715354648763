import React, { useEffect, useState } from 'react';

// Libraries
import classNames from 'classnames';

// Env
import { useEnvContext } from '@/Env/Client';

// Components
import {
  FormElements,
  Image as Origin,
  ImageProps as OriginProps,
  Layout,
  Text,
} from '@/Components';

// Icons
import * as Icons from '@/Icons';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--form--image';

const MAX_SIZE = 5e6;

const Image = React.forwardRef<HTMLInputElement, Spec.Props>(
  ({ Asset, name, onChange: changeHandler, placeholder, ...rest }, ref) => {
    const { env } = useEnvContext();

    const [Name, setName] = useState<string>();

    const [AssetGUID, setAssetGUID] = useState(Asset?.AssetGUID);
    const [Path, setPath] = useState(Asset?.Path);
    const [Size, setSize] = useState(Asset?.Size);
    const [_id, setId] = useState(Asset?._id);

    const isOversized = Size && Size > MAX_SIZE;

    const data: OriginProps['data'] = Path?.startsWith('blob')
      ? Path
      : `${env?.LOCI_API_URL}${Path}`;

    useEffect(() => {
      if (Asset?.AssetGUID === AssetGUID) {
        return;
      }

      setAssetGUID(Asset?.AssetGUID);
    }, [Asset?.AssetGUID]);

    useEffect(() => {
      if (Asset?.Path === Path) {
        return;
      }

      setPath(Asset?.Path);
    }, [Asset?.Path]);

    useEffect(() => {
      if (Asset?.Size === Size) {
        return;
      }

      setSize(Asset?.Size);
    }, [Asset?.Size]);

    useEffect(() => {
      if (Asset?._id === _id) {
        return;
      }

      setId(Asset?._id);
    }, [Asset?._id]);

    const className = classNames(rest.className, CLASS_NAME);

    const onChange: typeof changeHandler = (event) => {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      setName(file.name);
      setSize(file.size);

      const { target } = event;

      if (file.size > MAX_SIZE && target.value) {
        target.value = '';
        return;
      }

      const Path = URL.createObjectURL(file);
      setPath(Path);
    };

    const Preview = Path ? (
      <Origin data={data} className={`${CLASS_NAME}--preview`} />
    ) : (
      <object className={`${CLASS_NAME}--placeholder`}>
        {placeholder ? (
          React.Children.toArray(placeholder).map((child) => {
            if (!React.isValidElement(child)) {
              return child;
            }

            return React.cloneElement(child, {
              ...child.props,
              className: classNames(
                `${CLASS_NAME}--placeholder--default`,
                child.props.className
              ),
            });
          })
        ) : (
          <Icons.Ri.RiImage2Fill
            className={`${CLASS_NAME}--placeholder--default`}
            title='No Image'
          />
        )}
      </object>
    );

    const id = `${name}-file`;

    let label = <>Upload Image</>;

    if (Name) {
      label = <>{Name}</>;
    }

    if (isOversized) {
      label = (
        <Layout
          alignContent='center'
          alignItems='center'
          autoFlow='column'
          gap='narrow'
          justifyContent='center'
          justifyItems='center'
        >
          <Text
            className='loci-color-error'
            is='span'
            lookLike='h6'
            variant='secondary'
          >
            <Icons.Ri.RiErrorWarningLine />
            {`Image is too large (${
              Math.round((Size * 0.000001 + Number.EPSILON) * 100) / 100
            }mb)`}
          </Text>
        </Layout>
      );
    }

    const Info = (
      <Layout alignContent='center' alignItems='center' gap='narrow'>
        <FormElements.Label htmlFor={id}>
          <Text
            className='loci-color-confirm'
            is='span'
            lookLike='h6'
            variant='secondary'
          >
            {label}
          </Text>
          <Text is='span' variant='secondary'>
            {`Max. ${MAX_SIZE * 0.000001}mb`}
          </Text>
        </FormElements.Label>
      </Layout>
    );

    return (
      <FormElements.Fieldset
        className={className}
        alignItems='center'
        justifyContent='start'
        name={name}
      >
        {Preview}
        {Info}
        <FormElements.Input.Only
          {...rest}
          id={id}
          name={name}
          type='file'
          onChange={onChange}
          ref={ref}
          accept='.jpg, .jpeg, .gif, .png, .svg'
          max={12345}
          unstyled
        />
        <FormElements.Input.Only
          {...rest}
          name={`${name}.AssetGUID`}
          type='hidden'
          defaultValue={AssetGUID || undefined}
        />
        <FormElements.Input.Only
          {...rest}
          name={`${name}.Path`}
          type='hidden'
          defaultValue={Path}
        />
        <FormElements.Input.Only
          {...rest}
          name={`${name}.Size`}
          type='hidden'
          defaultValue={Size}
          max={1e7}
        />
        <FormElements.Input.Only
          {...rest}
          name={`${name}._id`}
          type='hidden'
          defaultValue={_id ? String(_id) : undefined}
        />
      </FormElements.Fieldset>
    );
  }
);

type ImageProps = Spec.Props;

Image.displayName = 'Image';

export { type ImageProps };
export default Image;
