import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

// Env
import { Value } from '@/Env/Client';

// LocalStorage
import { getItem } from '@/LocalStorage';

// Links
import http from './http';
import upload from './upload';
import ws from './ws';

const COOKIE = 'Authorization';

const Authorization = getItem(COOKIE);

const headers = {
  'apollo-require-preflight': 'true',
  authorization: '',
};

if (Authorization?.AccessToken) {
  headers.authorization = `bearer ${Authorization.AccessToken}`;
}

const httpLink = (config: Value) =>
  split(
    (operation) => {
      const definition = getMainDefinition(operation.query);

      return !!(
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws(config),
    split(
      (operation) => {
        const definition = getMainDefinition(operation.query);

        const shouldUpload = !!(
          definition.name?.value.toLowerCase().includes('upload') &&
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'mutation'
        );

        return shouldUpload;
      },
      upload(config),
      http(config)
    )
  );

export { http, ws, upload };
export default httpLink;
