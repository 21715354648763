import React, { PropsWithChildren } from 'react';

// Libraries
import { ApolloProvider } from '@apollo/client';

// Env
import { useEnvContext } from '@/Env/Client';

// Client
import Client from './Client';

const Provider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const env = useEnvContext();

  return <ApolloProvider client={Client(env)}>{children}</ApolloProvider>;
};

export default Provider;
