import { ApolloLink } from '@apollo/client';

// LocalStorage
import { getItem } from '@/LocalStorage';

const COOKIE = 'Authorization';

const authLink = new ApolloLink((operation, forward) => {
  if (operation.operationName.endsWith('RefreshToken')) {
    return forward(operation);
  }

  // add the authorization to the headers
  operation.setContext(async ({ headers = {}, ...rest }) => {
    const Authorization = getItem(COOKIE);

    if (!Authorization) {
      return { ...rest, headers };
    }

    const authorization = ['bearer', Authorization.AccessToken]
      .filter(Boolean)
      .join(' ');

    return {
      ...rest,
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  return forward(operation);
});

export default authLink;
