import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import { Query, QueryUserLocationLookupArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'UserLocationLookup'>;

type Props = Parameters<typeof useQuery<Type, QueryUserLocationLookupArgs>>[1];

const { UserLocationLookup } = Schema;

const DEFAULT_PROPS: Props = {
  notifyOnNetworkStatusChange: true,
};

const useUserLocationLookup = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<
    Type,
    QueryUserLocationLookupArgs
  >(UserLocationLookup, {
    ...DEFAULT_PROPS,
    ...props,
    skip: !props?.variables?.UserLocationLookup.ip,
  });

  return { data, previousData, loading, error };
};

export { useUserLocationLookup, type Type, type Props };
export default UserLocationLookup;
