import Activate from './Activate.graphql';
import AddActivity from './AddActivity.graphql';
import AddBooking from './AddBooking.graphql';
import AddGeoLocation from './AddGeoLocation.graphql';
import AddGuide from './AddGuide.graphql';
import AddPhone from './AddPhone.graphql';
import AddUser from './AddUser.graphql';
import DeleteActivity from './DeleteActivity.graphql';
import DeleteBooking from './DeleteBooking.graphql';
import DeleteGeoLocation from './DeleteGeoLocation.graphql';
import DeleteGuide from './DeleteGuide.graphql';
import DeletePhone from './DeletePhone.graphql';
import DeleteUser from './DeleteUser.graphql';
import PredictActivity from './PredictActivity.graphql';
import RefreshToken from './RefreshToken.graphql';
import Register from './Register.graphql';
import SignIn from './SignIn.graphql';
import SignOut from './SignOut.graphql';
import SocialSignIn from './SocialSignIn.graphql';
import UpdateActivities from './UpdateActivities.graphql';
import UpdateActivity from './UpdateActivity.graphql';
import UpdateBooking from './UpdateBooking.graphql';
import UpdateGeoLocation from './UpdateGeoLocation.graphql';
import UpdateGuide from './UpdateGuide.graphql';
import UpdatePhone from './UpdatePhone.graphql';
import UpdateUser from './UpdateUser.graphql';
import UploadAsset from './UploadAsset.graphql';
import UploadAssets from './UploadAssets.graphql';

export {
  Activate,
  AddActivity,
  AddBooking,
  AddGeoLocation,
  AddGuide,
  AddPhone,
  AddUser,
  DeleteActivity,
  DeleteBooking,
  DeleteGeoLocation,
  DeleteGuide,
  DeletePhone,
  DeleteUser,
  PredictActivity,
  RefreshToken,
  Register,
  SignIn,
  SignOut,
  SocialSignIn,
  UpdateActivities,
  UpdateActivity,
  UpdateBooking,
  UpdateGeoLocation,
  UpdateGuide,
  UpdatePhone,
  UpdateUser,
  UploadAsset,
  UploadAssets,
};
export default {};
