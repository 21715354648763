import Activate from './Activate.graphql';
import Activities from './Activities.graphql';
import Addresses from './Addresses.graphql';
import Assets from './Assets.graphql';
import Bookings from './Bookings.graphql';
import Countries from './Countries.graphql';
import ErrorInterface from './ErrorInterface.graphql';
import GeoLocations from './GeoLocations.graphql';
import Guides from './Guides.graphql';
import MongoError from './MongoError.graphql';
import PaginationInfo from './PaginationInfo.graphql';
import Phones from './Phones.graphql';
import Profile from './Profile.graphql';
import RefreshToken from './RefreshToken.graphql';
import Register from './Register.graphql';
import RuntimeError from './RuntimeError.graphql';
import Schedules from './Schedules.graphql';
import SignIn from './SignIn.graphql';
import SignOut from './SignOut.graphql';
import SocialSignIn from './SocialSignIn.graphql';
import UploadAsset from './UploadAsset.graphql';
import UserLocationLookup from './UserLocationLookup.graphql';
import Users from './Users.graphql';
import ValidationError from './ValidationError.graphql';

export {
  Activate,
  Activities,
  Addresses,
  Assets,
  Bookings,
  Countries,
  ErrorInterface,
  GeoLocations,
  Guides,
  MongoError,
  PaginationInfo,
  Phones,
  Profile,
  RefreshToken,
  Register,
  RuntimeError,
  Schedules,
  SignIn,
  SignOut,
  SocialSignIn,
  UploadAsset,
  UserLocationLookup,
  Users,
  ValidationError,
};
export default {};
