import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { FormElements, Layout } from '@/Components';

// Hooks
import { useResponsive } from '@/Hooks';

// Partial
import Group, { GroupProps } from './Group';
import Only, { OnlyProps } from './Only';

// Constants
import { CLASS_NAME } from './constants';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const Instance = React.forwardRef<HTMLInputElement, Spec.Props>(
  (
    {
      className: _className,
      disabled,
      id,
      label = '\u00A0',
      name,
      unstyled = false,
      ...rest
    },
    ref
  ) => {
    const { isTablet } = useResponsive();

    const heading = (
      <FormElements.Label
        className={`${CLASS_NAME}--label`}
        htmlFor={id || name}
      >
        {label}
      </FormElements.Label>
    );

    const Content = (
      <Only
        {...rest}
        disabled={disabled}
        id={id}
        name={name}
        ref={ref}
        unstyled
      />
    );

    const className = classNames(
      {
        'loci-font-size-small': isTablet,
      },
      CLASS_NAME,
      {
        [`${CLASS_NAME}--unstyled`]: unstyled,
        [`${CLASS_NAME}--disabled`]: disabled,
      },
      _className
    );

    return (
      <Layout
        alignItems='center'
        autoFlow='column'
        gap='narrow'
        justifyContent='center'
        frames='min-content--1fr'
      >
        <div className={className}>
          {Content}
          {heading}
        </div>
      </Layout>
    );
  }
);

type CheckboxProps = Spec.Props;

Instance.displayName = 'Checkbox';

const Checkbox = Object.assign(Instance, { Group, Only });

export {
  type CheckboxProps,
  type GroupProps,
  type OnlyProps,
  Group,
  Only,
  CLASS_NAME,
};
export default Checkbox;
