import React, { Suspense } from 'react';

// Routes
import { ProtectedRoute, Route as Origin } from '@/Router';

// Context
import GenerateProvider from './Context';

// Components
import { Spinner } from '@/Components';

const PATH = 'generate';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <GenerateProvider>
      <Suspense fallback={<Spinner animationDuration='instant' />}>
        <Contents />
      </Suspense>
    </GenerateProvider>
  );
};

export { PATH };
export default (
  <Origin path={PATH}>
    <Origin
      path=''
      element={
        <ProtectedRoute>
          <Lazy />
        </ProtectedRoute>
      }
    />
  </Origin>
);
