import React from 'react';

// Routes
import {
  Route as Origin,
  ProtectedRoute,
  Navigate,
  NavigateProps,
  MatchedRoute,
  useLocation,
} from '@/Router';

// Views
import Guide, { PATTERN as GUIDE_PATTERN } from './Guide';

const PATTERN = [
  '/activities/:ActivityGUID([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/booking',
  ...GUIDE_PATTERN,
];
const PATH = 'booking/*';

const Lazy = React.lazy(() => import('./Contents'));

const ConditionalRedirect: React.FunctionComponent = () => {
  const location = useLocation();

  const to: NavigateProps['to'] = {
    pathname: '..',
    search: location.search,
  };

  const Fallback = <Navigate to={to} replace />;

  return (
    <MatchedRoute fallback={Fallback} pattern={PATTERN}>
      <Lazy />
    </MatchedRoute>
  );
};

export { PATH, PATTERN };
export default (
  <Origin
    path={PATH}
    element={
      <ProtectedRoute>
        <ConditionalRedirect />
      </ProtectedRoute>
    }
  >
    {Guide}
  </Origin>
);
