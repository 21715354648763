import React from 'react';

// Libraries
import classNames from 'classnames';

// Analytic
import * as Analytic from '@/Analytic';

// Icons
import { Ri } from '@/Icons';

// Components
import { Layout } from '@/Components';

// Constants
import { CLASS_NAME } from '@/Components/Form/Radio/constants';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const Only = React.forwardRef<HTMLInputElement, Spec.Props>(
  (
    {
      className: _className,
      disabled,
      id,
      name,
      onFocus: focusHandler,
      unstyled = false,
      ...rest
    },
    ref
  ) => {
    const className = classNames(
      CLASS_NAME,
      {
        [`${CLASS_NAME}--unstyled`]: unstyled,
        [`${CLASS_NAME}--disabled`]: disabled,
      },
      _className
    );

    const onFocus: Spec.Props['onFocus'] = (event) => {
      Analytic.onFocus(event);

      focusHandler?.(event);
    };

    return (
      <>
        <Layout
          alignContent='center'
          alignItems='center'
          justifyContent='center'
          justifyItems='center'
        >
          <div>
            <Ri.RiCheckboxBlankCircleLine className='loci-font-size-medium' />
            <Ri.RiCheckboxBlankCircleFill
              className={classNames(
                'loci-font-size-smaller',
                'loci-color-confirm'
              )}
            />
            <input
              {...rest}
              className={className}
              disabled={disabled}
              id={id || name}
              name={name}
              onFocus={onFocus}
              ref={ref}
              type='radio'
            />
          </div>
        </Layout>
      </>
    );
  }
);

type OnlyProps = Spec.Props;

Only.displayName = 'Radio.Only';

export { type OnlyProps };
export default Only;
