import React, { PropsWithRef } from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Heading, HyperLink, Layout } from '@/Components';

// Icons
import { Logo, LogoVariants as SiteLogoVariants } from '@/Icons';

// Styles
import './Styles.scss';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--widgets--site-logo';
const TITLE = 'Loci';

const LOGOS = {
  icon: <Logo aria-label={TITLE} variant='icon' />,
  name: <Logo aria-label={TITLE} variant='name' />,
};

const SiteLogo: React.FunctionComponent<PropsWithRef<Spec.Props>> = ({
  withIcon = false,
  withName = true,
  ...props
}) => {
  let Contents = LOGOS.icon;

  if (!withIcon && withName) {
    Contents = LOGOS.name;
  }

  if (withIcon && !withName) {
    Contents = LOGOS.icon;
  }

  if (withIcon && withName) {
    Contents = (
      <>
        {LOGOS.icon}
        {LOGOS.name}
      </>
    );
  }

  const className = classNames(
    CLASS_NAME,
    {
      [`${CLASS_NAME}--with-name`]: withName,
    },
    props.className
  );

  return (
    <Heading {...props} className={className} variant='secondary' title={TITLE}>
      <Layout
        autoFlow='column'
        gap='narrower'
        alignItems='center'
        justifyContent='center'
      >
        <HyperLink className={`${CLASS_NAME}--hyper-link`} to='/' unstyled>
          {Contents}
        </HyperLink>
      </Layout>
    </Heading>
  );
};

type SiteLogoProps = Spec.Props;

export { type SiteLogoProps, SiteLogoVariants };
export default SiteLogo;
